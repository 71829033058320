import { useState, useEffect, useRef } from "react"
import { Button } from "components/ui/button"
import { createWebSocketClient } from "api/clients/knowledgeBuilder.httpClient"
import { createWebSocketClient as createWebSocketProjectoClient } from "api/clients/project-o.httpClient"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";

function convertTimestamp(timestamp) {
    try {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const date = new Date(timestamp * millisecondsPerDay);
        const formattedDate = date.toISOString();
        return formattedDate;
    } catch (error) {
        return 'N/A'
    }
  }

export default function ActivityLogViewer() {
    const [logs, setLogs] = useState([])
    const [logType, setLogType] = useState('KB')

    const logContainerRef = useRef(null)

    useEffect(() => {
        let eventSource;
        setLogs([])
    
        if (logType === 'KB') {
            eventSource = createWebSocketClient("/logs/ws/logs");
        } else {
            eventSource = createWebSocketProjectoClient("/logs/ws/logs");
        }
    
        eventSource.onmessage = (event) => {
            console.log('event', event);
            
            setLogs((prevLogs) => [...prevLogs, event]);
        };
    
        return () => {
            eventSource.close();
        };
    }, [logType]);

    useEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight
        }
    }, [logContainerRef])

    return (
        <Card className="space-y-4">
            <CardHeader>
                <CardTitle>Activity Log Viewer</CardTitle>
            </CardHeader>

            <CardContent className="space-y-4">
                <div className="flex w-full justify-end space-x-2">
                    <Select onValueChange={(value) => setLogType(value)} id="workflow" defaultValue={logType} >
                        <SelectTrigger>
                            <SelectValue placeholder="Select log type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value={'KB'}> KB </SelectItem>
                            <SelectItem value={'projecto'}> Projecto </SelectItem>
                        </SelectContent>
                    </Select>
                    <Button onClick={() => setLogs([])}>Clear Logs</Button>
                </div>
                <div
                    ref={logContainerRef}
                    className="h-[calc(100vh-200px)] overflow-y-auto border rounded-md p-4 bg-black text-white font-mono text-sm"
                >
                    {logs.map((log) => (
                        <div key={log.timeStamp}>
                            <span className="text-gray-400">{convertTimestamp(log.timeStamp)}</span>{" "}
                            <span className="text-gray-400">{log.data}</span>{" "}
                            <span className="text-gray-400">{log.type}</span>{" "}
                            <span className="text-gray-400">{log.target.url}</span>{" "}
                        </div>
                    ))}
                </div>
            </CardContent>

        </Card>
    )
}

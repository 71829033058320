import * as React from "react"
import { ScrollArea } from "components/ui/scroll-area"
import { Input } from "components/ui/input"
import { Button } from "components/ui/button"
import { SendHorizonal } from "lucide-react"
import { ChatControls } from "./ChatControls"
import { UserMessage, AssistantMessage } from "./MessageDisplay"

export const ChatPanel = ({
  mode,
  messages,
  loading,
  loadingMessage,
  onReferenceClick,
  handleSendMessage,
  onFileSelect=null
}) => {
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none border-b p-2">
        <ChatControls mode={mode} onFileSelect={onFileSelect} />
      </div>
      <div className="flex-1 min-h-0 relative"> {/* Added container with min-h-0 */}
        <ScrollArea className="h-full absolute inset-0">
          <div className="p-2">
            {messages.map((chat, index) => (
              <div key={index}>
                {chat.role === "user" ? (
                  <UserMessage content={chat.content} />
                ) : (
                  <AssistantMessage
                    content={chat.content}
                    onReferenceClick={onReferenceClick}
                    canClickRef={true}
                  />
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
            {loading && (
              <div className="h-8 px-4 flex items-center italic text-gray-400 text-sm">
                {loadingMessage}
              </div>
            )}
          </div>
        </ScrollArea>
      </div>
      <div className="flex-none border-t p-4">
        <ChatInput onSubmit={handleSendMessage} />
      </div>
    </div>
  )
}


const ChatMessage = ({ message }) => {
  return (
    <div className={`flex gap-3 ${message.role === "user" ? "flex-row-reverse" : "flex-row"}`}>
      <div
        className={`flex flex-col gap-2 rounded-lg p-3 max-w-[80%] ${
          message.role === "user" ? "bg-primary text-primary-foreground" : "bg-muted"
        }`}
      >
        <div className="text-sm">{message.content}</div>
        {message.citation && (
          <div className="text-xs opacity-80 border-t pt-2 mt-1">
            <div className="font-medium">Citation from page {message.citation.page}:</div>
            <div className="italic">&ldquo;{message.citation.text}&rdquo;</div>
          </div>
        )}
      </div>
    </div>
  )
}

const ChatInput = ({ onSubmit }) => {
  const [value, setValue] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
    setValue("");
  };

  return (
    <form className="flex flex-row gap-2" onSubmit={handleSubmit}>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Type a message..."
        className="flex-1 focus-visible:ring-0"
        autoComplete="off"
        name="userMessage"
      />
      <Button type="submit" size="icon" variant="ghost" disabled={!value}>
        <SendHorizonal className="h-5 w-5" />
        <span className="sr-only">Send message</span>
      </Button>
    </form>
  )
}
import { createContext, useState, useEffect } from "react";
import { redisCollectionService, workflowService } from "api";
import { useParams } from "react-router-dom";
import subOrgSectionMenu from "data/subOrgSectionMenu";

export const SubOrgContext = createContext({});

export const SubOrganizationProvider = ({ children }) => {
    
    const [selectedSubOrganization, setSelectedSubOrganization] = useState({});
    const [loading, setLoading] = useState(true);
    const [workflow, setWorkflow] = useState(null);
    const [collections, setCollections] = useState([]);
    const [collectionLoading, setCollectionLoading] = useState(true);
    const [subOrgMenu] = useState(subOrgSectionMenu);

    const { oragID, subOragID } = useParams();

    // Hooks
    useEffect(() => {
        fetchSubOrgData();
    }, [subOragID]);

    // Actions
    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
        case "SET_LOADING":
            return setLoading(payload);
        case "SET_WORKFLOW":
            return setWorkflow(payload);
        case "SET_COLLECTIONS":
            return setCollections(payload);
        case "SET_SELECTED_SUB_ORGANIZATION":
            return setSelectedSubOrganization(payload);
        default:
            return loading;
        }
    };

    const fetchSubOrgData = async () => {
        setLoading(true)
        setWorkflow(null)
        setCollections([])
        setCollectionLoading(true)
        try {
            const workflowResponse = await workflowService.getSubOrgWorkflow(oragID, subOragID);
            setWorkflow(workflowResponse.data);

            // fetch collections synchronously
            redisCollectionService.listCollections(oragID, subOragID)
            .then((projectsResponse) => {
                setCollections(projectsResponse.data);
            }).finally(() => {
                setCollectionLoading(false);
            });
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SubOrgContext.Provider
        value={{
            actions,
            loading,
            workflow,
            subOrgMenu,
            collections,
            selectedSubOrganization,
            collectionLoading
        }}
        >
            {children}
        </SubOrgContext.Provider>
    );
};

// import { Label } from 'components/ui/label'
import React from 'react'
import StepIdSelector from './StepIdSelector'
import ConditionBuilder from '../ConditionBuilder'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler'

const StepCondition = ({stepIndex, opCodeStep, opCodeSteps, setOpCodeSteps, nested}) => {
    const handleExpressionChange = (value) => {
        if(value) {
            setOpCodeSteps(opCodeSteps.map((opCoStep, i) => i === stepIndex? {...opCoStep, input:value || ""} : opCoStep ))
        }
    }

    return (
        <div className='grid grid-cols-3 items-center gap-4 w-full'>
            <div className='col-span-3'>
                <ConditionBuilder
                stepIndex={stepIndex}
                opCodeSteps={opCodeSteps}
                setOpCodeSteps={setOpCodeSteps}
                onConditionChange={handleExpressionChange}
                initialCondition={opCodeStep.input}
                previousOutputs = {handler.getPreviousOutputs(opCodeSteps, stepIndex)}
                nested={nested}
                />
            </div>

            <StepIdSelector
            label="True Branch"
            isTrue={true}
            selectedStep={opCodeStep?.next_step.true_branch || []}
            stepIndex={stepIndex}
            opCodeStep={opCodeStep}
            opCodeSteps={opCodeSteps}
            setOpCodeSteps={setOpCodeSteps}
            />

            <StepIdSelector
            label="False Branch"
            isTrue={false}
            selectedStep={opCodeStep?.next_step.false_branch || []}
            stepIndex={stepIndex}
            opCodeStep={opCodeStep}
            opCodeSteps={opCodeSteps}
            setOpCodeSteps={setOpCodeSteps}
            />
        </div>
    )
}   

export default StepCondition
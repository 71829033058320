import React from 'react';
import { PageContent, PageLayout } from 'components/NewDesignComponents/PageLayout';
import Breadcrumb from 'components/Breadcrumb';
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
  } from "components/ui/resizable"
import CodeEditor from './CodeEditor';
import CodeChatBox from './CodeChatBox';

const CodeSandbox = () => {
    return (
        <PageLayout>
            <PageContent className="w-full p-8 h-full">
                <div className="flex flex-col w-full h-full">
                    <div className='my-3 px-2'>
                        <Breadcrumb maxPaths={2} />
                    </div>
                    <div className="w-full flex flex-row h-full gap-4">
                        <ResizablePanelGroup
                            direction="horizontal"
                            className="gap-2 flex"
                        >
                            <ResizablePanel defaultSize={50} className="md:w-[480px] min-w-[480px] h-full">
                                {/* chat box */}
                                <CodeChatBox />
                            </ResizablePanel>
                            <ResizableHandle />
                            <ResizablePanel defaultSize={50} className="min-w-[480px] w-full h-fit">
                                {/* doc display */}
                                <CodeEditor/>
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </div>
                </div>
            </PageContent>
        </PageLayout>

    );
};

export default CodeSandbox;
/* eslint-disable max-lines */
import { Step, StepsContainer } from 'components/NewDesignComponents/VerticalSteps'
import React, { useContext, useEffect, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import OpCodeBuilderStep from '../../OpCodeBuilderStep'
import { OpCodeBuilderContext, STEP_TYPES } from '../../OpCodeBuilderHAndC/OpCodeBuilderContext';
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';

const NestedSteps = ({mainStepIndex, opCodeSteps, opCodeStep, setOpCodeSteps, parentId, setNestedPrevOutputs}) => {
    const [opCodeStepsLoop, setOpCodeStepsLoop] = useState([])
    const [stepTypes, setStepTypes] = useState([STEP_TYPES[1].name]);
    const { constantStepTypes, opType, orgId, subOrgId, indicators } = useContext(OpCodeBuilderContext)

    useEffect(() => {
        const prevOutputs = handler.getNestedPreviousOutputs(opCodeStepsLoop, indicators);
        
        if (prevOutputs && prevOutputs.length > 0) {
            setNestedPrevOutputs(prevOutputs);
        }
    }, [opCodeStepsLoop]);
    
    useEffect(() => {
        if(opCodeStep.input.steps.length > 0){
            setOpCodeStepsLoop(opCodeStep.input.steps)
            setStepTypes(opCodeStep.input.steps.map(step=> step.step_type))
        }
    }, [])
    
    useEffect(() => {
        if(opCodeStepsLoop.length > 0){
            setOpCodeSteps(opCodeSteps.map((opCoStep,i) => i === mainStepIndex? {...opCoStep, input:{...opCoStep.input, steps:opCodeStepsLoop}} : opCoStep ))
        }
    }, [opCodeStepsLoop])
    
    const handleAddStep = (position, currentStep) => {
        // console.log(`Add step ${position} ${currentStep + 1}`)
        let newOpCodeStepsLoop = [...opCodeStepsLoop];
        const newStepType = [...stepTypes];
        const currentIndex = Number(currentStep) 
        const isToBeFirstIndex = position === 'before' && currentIndex === 0

        // Find the index of the current step  
        if (currentIndex === -1) {
            console.error("Current step not found");
            return;
        }

        const newOpCodeStepLoop = {
            step_id: window?.crypto?.randomUUID(),
            step_type: `${opType === 'AutoOp'? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: " {{context['real_time_data']}}",  
            output: { Output: "", Output_map: {} },
            next_step: [],
            accumulate_output: true,
            is_first_step: isToBeFirstIndex? true : false
        }

        if(isToBeFirstIndex){
            newOpCodeStepsLoop = newOpCodeStepsLoop.map((opCoStepLoop, i) => i ===  currentIndex? {...opCoStepLoop, is_first_step:false} : opCoStepLoop)
        }
    
        if (position === 'before' || position === 'after') {
            const indexOffset = position === 'before' ? 0 : 1;
            const insertionIndex = currentIndex + indexOffset;

            if (position === 'before') {
                // Update the `next_step` of the new step to point to the current step
                newOpCodeStepLoop.next_step = [newOpCodeStepsLoop[currentIndex].step_id];
                
                // Update the previous step's `next_step` to point to the new step, if applicable
                if (currentIndex > 0) {
                    newOpCodeStepsLoop[currentIndex - 1].next_step = [newOpCodeStepLoop.step_id];
                }
            } else if (position === 'after') {
                // Update the `next_step` of the new step to point to the next step of the current step
                newOpCodeStepLoop.next_step = newOpCodeStepsLoop[currentIndex]?.next_step[0]? 
                [newOpCodeStepsLoop[currentIndex]?.next_step[0]] : [] 
    
                // Update the `next_step` of the current step to point to the new step
                if(newOpCodeStepsLoop[currentIndex]?.step_type !== "Condition"){
                    newOpCodeStepsLoop[currentIndex].next_step = [newOpCodeStepLoop.step_id];
                }

                if(newOpCodeStepsLoop[currentIndex]?.step_type === "Condition"){
                    newOpCodeStepsLoop[currentIndex].next_step = {true_branch:[newOpCodeStepLoop.step_id], false_branch:[newOpCodeStepLoop.step_id]}
                }
            }

            newOpCodeStepsLoop.splice(insertionIndex, 0, newOpCodeStepLoop);
            newStepType.splice(insertionIndex, 0, constantStepTypes[1].name);
        } else {
            console.error("Invalid position");
        }
    
        setOpCodeStepsLoop(newOpCodeStepsLoop);
        setOpCodeSteps(opCodeSteps.map((opCoStep,i) => i === mainStepIndex? {...opCoStep, steps:newOpCodeStepsLoop} : opCoStep ))
        setStepTypes(newStepType)
    };

    const handleStepTypeSelect = (value, index) => {
        const newType = constantStepTypes.find(item => item.name === value).name

        setStepTypes(stepTypes.map((type,i) => i === index? newType : type))

        let newOpCodeStepLoop = {}

        const currentInputGroup = opCodeSteps.find((_,i) => i === index)

        const inputText = currentInputGroup?.step_type === "Non-LLM"? (currentInputGroup?.input?.query_text || "") 
        : (currentInputGroup?.step_type=== "Condition"? "" : currentInputGroup?.input || "")

        const outputVar = currentInputGroup?.step_type === "Non-LLM"? (Object.keys(currentInputGroup?.output)[0] || "") 
        : (currentInputGroup?.output?.Output || "")
        
        const nextStepValue = currentInputGroup?.step_type !== "Condition"? currentInputGroup?.next_step || [] 
        : currentInputGroup?.next_step?.true_branch || []

        const llmObj = {
            step_id: "",
            step_type: `${newType === "LLM"? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: inputText,  
            output: { Output: outputVar, Output_map: {} },
            next_step: nextStepValue,
            accumulate_output: true,
            is_first_step: false
        }

        if (newType === "LLM") {
            newOpCodeStepLoop = llmObj
        } else if (newType === "LLM-Stream") {
            newOpCodeStepLoop = llmObj
        } else if (newType === "Non-LLM") {
            newOpCodeStepLoop = {
                step_id: "",
                step_type: "Non-LLM",
                registry_key: "",
                input: {
                    "org_id": orgId,
                    "sub_org_id": subOrgId,
                    "collection_id": "",
                    "use_rerank": true,
                    "query_text": inputText,  
                    "top_k": 10,
                    "top_n": 3,
                    "offset": 0,
                    "filters": {},
                    "search_type": "vector",
                },
                output: { [outputVar]: "compiled_text" },
                next_step: nextStepValue,
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Condition"){
            newOpCodeStepLoop = {
                step_id: "",
                step_type: "Condition",
                input: "",
                output: { Output: outputVar },
                next_step: {
                    true_branch: nextStepValue,
                    false_branch: nextStepValue
                },
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Loop"){
            newOpCodeStepLoop = {
                step_id: "",
                step_type: "Loop",
                input: {
                    collection: "",
                    item: "loopItem",
                    max_iterations: "",
                    parallel:true,
                    // break_condition: "",
                    steps: [
                        {
                            step_id: window?.crypto?.randomUUID(),
                            step_type: `${opType === 'AutoOp'? "LLM" : "LLM-Stream"}`,
                            unique_name: "",
                            model_type: "",
                            input: " {{context['real_time_data']}}",  
                            output: { Output: "", Output_map: {} },
                            next_step: [],
                            accumulate_output: true,
                            is_first_step: false
                        }
                    ],
                },
               output: { Output: outputVar},
               next_step: nextStepValue,
               accumulate_output: true,
               is_first_step: false
            }
        } else if (newType === "Memory") {
            newOpCodeStepLoop = {
                step_id: "",
                step_type: "Memory",
                input: {
                    n:3,
                    limit_size:1000
                },
                output: { Output: outputVar },
                next_step: nextStepValue,
                accumulate_output: true,
                is_first_step: false
            }
        }

        const newOpCodeStepsLoop = opCodeStepsLoop.map((opCoStepLoop,i) => i === index? {...newOpCodeStepLoop, step_id:opCoStepLoop.step_id} : opCoStepLoop)
        setOpCodeStepsLoop(newOpCodeStepsLoop)
        setOpCodeSteps(opCodeSteps.map((opCoStep,i) => i === mainStepIndex? {...opCoStep, steps:newOpCodeStepsLoop} : opCoStep ))
    }
    
    const handleRemoveStep = (id) => {
        // console.log(`Delete step ${id + 1}`, stepTypes)
        const opCodeStepLoopDeleted = opCodeStepsLoop?.find((_, index) => index === id)
        const outputText = opCodeStepLoopDeleted?.output?.Output || Object.keys(opCodeStepLoopDeleted?.output)[0] || ""
        const nextStepValue =  opCodeStepLoopDeleted?.step_type === 'Condition'? opCodeStepLoopDeleted?.next_step?.true_branch || [] : opCodeStepLoopDeleted?.next_step || []

        const updatedOpCodeStepsLoop = opCodeStepsLoop?.map(opCoStepLoop =>{
            if(opCoStepLoop?.step_type !== "Condition" && opCoStepLoop.step_type !== 'Identification'){

                let newGroup = {...opCoStepLoop}

                 if (opCoStepLoop?.next_step[0] === opCodeStepLoopDeleted?.step_id) {
                        newGroup = { ...opCoStepLoop, next_step: nextStepValue };
                }

                if(opCoStepLoop.step_type === 'Memory' || opCoStepLoop.step_type === 'Loop'){
                    return newGroup
                }
               
                if(opCoStepLoop.step_type === "Non-LLM" && outputText){
                    if(opCoStepLoop.input.query_text.includes(outputText)){
                        newGroup = { ...newGroup, input:{...newGroup.input, query_text: handler.removeDeletedOutput(opCoStepLoop.input.query_text || "", outputText)}}
                    }
                } else if(outputText) {
                    if(opCoStepLoop.input.includes(outputText)){
                        newGroup = { ...newGroup, input: handler.removeDeletedOutput(opCoStepLoop.input || "", outputText)} 
                    }

                    if(opCoStepLoop?.output?.Output_map && Object.keys(opCoStepLoop?.output?.Output_map).length > 0 && outputText in opCoStepLoop.output.Output_map){
                        delete newGroup.output.Output_map[outputText]
                    }
                }

                return newGroup
            }

            if(opCoStepLoop?.step_type === "Condition"){
                let true_branch = opCoStepLoop?.next_step?.true_branch
                let false_branch = opCoStepLoop?.next_step?.false_branch

                if (opCoStepLoop?.next_step?.true_branch[0] === opCodeStepLoopDeleted?.step_id) {
                    true_branch =  nextStepValue 
                }

                if (opCoStepLoop?.next_step?.false_branch[0] === opCodeStepLoopDeleted?.step_id) {
                    false_branch =  nextStepValue 
                }

                return {...opCoStepLoop, next_step:{true_branch, false_branch}}
            }

            return opCoStepLoop
        } )

        let newOpCodeStepsLoop = updatedOpCodeStepsLoop.filter((_, index) => index !== id)

        setOpCodeStepsLoop(newOpCodeStepsLoop)

        setStepTypes(stepTypes.filter((_, index) => index !== id))

        setOpCodeSteps(opCodeSteps.map((opCoStepLoop,i) => i === mainStepIndex? {...opCoStepLoop, steps:newOpCodeStepsLoop} : opCoStepLoop ))
    }

    const handleMoveStep = (dragIndex, hoverIndex, steps, parentIdContainer) => {
        try{
            if(parentId === parentIdContainer){
                let newOpCodeStepsLoop = []
                let newTypes = []
                steps?.map(step => {
                   
                        const foundInput = opCodeStepsLoop.find(opCoStepLoop => opCoStepLoop.step_id === step.props.step_id)
        
                        newOpCodeStepsLoop.push(foundInput)
        
                        newTypes.push(foundInput.step_type)
                })
                setOpCodeStepsLoop(newOpCodeStepsLoop)
                setOpCodeSteps(opCodeSteps.map((opCoStepLoop,i) => i === mainStepIndex? {...opCoStepLoop, steps:newOpCodeStepsLoop} : opCoStepLoop ))
                setStepTypes(newTypes)
            }
        }catch(err) {
            console.log(err)
        }
    }

    return (
        <StepsContainer onMoveStep={handleMoveStep} parentId={parentId} className={'w-full bg-white'}>
            {opCodeStepsLoop?.map((opCodeStepLoop, index) => (
                <Step
                indicator={`${handler.getIndicatorNumber(opCodeStepLoop.step_id, indicators) || index + 1}`}
                stepIndex={index}
                canAddBefore={true}
                canAddAfter={true}
                isDraggable={false}
                canDelete={index === 0 ? false : true}
                onAddStep={handleAddStep}
                onDeleteStep={handleRemoveStep}
                key={index}
                title={`Opcode Step ${handler.getIndicatorNumber(opCodeStepLoop.step_id, indicators) || index + 1} `}
                itemId={parentId}
                step_id={opCodeStepLoop.step_id}
                >
                    <div className="flex flex-col gap-2 w-full">
                        <Select onValueChange={(value) => handleStepTypeSelect(value, index)} 
                        id="step_type" 
                        className="w-full" value={stepTypes[index]}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select step type" />
                            </SelectTrigger>
                            <SelectContent>
                                {constantStepTypes.slice(1).map((item) => (
                                    <SelectItem key={item.id} value={item.name}>
                                        {item.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <div className="flex flex-col gap-4">
                            <OpCodeBuilderStep 
                                stepIndex={index}
                                opCodeStep={opCodeStepLoop} 
                                opCodeSteps={opCodeStepsLoop}
                                setOpCodeSteps={setOpCodeStepsLoop}
                                nested={true}
                            />
                            <hr/>
                        </div>
                    </div>
                </Step>
                ))}
        </StepsContainer>
    )
}

export default NestedSteps
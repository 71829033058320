import React, { useState, useContext, useEffect } from "react";
import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { useParams } from 'react-router-dom';
import { Context } from "context/GlobalState";
import { kbWSService } from "api/services/PROJECT-O/WEBSOCKETS/KnowledgeBuilder.websocket.service";
import { FileManagerContext } from "context/FileManagerContext";
import { Label } from "components/ui/label";
import { removeFirstPrefix } from "components/FileManager/FileManager";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";
import { Check, ChevronsUpDown, HelpCircle } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "components/ui/command";
import { cn } from "lib/utils"

export default function ProcessModal({ organizationId, subOrganizationId, isOpen, setIsOpen, loading, setLoading, selectedItems, title="Confirm Processing", subTitle="Select which file status to process."}) {
    const CONTEXTUAL_TYPES = [
        {name: "Global Augmentation", value: 'GLOBAL', description: "The Global Augmentation method creates a single summary for the entire document and appends it to every chunk. This approach minimises processing overhead while ensuring a consistent context across all chunks."},
        {name: "Global Local Augmentation", value: 'GLOBALLOCAL', description: "The Global-Local Augmentation method enhances each chunk with a unique context by combining the document's summary, its neighbouring chunks, and the chunk itself. This ensures that each chunk retains both its local relevance and the broader document context."},
    ]
    
    const [selectedTag, setSelectedTag] = useState("unprocessed");
    const [contextualRetrievalType, setContextualRetrievalType] = useState("none");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // reset selectedTag to unprocessed
        setSelectedTag('unprocessed');
    }, [isOpen])

    const { collectionId } = useParams()
    const { addNewNotifcation, user } = useContext(Context)
    const { socket, l1Status } = useContext(FileManagerContext)

    const handleStartProcess = async () => {
        // handle StartProcess
        try {
            setLoading(true);
            const status_filter = selectedTag === "all" || selectedTag === 'selected-files' ? "" : selectedTag;
            const body = {
                "organization_id": organizationId,
                "sub_organization_id": subOrganizationId,
                "collection_id": collectionId,
                "prefix": "", // All files
                "status_filter": status_filter,
                "contextual_embedding_strategy": contextualRetrievalType === 'none' ? '' : contextualRetrievalType,
                "file_keys_filter": selectedItems?.map((item) => (removeFirstPrefix(item.prefix))) || [],
                "user": user?.name || "Unrecognized user"
            }
            const res = await kbWSService.get_l1_task_id(body);
            
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({
                    "task_id": res.data.task_id
                }));
            } else {
                addNewNotifcation("Request not sent. Please reload the page and try again.");
                console.error("WebSocket is not open. Unable to send message.");
            }

            setIsOpen(false);
        } catch (error) {
            console.error("Error processing collection:", error);
            addNewNotifcation(`Processing failed. Please try again.`, "danger")
        } finally {
            setLoading(false);
        }
    };

    subTitle = selectedTag === "unprocessed" ? `Process all unprocessed files with "New" status.` :
            selectedTag === "all"? `Process all files regardless of status.` :
            `Process all files that failed the processing and has a status "failed"`;

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" className="hidden">Process Files</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{title || "Confirm File Processing"}</DialogTitle>
                    <DialogDescription>
                        {subTitle || "Select which file tag you want to process."}
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4 py-4">
                    <div className="space-y-2">
                        <Label> Process filter </Label>
                        <Select value={selectedTag} onValueChange={setSelectedTag}>
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select a file tag" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem key="unprocessed" value="unprocessed">New Files</SelectItem>
                                <SelectItem key="all" value="all">All Files</SelectItem>
                                <SelectItem key="failed" value="failed">Failed Files</SelectItem>
                                <SelectItem disabled={selectedItems.length < 1} key="selected-files" value="selected-files">Selected files {`(${selectedItems.length})`} </SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="space-y-2">
                        <Label> Contextual retrieval type </Label>
                        <TooltipProvider>
                            <Popover open={open} onOpenChange={setOpen}>
                                <PopoverTrigger asChild>
                                    <Button variant="outline" role="combobox" aria-expanded={open} className="w-full justify-between">
                                        {contextualRetrievalType ? CONTEXTUAL_TYPES.find((option) => option.value === contextualRetrievalType)?.name || 'None' : "None"}
                                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-full p-0">
                                    <Command>
                                        <CommandInput placeholder="Search" />
                                        <CommandList>
                                            <CommandEmpty>No  option found.</CommandEmpty>
                                            <CommandGroup>
                                                {CONTEXTUAL_TYPES.map((option) => (
                                                    <CommandItem
                                                    key={option.value}
                                                    value={option.value}
                                                    onSelect={(currentValue) => {
                                                        setContextualRetrievalType(currentValue?.toUpperCase() === contextualRetrievalType ? "" : currentValue?.toUpperCase())
                                                        setOpen(false)
                                                    }}
                                                    className="flex items-center justify-between"
                                                    >
                                                        <div className="flex items-center">
                                                            <span>{option.name}</span>
                                                            <Check className={cn("ml-2 h-4 w-4", contextualRetrievalType === option.value ? "opacity-100" : "opacity-0")} />
                                                        </div>
                                                        <Tooltip>
                                                            <TooltipTrigger asChild>
                                                                <Button variant="ghost" size="icon" className="h-6 w-6 rounded-full p-0">
                                                                    <HelpCircle className="h-4 w-4" />
                                                                    <span className="sr-only">Info about {option.name}</span>
                                                                </Button>
                                                            </TooltipTrigger>
                                                            <TooltipContent side="right" align="start">
                                                                <p className="max-w-xs">{option.description}</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </CommandItem>
                                                ))}
                                            </CommandGroup>
                                        </CommandList>
                                    </Command>
                                </PopoverContent>
                            </Popover>
                        </TooltipProvider>
                    </div>
                </div>

                <DialogFooter>
                    <Button variant="outline" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleStartProcess} disabled={loading || l1Status === "processing" || l1Status === "checking"}>{loading ? "processing..." : "Confirm"}</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import WatsonXConfiguration from '../Common/WatsonXConfiguration';
import GroqConfiguration from '../Common/GroqConfiguration';
import AnatomylivePrompt from '../Common/AnatomyLivePrompt';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';
import { Separator } from 'components/ui/separator';
import { ArrowUpFromLine, WandSparkles, Globe } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Output } from './Output';
import { unifiedModelWSService } from 'api/services/PROJECT-O/WEBSOCKETS/UnifiedModel.websocket.service';
import { Context } from 'context/GlobalState';
import CommonModelSelectionModal from '../Common/CommonModelSelectionModal';
import StyledTextArea from "../StyledTextArea";
import { RagModal } from '../Common/RAG/RagModal';
import { useParams } from 'react-router-dom';
import { checkRequiredFields } from '../Instructions/helper';
import { Label } from 'components/ui/label';
import { instructionService } from 'api/services/BEX/instruction.service';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from 'components/ui/resizable';
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';
import { Trash2 } from 'lucide-react';
import SambaNovaConfiguration from '../Common/SambaNovaConfiguration';
import { Badge } from 'components/ui/badge';
import SaveOrUpdateInstruction from './SaveOrUpdateInstruction';
import { ImageSelectionModal } from '../Common/File/ImageSelectionModal';

export default function InstructionForm({form, setForm, loadedInstruction = null, setLoadedInstruction = null}) {

    const [output, setOutput] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [isContextModalOpen, setIsContextModalOpen] = useState(false)
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isImageSelectionModalOpen, setIsImageSelectionModalOpen] = useState(false);
    const [isInputDataModalOpen, setIsInputDataModalOpen] = useState(false);
    const { oragID, subOragID } = useParams();

    const openContextModal = () => setIsContextModalOpen(true);
    const closeContextModal = () => setIsContextModalOpen(false);
    const openInputDataModal = () => setIsInputDataModalOpen(true);
    const closeInputDataModal = () => setIsInputDataModalOpen(false);
    const openImageSelectionModal = () => setIsImageSelectionModalOpen(true);
    const closeImageSelectionModal = () => setIsImageSelectionModalOpen(false);
    const openSaveModal = () => setIsSaveModalOpen(true);
    const closeSaveModal = () => setIsSaveModalOpen(false);
    const openUpdateModal = () => setIsUpdateModalOpen(true);
    const closeUpdateModal = () => setIsUpdateModalOpen(false);
    
    const { addNewNotifcation, user } = useContext(Context);
    const { state, dispatch, instructions } = useContext(AiLabContext);

    const handleParameter = (data) => {
        if (form?.model_type === 'IBM') {
            setForm("watsonParameters", data)
        } else if (form?.model_type === 'Groq')  {
            setForm("groqParameters", data)
        } else if (form?.model_type === 'SambaNova')  {
            setForm("sambaNovaParameters", data)
        }
    }

    // useEffect(
    //     () => {
    //         console.log("Selected images: ", form?.selectedImages);
    //     }, [form?.selectedImages]
    // )

    const selectedModel = (data) => {
        setForm("selectedModel", data.modelName);
        setForm("model_type", data.modelType);
        setForm("region", data.modelRegion);
    }

    const handleCopyImages = (fileList) => {
        const formattedImages = fileList.map(item => ({
            url: item.presigned_url,
            name: item.name
        }))

        setForm("selectedImages", formattedImages)

        fileList.forEach(item => {
            addNewNotifcation(`${item.name} added.`, "success");
        }) 
    }

    const handleCopyImageUrl = (resultUrl, resultName) => {
        setForm("selectedImages", [{url: resultUrl, name: resultName}]);
        addNewNotifcation(`${resultName} added.`, "success");
    }

    const removeURL = (index) => {
        const updatedImageList = form?.selectedImages.filter((_, i) => i !== index);
        setForm("selectedImages", updatedImageList);
    }

    const handleSendPrompt = async () => {
    
        if (!form?.selectedModel) {
            return addNewNotifcation("Please select a foundation model.", "warning");
        } else if (!form?.instruction.trim()) {
            return addNewNotifcation("Please enter an instruction.", "warning");
        }

        try {
            setBtnLoading(true)
            setOutput("")
            
            let params = form?.watsonParameters;
            if (form?.model_type === 'IBM') {
                if (form?.watsonParameters.mode === "greedy") {
                    params = Object.fromEntries(
                        Object.entries(form?.watsonParameters).filter(([key]) => key === "max_new_tokens" || key === "mode" || key === "stop_sequences")
                    );
                }
            }

            let finalParams = {
                "IBM": params,
                "Groq": form?.groqParameters,
                "SambaNova": form?.sambaNovaParameters
            }
            
            const body = {
                "modelId": form?.selectedModel,
                "prompt": labHelper.ConstructPromptUnified({
                    "instruction": form?.instruction.trim(),
                    "context": form?.context.trim(),
                    "target_format": form?.targetFormat.trim(),
                    "sample_input": form?.sampleInput.trim(),
                    "sample_output": form?.sampleOutput.trim(),
                }),
                "parameters": finalParams[form?.model_type] || {},
                "query": form?.inputData.trim(),
                "region": form?.region || "jp-tok",
                "image_urls": form?.selectedImages.map(images => images.url)  || [],
                "style_guide": "",
                "output_format": "",
                "extra_content": "",
            }
            
            // console.log("Sent Prompt\n", body.prompt);
            // console.log("Sent URLS:\n", body.image_urls);
            const res = await unifiedModelWSService.experiment(body, form?.model_type);
            if (typeof res === "object" && res.model_type && res.result.prediction) {
                setOutput(res?.result?.prediction);
            } else {
                throw res;
            }
        } catch (error) {
            if (typeof error === "string" && error.includes("rate_limit_exceeded")) {
                addNewNotifcation("Model rate limit exceeded.", "warning");
            } else if (typeof error === "object" && error?.websocket_error) {
                addNewNotifcation("Error generating response.", "danger");
            } else {
                addNewNotifcation("Error generating response.", "danger");
            }
            console.log('Experiment Error: ', error);
        } finally {
            setBtnLoading(false)
        }
    }

    // Save
    const handleCreateInstruction = async (unique_name, modelDescription, isUpdate = false) => {
        const requiredFields = [
            { field: 'model_type', name: 'model type' },
            { field: 'unique_name', name: 'unique name' },
            { field: 'modelId', name: 'model' },
        ];
    
        const instructionObj = {
            model_type: isUpdate ? loadedInstruction?.model_type : form?.model_type,
            unique_name,
            modelId: form?.selectedModel,
            prompt: labHelper.ConstructPromptUnified({
                instruction: form?.instruction.trim(),
                context: form?.context.trim(),
                target_format: form?.targetFormat.trim(),
                sample_input: form?.sampleInput.trim(),
                sample_output: form?.sampleOutput.trim(),
            }),
            parameters: form?.model_type === 'IBM' ? form?.watsonParameters 
                : form?.model_type === 'SambaNova' ? form?.sambaNovaParameters 
                : form?.groqParameters,
            org_id: oragID,
            sub_org_id: subOragID,
            region: form?.region || "jp-tok",
            modelDescription,
            user_id: user._id,
            placeholders: {},
            metadata: {},
        };
    
        if (isUpdate) {
            instructionObj.instructionId = loadedInstruction?._id;
            if (loadedInstruction?.model_type !== form?.model_type) {
                instructionObj['new_model_type'] = form?.model_type;
                instructionObj['creation_timestamp'] = loadedInstruction.creation_timestamp;
            }
        }
    
        const checkFields = checkRequiredFields(instructionObj, requiredFields);
        if (!checkFields.isValid) {
            return addNewNotifcation(`Please fill in all required fields: ${checkFields.missingFields.map(item => item.name).join(', ')}`, 'warning');
        }
        if (!form?.instruction?.trim()) {
            return addNewNotifcation(`Please fill in all required fields: instruction`, 'warning');
        }
    
        try {
            isUpdate ? setUpdateLoading(true) : setSaveLoading(true);
    
            const res = isUpdate 
                ? await instructionService.updateModel(instructionObj) 
                : await instructionService.createModel(instructionObj);
    
            addNewNotifcation(`Instruction ${isUpdate ? 'updated' : 'created'} successfully`, 'success');
            setLoadedInstruction(res?.data?.unique_name ? res.data : loadedInstruction);
            // console.log('res: ', res);
            if (!isUpdate) {
                dispatch({type: "SET_INSTRUCTIONS", field: "savedInstructions", value: [...instructions, res.data] })
            } else {
                dispatch({type: "SET_INSTRUCTIONS", field: "savedInstructions", value: instructions.map((item) => item.unique_name === unique_name ? res.data : item) })
            }
        } catch (error) {
            console.log('error: ', error);
            const message = error.response?.status === 409 
                ? `Instruction with ${unique_name} already exists` 
                : `Something went wrong. Please try again`;
            addNewNotifcation(message, 'danger');
        } finally {
            if (isUpdate) {
                setUpdateLoading(false);
                closeUpdateModal();
            } else {
                setSaveLoading(false);
                closeSaveModal();
            }
        }
    };

    // anatomy action buttons
    const actions = {
        instruction: [
            {icon: <WandSparkles size={18}/>, onClick: () => {alert("clicked")}, disabled: true, visible: false}
        ],
        context: [
            {icon: <Globe size={18}/>, onClick: () => {alert("clicked")}, disabled: true, visible: false},
            {icon: <span className=''>RAG</span>, onClick: openContextModal, disabled: false, visible: true}
        ],
        inputData: [
            {icon: <ArrowUpFromLine size={18}/>, onClick: () => {alert("clicked")}, disabled: true, visible: false},
            {icon: <span className=''>RAG</span>, onClick: openInputDataModal, disabled: false, visible: true},
            {icon: <span className=''>Select File</span>, onClick: openImageSelectionModal, disabled: false, visible: true}
        ]
    }


    return (
        <div className="flex flex-row gap-4 w-full">
            <ResizablePanelGroup direction="horizontal" className="w-full gap-2">
                <ResizablePanel defaultSize={50} minSize={30} className='flex flex-col gap-4 w-full'>
                    <div className="border border-solid bg-card text-card-foreground shadow rounded-md px-4 py-6 h-fit">
                        <div className='flex gap-2'>
                            <div className="w-full">
                                <CommonModelSelectionModal
                                finalModel={{modelName: form?.selectedModel, modelType: form?.model_type, modelRegion: form?.region}}
                                onModelSelect={selectedModel}
                                />
                            </div>

                            {form?.model_type === 'IBM' ? (
                                <WatsonXConfiguration
                                parametersWatsonX={form?.watsonParameters} 
                                setParametersWatsonX={handleParameter} 
                                selectedModel={form?.selectedModel}
                                />
                            ) : form?.model_type === 'Groq' ? (
                                <GroqConfiguration
                                parametersGroq={form?.groqParameters} 
                                setParametersGroq={handleParameter} 
                                />
                            ) : form?.model_type === 'SambaNova' ? (
                                <SambaNovaConfiguration
                                parametersSambaNova={form?.sambaNovaParameters} 
                                setParametersSambaNova={handleParameter}
                                />
                            ) : ''}
                            
                        </div>

                        <div className="flex flex-row gap-2 mt-2 items-center">
                            <Label>Instruction: </Label>
                            <Badge className="">{loadedInstruction?.unique_name || "Unsaved"}</Badge>
                        </div>

                        <Separator className='my-3' />

                        <AnatomylivePrompt isExecute={false} actions={actions}/>
                        
                        <div className="flex flex-row justify-end gap-2 mt-4 px-1">
                            { loadedInstruction && (
                                <Button variant="outline" onClick={openUpdateModal}>
                                    Update instruction
                                </Button>
                            )}
                            <Button onClick={openSaveModal}>
                                {loadedInstruction?.unique_name ? "Copy Instruction" : "Save instruction"}
                            </Button>
                        </div>
                    </div>

                    <div className="border border-solid bg-card text-card-foreground shadow rounded-md px-4 py-6 h-fit">
                        <div className='flex flex-col gap-4 w-full p-1'>
                            <StyledTextArea
                                value={state.promptLab.form.inputData}
                                onChange={(e) => setForm('inputData', e.target.value)}
                                labelText="Input Data"
                                tooltipText="Include any data or details that the language model needs to process in order to generate
                                a meaningful response. This can include text, numbers, or structured data."
                                required={false}
                                maxRows={20}
                                actions={actions?.inputData || []}
                            />
                        </div>

                        <div>
                            <div>

                                <ul className="p-2">
                                    { form?.selectedImages && form?.selectedImages?.map((item, index) => (
                                        <li key={index} className="flex flex-row hover:bg-gray-100 justify-between gap-2 p-2 rounded-md group">
                                            <a className="text-xs cursor-pointer hover:underline w-fit" href={item.url} target="_blank" rel="noopener noreferrer">
                                                {item.name}
                                            </a>
                                            <button onClick={() => removeURL(index)} className="group-hover:block hidden">
                                                <Trash2 size={14}/>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div> 
                        </div>

                    </div>
                    {/* RAG modal */}
                    <RagModal isOpen={isContextModalOpen} onClose={closeContextModal} form={state.promptLab} setForm={setForm} field={"context"}/>
                    <RagModal
                        isOpen={isInputDataModalOpen}
                        onClose={closeInputDataModal}
                        form={state.promptLab}
                        setForm={setForm}
                        field={"inputData"}
                        handleCopyImageUrl={handleCopyImageUrl}
                    />
                    {/* Save/Update modal */}
                    <SaveOrUpdateInstruction
                        isOpen={isSaveModalOpen || isUpdateModalOpen}
                        onClose={isSaveModalOpen ? closeSaveModal : closeUpdateModal}
                        onSaveOrUpdate={handleCreateInstruction}
                        loading={isSaveModalOpen ? saveLoading : updateLoading}
                        loadedInstruction={loadedInstruction}
                        isUpdate={isUpdateModalOpen}
                    />
                    <ImageSelectionModal
                        isOpen={isImageSelectionModalOpen}
                        onClose={closeImageSelectionModal}
                        handleImageSelect={handleCopyImages}
                    />
                </ResizablePanel>
                <ResizableHandle />
                <ResizablePanel defaultSize={50} minSize={30} className="w-full">
                    <Output output={output} handleSendPrompt={handleSendPrompt} btnLoading={btnLoading}/>
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    )
}
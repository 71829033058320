import { useCallback, useContext, useState } from 'react'
import { Context } from 'context/GlobalState'
import { useParams } from 'react-router-dom'
import { fileManagerService } from 'api/services/BEX/fileManager.service'
import axios from 'axios'
import { setTuningParameters } from './data'
import { tuningLabService } from 'api/services/BEX/tuningLab.service'
import TuningForm2 from './TuningForm2'

export default function TuningForm({ onSubmit, editingExperiment, experiments }) {
    const [loading, setLoading] = useState(false)
    const {addNewNotifcation, user} = useContext(Context)
    const params = useParams()

    const handleUpdateSubmitExc = (data) => {
        editingExperiment? handleFormUpdate(data) : handleFormSubmit(data)
    }

    const handleFormUpdate = async (data) => {
        try {
            setLoading(true)  

            const newFilesToCos = data.files.filter(file => 
                !data?.presignedUrls?.some(fileUrl => fileUrl.name === file.name)
            );

            if(newFilesToCos.length > 0){
                await handleUploadFiles(newFilesToCos, data.deployment_name)
            }

            const newData = setTuningParameters(data)

            let filesDeleted = data.originalFiles.filter(file=> 
                !data?.files?.some(fileUrl => fileUrl.name === file.name)
            )

            const key = `FolderTuning/${user.id}/${data.deployment_name}`
            filesDeleted = filesDeleted.map(file => ({...file, Key:`${key}/${file.name}`}))

            const formData = { 
                ...newData, 
                files:filesDeleted,
                org_id:params.oragID, 
                sub_org_id:params.subOragID, 
                user_id:user._id 
            }

            const existingExperiment = await tuningLabService.updateTuningExperiment(formData)
         
            onSubmit({...existingExperiment.data, id:existingExperiment.data._id })

            addNewNotifcation("Experiment successfully updated.", "success")
        } catch (error) {
            console.error(error);
            addNewNotifcation("Something went wrong!.", "danger")
        } finally {
            setLoading(false)
        }
    }
  
    const handleFormSubmit = async (data) => {
        try {
            setLoading(true)  

            const deploymentName = data.deployment_name;
            if (deploymentName && experiments.some(exp => exp.deployment_name === deploymentName)) {
                addNewNotifcation("This deployment name is already in use. Please choose a different name.", "danger")
                return;
            }

            await handleUploadFiles(data.files, data.deployment_name)
            const newData = setTuningParameters(data)

            const formData = { 
                ...newData, 
                org_id:params.oragID, 
                sub_org_id:params.subOragID, 
                user_id:user._id 
            }

            const newExperiment = await tuningLabService.addTuningExperiment(formData)
            onSubmit({...newExperiment.data, id:newExperiment.data._id})

            addNewNotifcation("Experiment successfully added.", "success")
        } catch (error) {
            console.error(error);
            addNewNotifcation("Something went wrong!.", "danger")
        } finally {
            setLoading(false)
        }
    }

    const handleUploadFiles = useCallback( async (files, deployment_name) => {        
        if (files && files.length <= 0)  {
            return addNewNotifcation('Please add files to upload', 'warning')
        }

        const fileNamesArr = []
        const metadataArr = []

        Array.from(files).forEach((file) => {
            fileNamesArr.push(file.name)
            metadataArr.push(
                JSON.stringify({
                    lastModifiedDate: file.lastModifiedDate,
                    userId: user._id,
                    organizationId: params.oragID,
                    subOrganizationId: params.subOragID,
                })
            );
        })

        const uploadFilesObj = {
            organizationId: params.oragID,
            bucketId: `${params.oragID}-bex-demo-mode`,
            prefix: `FolderTuning/${user.id}/${deployment_name}` || null,
            fileNames: fileNamesArr,
            metadataArray: metadataArr
        }

        try {
            const response = await fileManagerService.getPreSignedUrls(uploadFilesObj)
            const { preSignedUrls } = response.data;
            // Step 2: Upload each file to IBM COS using its respective pre-signed URL
            await Promise.all(preSignedUrls.map(async ({ fileName, url }) => {
                const file = Array.from(files).find((file) => file.name === fileName);
                const res = await axios.put(url, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                });
                // console.log(res)
            }));
        } catch (error) {
            console.error(error);
            if (error.response?.data?.message === "\"prefix\" is not allowed to be empty")
                addNewNotifcation("Prefix is not allowed to be empty.", "warning")
            else
                addNewNotifcation("Files not uploaded.", "danger")
        } 
    }, [])
  
    return (
        <TuningForm2 
        handleUpdateSubmitExc={handleUpdateSubmitExc} 
        editingExperiment={editingExperiment} 
        loading={loading}
        />
    )
  }
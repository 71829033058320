import { Label } from 'components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import React, { useContext } from 'react'
import handler from '../OpCodeBuilderHAndC/OpCodeBuilderHandler'
import { OpCodeBuilderContext } from '../OpCodeBuilderHAndC/OpCodeBuilderContext'

const NextStep = ({opCodeStep, stepIndex, opCodeSteps, setOpCodeSteps}) => {
    const {indicators } = useContext(OpCodeBuilderContext)
    const isLastStep = stepIndex === opCodeSteps?.length - 1

    const handleNextStepChange = (value) => {
        const newOpCodeSteps = opCodeSteps.map((opCoStep) => 
            opCoStep.step_id === opCodeStep.step_id? {...opCoStep, next_step: value !== "-"? [value]: []} : opCoStep) 
        setOpCodeSteps(newOpCodeSteps)
    } 

    return (
        <>
            {!isLastStep && 
            <>
                <Label htmlFor={`param-${stepIndex}`}>Next Step</Label>
                <Select
                    onValueChange={(value) => handleNextStepChange(value)}
                    value={opCodeStep?.next_step[0] || ""}
                >
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select step" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem key={"default"} value={"-"}>
                            None
                        </SelectItem>
                        {handler.getNextSteps(opCodeSteps, stepIndex, indicators).map((item) => (
                            <SelectItem key={item.nextStep.label} value={item.nextStep.value}>
                                {item.nextStep.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </>
            }
        </>
    )
}

export default NextStep
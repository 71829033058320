/* eslint-disable max-lines */
import React, { useContext } from "react"
import { useState, useEffect } from "react"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { AlertCircle, ChevronDownIcon, PlusIcon } from "lucide-react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "components/ui/dialog"
import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, DropdownMenu, DropdownMenuLabel } from 'components/ui/dropdown-menu';
import { Label } from "components/ui/label"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip"
import { Switch } from "components/ui/switch"
import { Context } from "context/GlobalState"
import { OpCodeBuilderContext } from "../OpCodeBuilderHAndC/OpCodeBuilderContext"
import { ContextSelector } from "./ContextSelector"
import handler from "../OpCodeBuilderHAndC/OpCodeBuilderHandler"
import { useParams } from "react-router-dom"
import InputValidation from "components/InputValidation"
import labHelper from "pages/PromptingAndRagTraining/classes/AILabHelper"

export default function DynamicStaticInputBuilder({ onCombinedResultChange, previousOutputs, opCodeStep, opCodeSteps, setOpCodeSteps, stepIndex, inputText, nested }) {
    const [userInput, setUserInput] = useState("")
    const [selectedOutputs, setSelectedOutputs] = useState([])
    const [contextFields, setContextFields] = useState([])
    const [newContextField, setNewContextField] = useState("")
    const [realTimeData, setRealTimeData] = useState(false)
    const [dynamicCollection, setDynamicCollection] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const {globalContext, actions} = useContext(OpCodeBuilderContext)
    const {addNewNotifcation} = useContext(Context)
    const { oragID, subOragID } = useParams()
    const contextLen = 
        (contextFields?.length || 0) - 
        (realTimeData ? 1 : 0) - 
        (dynamicCollection ? 3 : 0);

    
    useEffect(() => {
        try{
            // if(opCodeStep.step_id && inputText){
                const result = {
                    state: "",
                    context: [],
                    output: [],
                  }
                
                const colonIndex = inputText?.indexOf(":")
                if (colonIndex !== -1) {
                    result.state = inputText?.slice(0, colonIndex).trim()
                }

                // Extract context items
                const contextRegex = /{{context\['(.*?)'\]}}/g
                let match
                while ((match = contextRegex.exec(inputText)) !== null) {
                    result.context.push(match[1])
                }

                // Extract output items
                const outputRegex = /{{output\['(.*?)'\]}}/g
                while ((match = outputRegex.exec(inputText)) !== null) {
                    result.output.push(match[1])
                }

                setRealTimeData(inputText?.includes("{{context['real_time_data']}}"))
                setDynamicCollection(opCodeSteps[stepIndex]?.input?.collection_id?.includes("{{context['collection_id']}}"))
    
                setUserInput(result.state || "")
                setContextFields(result.context || [])
                setSelectedOutputs(result.output || [])
            // }
        } catch(err) {
            console.log(err)
        }
     
    }, [inputText])
  
    const updateResult = (cFields, oFields, inputText) => {
        let result = ""
        result += inputText.trim()
        if (result && !result.endsWith(":")) {
            result += ":"
        }
        
        const contextPart = cFields
            .filter(field => !['collection_id', 'sub_org_id', 'org_id'].includes(field))
            .map(field => `{{context['${field}']}}`)
            .join(" ");
        const outputPart = oFields.map((output) => `{{output['${output}']}}`).join(" ")
        const selectionPart = `${contextPart} ${outputPart}`.trim()
        if (selectionPart) {
            result += ` ${selectionPart}`
        }
        
        onCombinedResultChange(result)
    }
    
    const updateResultContext = (cFields) => {
        updateResult(cFields, selectedOutputs, userInput)
    }
    
    const handleInputChange = (e) => {
        const value  = e.target.value
        setUserInput(value)
        updateResult(contextFields, selectedOutputs, value)
    }
    
    const handleOutputToggle = (output) => {
        const oFields = selectedOutputs.includes(output) ? selectedOutputs.filter((item) => item !== output) : [...selectedOutputs, output]
        setSelectedOutputs(oFields)
        updateResult(contextFields, oFields, userInput)
    }

    const addContextField = (e) => {
        e.preventDefault()
        if (labHelper.validateContextKey(newContextField).isValid === false) return;


        if(newContextField === "real_time_data"){
            addNewNotifcation("This key 'real_time_data' is reserved and cannot be used.", "warning")
            return
        }
          
        if(globalContext.includes(newContextField) && newContextField !== "real_time_data"){
            addNewNotifcation("This key already exists, please use different key.", "warning")
            return
        }
    
        if (newContextField) {
            setContextFields([...contextFields, newContextField])
            setNewContextField("")
            actions({type:"SET_GLOBAL_CONTEXT", payload:[...globalContext, newContextField]})   
            updateResultContext([...contextFields, newContextField])
        }

    }

    const handleAddContextFromOtherSteps = (context, checked) => {
        let cFields = [...contextFields]
        if (checked) {
            cFields = [...contextFields, context]
            setContextFields(cFields)
        } else {
            cFields = contextFields.filter((c) => c !== context)
            setContextFields(cFields)
        }

        updateResultContext(cFields)
    }

    const removeContextField = (contextToRemove) => {
        const cFields = contextFields.filter((c) => c !== contextToRemove)
        setContextFields(cFields)
        actions({type:"SET_GLOBAL_CONTEXT", payload:globalContext.filter((c) => c !== contextToRemove)})
        actions({type:"SET_REMOVED_CONTEXT", payload:contextToRemove})
        handler.removeSpecificContextFromOtherSteps(setOpCodeSteps, opCodeSteps, contextToRemove)
        updateResultContext(cFields)
    }

    const handleRealTimeData = (value) => {
        setRealTimeData(value)
        let cFields = [...contextFields]

        if(value){
            cFields = [...contextFields, 'real_time_data']
            setContextFields(cFields)
        } else {
            cFields = contextFields.filter((f) => f !== 'real_time_data')
            setContextFields(cFields)
        }
        updateResultContext(cFields)
    }

    const handleDynamicCollection = (value) => {
        setDynamicCollection(value)
        let cFields = [...contextFields]

        if(value){
            cFields = [...contextFields, 'org_id', 'sub_org_id', 'collection_id']
            setContextFields(cFields)
            const newOpCodeSteps = [...opCodeSteps]
            newOpCodeSteps[stepIndex].input.org_id = `{{context['org_id']}}`
            newOpCodeSteps[stepIndex].input.sub_org_id = `{{context['sub_org_id']}}`
            newOpCodeSteps[stepIndex].input.collection_id = `{{context['collection_id']}}`
            setOpCodeSteps(newOpCodeSteps)
        } else {
            cFields = contextFields.filter((f) => f !== 'org_id' && f !== 'sub_org_id' && f !== 'collection_id')
            setContextFields(cFields)
            const newOpCodeSteps = [...opCodeSteps]
            newOpCodeSteps[stepIndex].input.org_id = oragID
            newOpCodeSteps[stepIndex].input.sub_org_id = subOragID
            newOpCodeSteps[stepIndex].input.collection_id = ""
            setOpCodeSteps(newOpCodeSteps)
        }
        // updateResultContext(cFields)

    }

    const sortedAndFilteredContext = (searchTerm) => {
        const filteredContext = globalContext?.filter((field) => field !== "real_time_data" && field !== 'org_id' && field !== 'sub_org_id' && field !== 'collection_id')
          .filter((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()))
    
        return [
          ...contextFields.filter((field) => filteredContext.includes(field)),
          ...filteredContext.filter((field) => !contextFields.includes(field)),
        ]
    }
    
    return (
        <>  
            {opCodeStep?.step_type === "Non-LLM" &&
            (<div className="flex flex-col justify-center gap-1 pt-1">
                <Label htmlFor={`input-${1}`} className="flex items-center gap-2">
                    Use Dynamic Collection
                </Label>
                <div className="flex items-center bg-white gap-2 h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
                    <Switch id="real-time-data" checked={dynamicCollection} onCheckedChange={handleDynamicCollection} />
                    <span className="text-xs text-gray-400"> will be used during the execution</span>
                </div>

            </div>)}
            <div className="flex flex-col justify-center gap-1 pt-1">
                <Label htmlFor={`input-${1}`} className="flex items-center gap-2">
                    Include (real_time_data)
                </Label>
                <div className="flex items-center bg-white gap-2 h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
                    <Switch id="real-time-data" checked={realTimeData} onCheckedChange={handleRealTimeData} />
                    <span className="text-xs text-gray-400"> will be used during the execution</span>
                </div>

            </div>
            <div className="flex flex-col justify-center gap-1 pt-1">
                <Label htmlFor={`input-${1}`} className="flex items-center gap-2">
                    Static Input string
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <AlertCircle className="h-4 w-4 cursor-pointer" />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-xs">
                                <p className="text-sm">
                                    If you want to enter an additional string on top of the real-time data, you can enter it here.
                                    Example: <span className="font-medium">"Analyze customer feedback"</span>.
                                </p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </Label>

                <Input value={userInput} onChange={handleInputChange} placeholder="Enter your text here..." className="w-full bg-white" />
            </div>
            <div className="flex flex-col justify-center gap-1 pt-1">
                <Label htmlFor={`input-${1}`} className="flex items-center gap-2">
                    Dynamic Context Keys
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <AlertCircle className="h-4 w-4 cursor-pointer" />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-xs">
                                <p className="text-sm">
                                    Additional fields on top of real-time data. These context keys will be used later during execution to enter 
                                    and send with execution. Each step must have unique context keys, and you can select from previous steps' 
                                    context as well.
                                </p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </Label>

                <Button variant="outline" className="w-full" onClick={() => setIsDialogOpen(true)}>
                    {contextLen > 0 ? `${contextLen} context ${contextLen > 1?  "keys" : "key" } added` : "Add Context" }
                </Button>
            </div>
            {(opCodeStep?.step_type !== "Identification" && (nested? stepIndex !== 0 : stepIndex !== 1)) &&
                <div className="flex flex-col justify-center gap-1 pt-1">
                    <Label htmlFor={`input-${1}`} className="flex items-center gap-2">
                        Outputs From Prevoius Steps 
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <AlertCircle className="h-4 w-4 cursor-pointer" />
                                </TooltipTrigger>
                                <TooltipContent className="max-w-xs">
                                    <p className="text-sm">
                                        Additional selections of previous steps' output (result) on top of real-time data. These outputs 
                                        will be used as input for this step.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </Label>
                    <DropdownMenu className="w-full">
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="w-full justify-between">
                                <span className="truncate">
                                    {selectedOutputs.length > 0 
                                        ? `Selected: ${selectedOutputs.length }`
                                        : "Select previous step outputs"}
                                </span>
                                <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <DropdownMenuLabel>Previous Steps Output</DropdownMenuLabel>
                            {previousOutputs.map((output) => (
                                <DropdownMenuCheckboxItem
                                key={output.stepId}
                                checked={selectedOutputs.includes(output.prevOutp.value)}
                                onCheckedChange={() => handleOutputToggle(output.prevOutp.value)}
                                >
                                    {output.prevOutp.label}
                                </DropdownMenuCheckboxItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            }

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Manage global context fields available across all steps</DialogTitle>
                        <DialogDescription>Add, select, or remove context fields. Added context will be available to all steps to select or remove, so removing a context field used in multiple steps will delete it from all of them.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <form onSubmit={addContextField} className="flex flex-row space-x-2">
                            <InputValidation
                                value={newContextField}
                                onChange={(e) => setNewContextField(e.target.value)}
                                placeholder="Enter context field"
                                validation={labHelper.validateContextKey}
                            />
                            <Button
                                disabled={newContextField.trim() === '' || labHelper.validateContextKey(newContextField).isValid === false}
                                onClick={addContextField}>
                                <PlusIcon className="h-4 w-4" /> Add
                            </Button>
                        </form>
                        <ContextSelector 
                        contextFields={contextFields}
                        sortedAndFilteredContext={sortedAndFilteredContext}
                        onAddContext={handleAddContextFromOtherSteps}
                        onRemoveContext={removeContextField}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
    }
/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import { assistantInstanceService, opcodeAssistantService } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import { PageContent, PageContentHeader, PageContentHeaderText, PageContentHeaderTitle, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from 'components/NewDesignComponents/PageLayout';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { ScrollArea } from 'components/ui/scroll-area';
import AssistantForm from './AssistantForm';
import {  Bot, MoreVertical, Settings, Trash2 } from 'lucide-react'
import { Context } from 'context/GlobalState';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import AlertModal from 'components/Alertmodal';
import { formatDateToDDMMYYY } from 'utils/timeUtils';
import { getOrganizationMember } from 'utils/organizationUtils';
import { OrganizationContext } from 'context/OrganizationContext';
import ManageAssistantDialog from './UpdateAssistant';
import { SearchBar } from 'pages/PromptingAndRagTraining/components/Instructions/Filters';
import { opcodeService } from 'api/services/BEX/opcode.service';
import { TextSkeleton } from 'components/ui/text-skeleton';
import useLazyLoad from 'hooks/useLazyLoading';

export default function Assistants() {

    const [assistantInstances, setAssistantInstances] = useState([])
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')

    const { oragID, subOragID } = useParams()


    const getFilteredList = () => {
        return assistantInstances.filter(instance =>
            instance.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            instance._id?.toLowerCase().includes(searchTerm.toLowerCase())
        )
    };
    
    // Handle search term change
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    useEffect(() => {

        opcodeAssistantService.listOpcodeAssistants(oragID, subOragID)
        .then((res) => {
            setAssistantInstances(res.data)
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    return (
        <PageLayout>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Build your Enterprise
                    </PageTitle>
                    <PageSubTitle>
                        Agentic Workforce
                    </PageSubTitle>
                    <PageDescription>
                        Empower your business with AI-driven assistants that work alongside your team. Automate tasks, enhance decision-making, and drive productivity with a workforce built for the future.
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button onClick={() => setShow(true)} className='w-full'> Add new assistant </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent loading={loading}>
                <PageContentHeader>
                    <PageContentHeaderTitle>Assistant Builder:</PageContentHeaderTitle>
                    <PageContentHeaderText>Manage your Agentic AI Assistants</PageContentHeaderText>
                </PageContentHeader>
                <div className="w-full mx-auto p-2 md:p-6">
                    <div className="space-y-4">
                        <SearchBar onSearch={handleSearch} />
                        {getFilteredList().length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {getFilteredList()?.map((item) => (
                                    <AssistantCard
                                    key={item._id}
                                    onManage={() => {
                                        setSelectedCard(item)
                                        setUpdateModal(true)
                                    }}
                                    {...item} setAssistantInstances={setAssistantInstances} />
                                ))}
                            </div>
                        ) : (
                            <div className='flex flex-grow justify-center items-center'>
                                <span className="text-lg font-semibold mb-2">No Activated Assistant Found </span>
                            </div>
                        )}
                    </div>
                </div>

                {/* create new assistant dialog */}
                <CreateNewAssistantDialog show={show} setShow={setShow} setAssistantInstances={setAssistantInstances} />

                {/* update assistant dialog */}
                <ManageAssistantDialog show={updateModal} setShow={setUpdateModal} selectedCard={selectedCard} setAssistantInstances={setAssistantInstances} />

            </PageContent>
        </PageLayout>
    )
}

const AssistantCard = ({ _id, title, status, created, createdBy, default_opcode, onManage, setAssistantInstances }) => {

    const [delModal, setDelModal] = useState(false)
    const [delBtn, setDelBtn] = useState(false)
    const [createdByName, setCreatedByName] = useState('')
    const [assistantType, setAssistantType] = useState(null)
    const [assistantTypeLoading, setAssistantTypeLoading] = useState(true)

    const navigate = useNavigate()
    const { selectedOrganization } = useContext(OrganizationContext)
    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    const fetchOpcodeInfo = async () => {
        try {
            getOrganizationMember(createdBy, selectedOrganization)
            .then((name) => {
                setCreatedByName(name || '-')
            }).catch((error) => {
                console.log('error: ', error);
            })

            if (default_opcode) {
                const opcodeInfo = await opcodeService.getOpCodeById(oragID, subOragID, default_opcode)
                setAssistantType(opcodeInfo.data.type)
            } else {
                setAssistantType('Not found')
            }
        } catch (error) {
            if (error.response?.status === 404) {
                setAssistantType('Not found')
            }
            console.log('error: ', error);
        } finally {
            setAssistantTypeLoading(false)
        }
    }

    const { elementRef } = useLazyLoad(fetchOpcodeInfo)

    const handleDeleteAssistant = async () => {

        setDelBtn(true)

        
        try {
            const delObj = {organizationId: oragID, subOrganizationId: subOragID, assistantId: _id}
            await assistantInstanceService.deleteAssistant(delObj)
            
            // delete assistant in redis (project o)
            const delOpcodeAssistantObj = {org_id: oragID, sub_org_id: subOragID, assistant_name: _id}
            await opcodeAssistantService.deleteOpcodeAssistant(delOpcodeAssistantObj)

            addNewNotifcation('Assistant deleted successfully', 'success')
            setAssistantInstances(prev => prev.filter(item => item._id !== _id))
        } catch(error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }finally {
            setDelBtn(false)
            setDelModal(false)
        }

    }

    const handleStopPropagation = (e, callback) => {
        e.stopPropagation()
        if (callback) callback()
    }

    return (
        <Card ref={elementRef} className='cursor-pointer' onClick={() => navigate(`${_id}`)}>
            <CardHeader>
                <div className='flex w-full justify-between '>
                    <div className='w-full flex flex-col truncate'>
                        <CardTitle className='cursor-pointer w-full hover:underline truncate'>{title}</CardTitle>
                        <CardDescription>{_id}</CardDescription>
                    </div>
                    <DropdownMenu className='min-w-4' modal={false}>
                        <DropdownMenuTrigger onClick={handleStopPropagation} asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <MoreVertical className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent onClick={handleStopPropagation} align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={onManage}>
                                <Settings className="mr-2 h-4 w-4" />
                                <span>Manage</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => setDelModal(true)} className="text-red-600">
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Delete</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent>
                <div className="flex gap-3 items-center">
                    <span className='rounded-full size-16 flex justify-center items-center bg-[#b1092a]'>
                        <Bot size={28} color='white'/>
                    </span>
                    <ul className="flex flex-col">
                        {/* <li>Purpose: </li> */}
                        <li className='text-sm text-muted-foreground flex items-center gap-2'>
                            Opcode type:
                            <TextSkeleton lines={1} width={70} loading={assistantTypeLoading} className='truncate'>
                                <span> {assistantType} </span>
                            </TextSkeleton>
                        </li>
                        <li className='text-sm text-muted-foreground'>Created on: <span>{formatDateToDDMMYYY(created)}</span> </li>
                        <li className='text-sm text-muted-foreground flex items-center gap-2'>
                            Created by:
                            <TextSkeleton lines={1} width={70} loading={createdByName === ''} className='truncate'>
                                <span> {createdByName} </span>
                            </TextSkeleton>
                        </li>
                        <li className='text-sm text-muted-foreground'>Status: <span> {status} </span> </li>
                    </ul>
                </div>
            </CardContent>

            {/* delete collection modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDeleteAssistant} />
        </Card>
    )
}

const CreateNewAssistantDialog = ({ show, setShow, setAssistantInstances }) => {
    const [btnLoading, setBtnLoading] = useState(false)

    const initialData = {
        title: '',
        description: '',
        opcodes: [],
        default_opcode: '',
    }

    const [assistantForm, setAssistantForm] = useState(initialData)

    const { oragID, subOragID }= useParams()
    const { addNewNotifcation }= useContext(Context)    

    const handleCreateAssistantAndOpcode = async () => {
        if (!assistantForm.title || assistantForm.opcodes.length <= 0 || !assistantForm.default_opcode) {
            return addNewNotifcation('Please fill in all required fields', 'warning')
        }

        try {
            setBtnLoading(true)

            const updateOrg = {
                title: assistantForm.title,
                description: assistantForm.description,
                status: 'active',
                organizationId: oragID,
                subOrganizationId: subOragID,
            }

            // create assistant instance
            const addAssistantRes = await assistantInstanceService.addAssistantInstance(updateOrg)
            console.log('addAssistantRes: ', addAssistantRes);

            const opcodeAssistantObj = {
                "org_id": oragID,
                "sub_org_id": subOragID,
                "assistant_name": addAssistantRes.data._id,
                "description": assistantForm.description,
                "opcodes": assistantForm.opcodes,
                "default_opcode": assistantForm.default_opcode,
                "status": "active",
                "title": assistantForm.title,
            }
            // create assistant in redis
            const opcodeAssistantRes = await opcodeAssistantService.createOpcodeAssistant(opcodeAssistantObj)

            addNewNotifcation('Assistant created successfully', 'success')
            setAssistantInstances(prev => ([...prev, opcodeAssistantRes.data]))
            setShow(false)
        } catch (error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        } finally {
            setBtnLoading(false)
        }

    }

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full min-h-[500px] max-w-lg p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create new assistant</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new assistant and link it to an opcode.</DialogDescription>
                    </DialogHeader>

                    <div className='flex flex-col mt-4 min-h-[400px]'>

                        <div className='flex flex-col flex-grow'>
                            <AssistantForm form={assistantForm} setForm={setAssistantForm} />
                        </div>
                    </div>

                    <DialogFooter className='mt-4'>
                        <div className='space-x-3'>
                            <Button variant='outline' onClick={() => setShow(false)}>
                                cancel
                            </Button>
                            <Button disabled={btnLoading} onClick={handleCreateAssistantAndOpcode}>
                                {btnLoading ? 'Creating assistant...' : 'Create assistant'}
                            </Button>
                        </div>
                    </DialogFooter>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}
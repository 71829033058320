import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from 'components/ui/card';
import InstructionDialog from './InstructionDialog';

const CodeEditor = () => {

    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <Card className="w-full flex flex-col justify-between h-[620px]">
            <CardHeader className="p-0">
                <InstructionDialog/>
            </CardHeader>
            <CardContent className="flex-1 grid grid-cols-1 p-0">
 
                {loading && (
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="loader">Loading VS Code...</div>
                    </div>
                )}
                <iframe
                    src={`${process.env.REACT_APP_AI_LAB_VS_CODE}/`}
                    style={{ border: 'none', width: '100%', height: '100%' }}
                    className='rounded-lg'
                    title="Embedded Site"
                    onLoad={handleLoad}
                />
            </CardContent>
        </Card>
    );
};

export default CodeEditor;
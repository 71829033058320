import React, { useContext, useState, useEffect } from 'react'
import StyledTextArea from "../StyledTextArea";
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';

const reservedKeywords = [
    "Instruction:", "Context:", "Target Format:", "Sample Input:", "Sample Output:",
    "## Instruction", "## Context", "## Target Format", "## Sample Input", "## Sample Output"
];

const tooltips = {
    instruction: "Provide specific tasks or questions for the language model to address. This sets the purpose and direction for the response.",
    context: "Supply background information or details that help the language model understand the scenario or topic, ensuring relevant and accurate responses.",
    targetFormat: "Specify the structure and style of the response. This ensures that the output is consistent with your needs, whether it's a specific layout, tone, or type of information.",
    sampleInput: "Offer an example of what the input data might look like. This helps clarify the format and type of data expected.",
    sampleOutput: "Show a representative example of the desired response. This demonstrates the format and quality of output you are looking for from the language model.",
    inputData: "Include any data or details that the language model needs to process in order to generate a meaningful response. This can include text, numbers, or structured data."
};

const AnatomyLivePrompt = ({ isExecute=false, actions = {} }) => {
    const { state, dispatch } = useContext(AiLabContext);
    const [errors, setErrors] = useState({});

    const validateInput = (field, value) => {
        const lowerCaseValue = value.toLowerCase();
        const foundKeyword = reservedKeywords.find(keyword => lowerCaseValue.includes(keyword.toLowerCase()));
        
        setErrors(prev => ({
            ...prev,
            [field]: foundKeyword ? `Reserved keyword detected: '${foundKeyword}'. Please revise your text to proceed.` : ""
        }));
        
        return !foundKeyword;
    };

    const handleFormChange = (field, value) => {
        validateInput(field, value)

        dispatch({
            type: 'UPDATE_FIELD',
            field,
            value,
        });
    };

    // Revalidate errors when state updates externally
    useEffect(() => {
        const newErrors = {};
        ["instruction", "context", "targetFormat", "sampleInput", "sampleOutput"].forEach(field => {
            const value = state?.promptLab?.form?.[field] || "";
            const lowerCaseValue = value.toLowerCase();
            const foundKeyword = reservedKeywords.find(keyword => lowerCaseValue.includes(keyword.toLowerCase()));
            newErrors[field] = foundKeyword ? `Reserved keyword detected: '${foundKeyword}'. Please revise your text to proceed.` : "";
        });
        setErrors(newErrors);
    }, [state?.promptLab?.form]);

    return (
        <div className='flex flex-col gap-4 px-1 py-1'>
            {["instruction", "context", "targetFormat", "sampleInput", "sampleOutput"].map((field) => (
                <div key={field} className="flex flex-col gap-2">
                    <StyledTextArea
                        value={state?.promptLab?.form?.[field] || ""}
                        onChange={(e) => handleFormChange(field, e.target.value)}
                        labelText={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                        tooltipText={tooltips[field]}
                        required={field === "instruction"}
                        actions={actions?.[field] || []}
                    />
                    {errors[field] && <span className='text-xs text-red-400'>{errors[field]}</span>}
                </div>
            ))}
            {isExecute && (
                <StyledTextArea
                    disabled={!isExecute}
                    value={state?.promptLab?.form?.inputData}
                    onChange={(e) => handleFormChange("inputData", e.target.value)}
                    labelText="Input Data"
                    tooltipText={tooltips.inputData}
                    required={false}
                />
            )}
        </div>
    );
}

export default AnatomyLivePrompt
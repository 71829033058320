/* eslint-disable max-lines */
import { Button } from "components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Context } from "context/GlobalState"
import { channelService, opcodeAssistantService } from "api"
import { NewCombobox } from "components/ui/combobox"

export const ChannelTypeUpdateDialog = ({ isOpen, setOpenEmailModal, channel, setEmailChannels }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [emailUpdateObject, setEmailUpdateObject] = useState(channel)
    const [assistants, setAssistants] = useState([{name: 'None', value: ''}])
    const [assistantsLoading, setAssistantsLoading] = useState(true)
    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    useEffect(() => {
        if (!isOpen) return
        opcodeAssistantService.listOpcodeAssistants(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            setAssistants(prev => ([...prev, ...res.data.map(item => ({name: item.title, value: item._id}))]))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setAssistantsLoading(false)
        })
    }, [isOpen])

    const handleOnSubmit = () => {

        setBtnLoading(true)

        const emailObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            channelId: emailUpdateObject._id,
            topic: emailUpdateObject.topic,
            assistantId: emailUpdateObject?.assistantId === 'none' ? '' : emailUpdateObject?.assistantId
        }

        channelService.updateChannel(emailObj)
        .then((res) => {
            console.log('res: ', res);
            setEmailChannels(prev => {
                return prev.map((item) => {
                    if (item._id === emailUpdateObject._id){
                        return {...emailUpdateObject}
                    } else {
                        return {...item}
                    }
                })
            })
            addNewNotifcation('Email Updated successfully', 'success')
            setOpenEmailModal(false)
        }).catch(error => {
            console.log('error: ', error);
            if (error.response.status === 404) {  
                addNewNotifcation('Email not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    const handleChange = (value, type) => {
        setEmailUpdateObject(prev => {
            return {...prev, [type]: value}
        })
    }

    if (!emailUpdateObject) return 

    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle> Manage Channel</DialogTitle>
                    <DialogDescription> Update channel information</DialogDescription>
                </DialogHeader>
                <div className="p-6 space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="address">Address</Label>
                        <Input id='address' disabled={true} defaultValue={emailUpdateObject.address} />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="topic">Email Topic</Label>
                        <Input id='topic' value={emailUpdateObject.topic} onChange={(e) => handleChange(e.target.value, 'topic')} placeholder="Enter email topic" />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="assistant">Assistant</Label>
                        <NewCombobox comboboxWidth='w-full' items={assistants} setter={(value) => handleChange(value, 'assistantId')} selectedValue={emailUpdateObject.assistantId} disabled={assistantsLoading} />
                    </div>
                </div>

                <DialogFooter>
                    <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                    <Button onClick={handleOnSubmit} disabled={btnLoading}> Submit </Button>
                </DialogFooter>
            </DialogContent>

        </Dialog>
    )
}

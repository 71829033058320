import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/ui/dialog";
import { Label } from "components/ui/label";
import { useContext, useEffect, useState } from "react";
import { SubOrgContext } from "context/SubOrganizationContext";
import { Combobox } from "components/ui/combobox";
import { Button } from "components/ui/button";
import { FileSelection } from "./FileList";
import { getFileSystem } from "./FileSystemHelper";
import { useParams } from "react-router-dom";
import { Context } from "context/GlobalState";
import { LoaderSpinner } from "components/LoaderSpinner";

export function ImageSelectionModal({ isOpen, onClose, handleImageSelect }) {
    const { collections } = useContext(SubOrgContext);
    const { addNewNotifcation } = useContext(Context);

    const [selectedCollection, setSelectedCollection] = useState(null);
    const [fileSystem, setFileSystem] = useState([]);
    const [isFetchingFiles, setIsFetchingFiles] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const { oragID, subOragID } = useParams();

    const closeImageSelectionModal = () => onClose();

    const initializeFileSystem = async () => {
        try {
            setFileSystem([]);
            console.log("Fetching files from the collection...");
            setIsFetchingFiles(true);

            const files = await getFileSystem(collections, oragID, subOragID, selectedCollection?._id);
            setFileSystem(files);

            addNewNotifcation("Files fetched successfully.", "success");
        } catch (error) {
            console.error(`Failed to fetch files:`, error);
            addNewNotifcation("Could not retrieve the files after multiple attempts.", "danger");
        } finally {
            setIsFetchingFiles(false);
        }
    };

    const onFileClick = (fileList) => {
        setSelectedFiles(fileList);
    }

    const handleFileSelect = (fileList) => {
        // console.log("CONFIRMED SELECTED FILES:", fileList)
        dispatch({type: "SET_DOCUMENT", value: fileList[0]});
    }

    useEffect(() => {
        if (selectedCollection?._id && selectedCollection?._id != '')
            initializeFileSystem();
    }, [selectedCollection?._id]);

    // useEffect(() => {
    //     console.log("Selected image: ", selectedFiles)
    // }, [selectedFiles])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-6xl select-none h-fit max-h-[800px] min-h-[300px]">
                <DialogHeader>
                    <DialogTitle>Input Data Image Selection</DialogTitle>
                    <DialogDescription>
                        Select an image file from the collection.
                    </DialogDescription>
                </DialogHeader>
                <div className="w-full">
                    <Label>Collection</Label>
                    <div className="flex flex-col gap-2">
                        <Combobox 
                            id="collection-combobox"
                            comboboxWidth='w-[300PX]' 
                            items={collections}
                            setter={setSelectedCollection}
                            selectedValue={selectedCollection} 
                            disabled={collections?.length === 0} 
                            />

                        {isFetchingFiles ? 
                            <div className="flex flex-row mx-auto gap-2 h-fit text-gray-400">
                                <span>Fetching Files</span>
                                <LoaderSpinner className='w-6 h-6'/>
                            </div>
                        : !fileSystem ? 
                            <div className="flex flex-row mx-auto gap-2 h-fit text-gray-400">
                                <span>No Files</span>
                            </div>
                        : <FileSelection fileSystem={fileSystem} onFileClick={onFileClick} multiple={false}/>}

                        <Button 
                            disabled={selectedFiles.length === 0}
                            onClick={() => {
                                handleImageSelect(selectedFiles)
                                closeImageSelectionModal()
                            }}>
                            Select
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
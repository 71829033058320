/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/ui/dialog";
import { Label } from 'components/ui/label';
import InputValidation from 'components/InputValidation';
import { Textarea } from 'components/ui/textarea';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';


function SaveOrUpdateInstruction({ isOpen, onClose, onSaveOrUpdate, loading, loadedInstruction, isUpdate }) {
    const [uniqueName, setUniqueName] = useState('');
    const [modelDescription, setModelDescription] = useState('');

    useEffect(() => {
        if (loadedInstruction && isUpdate) {
            setUniqueName(loadedInstruction.unique_name);
            setModelDescription(loadedInstruction.modelDescription);
        } else {
            setUniqueName("");
            setModelDescription("");
        }
    }, [isOpen, isUpdate]);

    const handleSaveOrUpdate = async () => {
        await onSaveOrUpdate(uniqueName, modelDescription, isUpdate);
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px] select-none">
                <DialogHeader>
                    <DialogTitle>
                        {
                            loadedInstruction && isUpdate ? 'Update Instruction'
                            : loadedInstruction && !isUpdate ? "Copy instruction"
                            : 'Save Instruction'
                        }
                    </DialogTitle>
                    <DialogDescription>
                        Please make sure that you have experimented with your instruction and are satisfied with the output it is giving.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Unique Name
                        </Label>
                        <InputValidation
                            id="name"
                            value={uniqueName}
                            onChange={(e) => setUniqueName(e.target.value)}
                            className="col-span-3"
                            placeholder="Enter a unique name for your instruction"
                            disabled={isUpdate}
                            validation={labHelper.validateUniqueName}
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="description" className="text-right">
                            Description
                        </Label>
                        <Textarea
                            id="description"
                            value={modelDescription}
                            onChange={(e) => setModelDescription(e.target.value)}
                            className="col-span-3"
                            placeholder="Enter description for your instruction"
                        />
                    </div>
                </div>
                <div className="modal-actions flex flex-row gap-2 items-center justify-end">
                    <Button variant="outline" onClick={onClose}>Cancel</Button>
                    <Button
                        className='px-2 w-fit min-w-[70px]'
                        size='icon'
                        variant="default"
                        disabled={loading || labHelper.validateUniqueName(uniqueName).isValid === false}
                        onClick={handleSaveOrUpdate}>
                        {loading ? (isUpdate ? 'Updating instruction...' : 'Saving instruction...') : (isUpdate ? 'Update' : 'Save')}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default SaveOrUpdateInstruction;
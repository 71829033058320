import { Context } from "context/GlobalState";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SubOrgDropdown from "./SubOrgDropdown";

export default function DashboardSubHeader() {
    // Local state for selected organization
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    
    const { oragID, subOragID } = useParams();
    const { organizations } = useContext(Context);

    useEffect(() => {
        if (organizations && oragID) {
            const foundOrganization = organizations.find(item => item._id === oragID);
            setSelectedOrganization(foundOrganization);
        }
    }, [organizations, oragID]);

    const userSubOrganizationCount = selectedOrganization?.subOrganizations?.length || 0;
    const hasEnoughSubOrganizations = userSubOrganizationCount > 0;

    if (!subOragID || !selectedOrganization) return null;

    return (
        <header className="bg-white dark:bg-gray-900 px-6 flex items-center justify-between">
            <div className="flex items-center gap-4">
                {/* SubOrganizations Selection */}
                {hasEnoughSubOrganizations && (
                    <SubOrgDropdown
                    urlSubOrg={selectedOrganization.subOrganizations.find(subOrg => subOrg._id === subOragID)}
                    />
                )}
            </div>
        </header>
    );
}

import { Card, CardContent, CardHeader } from "components/ui/card";
import { ScrollArea } from "components/ui/scroll-area";
import { EyeOffIcon, EyeIcon } from "lucide-react";
import { useState, useEffect } from "react";
import { Switch } from "components/ui/switch";
import { Button } from "components/ui/button";
import { FilterBar } from "../../Instructions/Filters";
import { Label } from "components/ui/label";
import { AppLoader } from "components/LoaderSpinner";

export function QueryResults({ results, onResultAction = null, onImageSelect = null, field, isQuerying, hasQueried, setHasQueried }) {
    const [viewerShownMap, setViewerShownMap] = useState({});
    const [selectedFileType, setSelectedFileType] = useState("All");

    const fileTypes = [
        {name: 'All', value: 'All'},
        {name: 'PDF', value: 'pdf'},
        {name: 'DOC', value: 'doc'},
        {name: 'DOCX', value: 'docx'},
        {name: 'JPG', value: 'jpg'},
        {name: 'JPEG', value: 'jpeg'},
        {name: 'PNG', value: 'png'}
    ]
    
    const toggleViewer = (index) => {
        setViewerShownMap(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleCopyResultToContext = (resultText) => {
        if (onResultAction) {
            onResultAction(resultText);
        }
    };

    const handleCopyImageUrl = (resultURL, resultName) => {
        if (onImageSelect) {
            onImageSelect(resultURL, resultName);
        }
    };
    
    const checkFileType = (results) => {
        const split = results.split(".")
        const type = split[split.length - 1]
        return type
    }

    const filteredResults = results.filter(doc => {
        const fileType = checkFileType(doc.document_name);
        const matchesFileType = selectedFileType === "All" || fileType === selectedFileType.toLowerCase();
        return matchesFileType;
    });

    useEffect(() => {
        if (!isQuerying) {
            setHasQueried(results.length > 0 || hasQueried);
        }
    }, [isQuerying, results]);

    return (
        <div className="max-h-[400px] h-fit">
            <div className="mb-4 flex flex-row w-full items-center gap-4">
                <Label className="whitespace-nowrap">File type:</Label>
                <FilterBar types={fileTypes} placeholder="Filter file types" className="w-full" 
                    selectFileType={selectedFileType}
                    onSearch={setSelectedFileType}
                />
            </div>
            <ScrollArea className="relative max-h-[350px] h-fit">
                <div className="h-fit pr-4">
                    {isQuerying ? (
                        <AppLoader size={50}/>
                    ) : filteredResults.length > 0 ? (
                        filteredResults.map((doc, index) => (
                            <Card key={index} className="w-full mb-4 bg-gray-100">
                                <CardHeader className="py-6 px-6">
                                    <div className="grid grid-cols-2 gap-2 text-xs">
                                        <div className="col-span-2">
                                            <span className="font-semibold">File Key: </span>
                                            <span className="text-gray-600 break-words">{doc.file_key}</span>
                                        </div>
                                        <div>
                                            <span className="font-semibold">Page Number: </span>
                                            <span>{doc.page_number || "-"}</span>
                                        </div>
                                        <div>
                                            <span className="font-semibold">Sheet Name: </span>
                                            <span>{doc.sheet_name || "-"}</span>
                                        </div>
                                        <div className="col-span-2">
                                            <span className="font-semibold">URL: </span>
                                            <a href={doc.presigned_url} target="_blank" className="hover:underline text-blue-400">{doc.document_name}</a>
                                        </div>
                                        <div>
                                            <span className="font-semibold">Score: </span>
                                            <span>{doc.distance || doc.score}</span>
                                        </div>
                                        <div className="col-span-2">
                                            <span className="font-semibold">File type: </span>
                                            <span>{checkFileType(doc.document_name)}</span>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardContent className="space-y-2 py-2 px-6">
                                    <div className="flex flex-col gap-4">
                                        <div className="flex gap-4">
                                            {onResultAction && 
                                                <Button onClick={() => handleCopyResultToContext(doc.text.trim())}>
                                                    Copy To {field == "inputData" ? "Input Data" : "Context"}
                                                </Button>}
                                        </div>
                                        <h3 className="font-semibold mb-2 text-sm">Text:</h3>
                                        <ScrollArea className="max-h-[150px] w-full rounded-md p-0 border bg-white">
                                            <p className="text-xs whitespace-pre-wrap p-2 border-none">{doc.text.trim()}</p>
                                        </ScrollArea>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center space-x-2">
                                            <Switch
                                                checked={viewerShownMap[index] || false}
                                                onCheckedChange={() => toggleViewer(index)}
                                            />
                                            {viewerShownMap[index] ? (
                                                <EyeIcon className="h-4 w-4 text-gray-500" />
                                            ) : (
                                                <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                            )}
                                        </div>
                                        <div className="">
                                            {viewerShownMap[index] && doc.presigned_url && (
                                                <>
                                                    {doc.document_type === "application/pdf" && (
                                                    <iframe src={doc.presigned_url} frameborder="0" className="w-full h-[400px]"></iframe>
                                                    )}
                                                    {doc.document_type.startsWith("image") && (
                                                    <img src={doc.presigned_url} className="w-full h-fit"/>
                                                    )}
                                                    {doc.document_type != "application/pdf" && !doc.document_type.startsWith("image") && (
                                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(doc.presigned_url)}`} frameborder="0" className="w-full h-[400px]"></iframe>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))
                    ) : hasQueried ? (
                        <p>No results found.</p>
                    ) : null }
                </div>
            </ScrollArea>
        </div>
    );
}
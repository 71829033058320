import { CodeSandboxData } from "../initialData";

export const CodeSandboxReducer = (state, action) => {
    switch(action.type) {
        case "SET_OPCODES":
            return {
                ...state,
                codeSandboxOpcodes: action.value,
            };
        case "ADD_MESSAGE":
            return {
                ...state,
                codeSandboxMessages: [
                    ...state.codeSandboxMessages,
                    action.value,
                ],
            };
        case "RESET_MESSAGES":
            return { 
                ...state, 
                codeSandboxMessages: CodeSandboxData.codeSandboxMessages
            };
        default:
            return state;
    }
}
import React from "react"
import { cn } from "lib/utils"

export function TextSkeleton({ children, width = 20, loading, className }) {

    return (
        <div className={cn("", className)}>
            {loading ? (
                <div className="animate-pulse space-y-2">
                    <div
                    className="h-4 bg-muted rounded"
                    style={{ width: `${width}px` }}
                    />
                </div>
            ) : (
                children
            )}
        </div>
    )
}


import { fileManagerService } from "api/services/BEX/fileManager.service";

export const buildFileSystem = (paths) => {
    const fileSystem = [];
    let idCounter = 1;

    // Helper function to find or create a folder
    const findOrCreateFolder = (parentChildren, folderName, bucketName) => {
        const folder = parentChildren.find(child => child.name === folderName && child.type === "folder");
        if (folder) return folder;

        const newFolder = { id: String(idCounter++), name: folderName || '-', bucket_name: bucketName, type: "folder", children: [] };
        parentChildren.push(newFolder);
        sortChildren(parentChildren); // Sort after adding a new folder
        return newFolder;
    };

    // Helper function to sort children
    const sortChildren = (children) => {
        children.sort((a, b) => {
            if (a.type === "folder" && b.type === "file") return -1;
            if (a.type === "file" && b.type === "folder") return 1;
            return a.name.localeCompare(b.name);
        });
    };

    // Process each path
    paths.forEach(({ path, bucket_name, presigned_url, status, reason, l2_status, l2_summary }) => {
        const parts = path.split('/');
        let currentChildren = fileSystem;
        
        let prefix = parts.slice(1, -1).join("/")

        parts.forEach((part, index) => {
            if (index === parts.length - 1) {
                // It's a file, add with bucket_name
                if (part) {
                    // console.log(prefix)
                    currentChildren.push({
                        id: String(idCounter++),
                        name: part,
                        bucket_name,
                        type: "file",
                        presigned_url,
                        prefix: prefix || "",
                        status,
                        reason,
                        l2_status,
                        l2_summary
                    });
                    sortChildren(currentChildren); // Sort after adding a new file
                }
            } else {
                // It's a folder, find or create it
                const folder = findOrCreateFolder(currentChildren, part, bucket_name);
                currentChildren = folder.children;
            }
        });
    });
    return fileSystem;
}


export const getFileSystem = async (collections, oragID, subOragID, collectionID) => {
    try {
        let paths = [];
        let continuation_token = null;
        const collection = collections.find(item => item._id === collectionID);
        const bucket_name = `coll-${subOragID}-${collectionID}`;
        let hasMoreFiles = true;

        // Loop to keep fetching files until no continuation token is returned
        while (hasMoreFiles) {
            const response = await fileManagerService.listFilesWithPresignedUrl(oragID, bucket_name);

            // Process response and append documents to paths
            response.data.documents.forEach((doc) => {
                if (!doc.key.endsWith("/")) {
                    paths.push({
                        path: `${collection.name}/${doc.key}`,
                        bucket_name,
                        presigned_url: doc.presigned_url,
                        status: doc?.metadata?.status || 'New',
                        reason: doc?.metadata?.reason || '',
                        l2_status: doc?.metadata?.l2_status || 'New',
                        l2_summary: doc?.metadata?.l2_summary || ''
                    });
                }
            });

            // If the collection has no documents, add an empty folder
            if (response.data.documents.length === 0) {
                paths.push({ path: `${collection.name}/`, bucket_name });
            }

            // Update continuation token (if present) to fetch more files
            continuation_token = response.data.next_continuation_token || null;

            // If no continuation token is returned, it means all files are loaded
            hasMoreFiles = continuation_token !== null;
        }

        // Build the file system using the paths and bucket names
        const files = buildFileSystem(paths);
        // console.log("FILE SYSTEM:", files)
        return files
    } catch (e) {
        throw new Error(e)
    }
};
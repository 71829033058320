/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react'
import { Button } from "components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "components/ui/card"
import { secretKeysService } from 'api'
import { useParams } from 'react-router-dom'
import { AppLoader } from 'components/LoaderSpinner'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Context } from 'context/GlobalState'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import { OrganizationContext } from 'context/OrganizationContext'
import { SecretInputRender } from './RedisKys'
import { Label } from 'components/ui/label'

export default function MilvusConfig() {
    
    const [secretKeys, setSecretKeys] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const { oragID } = useParams()

    useEffect(() => {
        secretKeysService.listMilvusSecretKeys(oragID)
        .then((res) => {
            setSecretKeys({keys: res.data.milvusKeys})
        }).catch(error => {
            console.log('error: ', error);
            setError(error?.response?.message || 'Something went wrong')
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    if (error) {
        return <h1> Error: {error} </h1>
    }

    return (
        <OrganizationKeyCard keyName='Milvus' esKeys={secretKeys.esKeys} keys={secretKeys.keys} vectorStoreType={secretKeys.vectorStoreType} setSecretKeys={setSecretKeys} />
    )
}


const OrganizationKeyCard = ({ keys }) => {

    const DB_STORE_TYPES = ['dedicated', 'custom']
    const milvusObj = {
        'milvusApiKey': '',
        'milvusCloudRegion': '',
        'milvusClusterId': '',
        'milvusPublicEndpoint': '',
        'keyType': 'custom',
        'isMilvusActivated': false
    }

    const [btnLoading, setBtnLoading] = useState(false)
    const [milvusKeys, setMilvusKeys] = useState(milvusObj)

    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    const { oragID } = useParams()

    const resetValues = () => {
        if (selectedOrganization.isMilvusActivated) {
            setMilvusKeys(keys)
        }
        
    }

    useEffect(() => {
        resetValues()
    }, [])


    const handleChangeMilvusKeys = () => {
        if (selectedOrganization.userRole !== 'superAdmin') {
            return addNewNotifcation('Only super admin can update milvus keys', 'warning')
        }

        const obj = {
            milvusKeys,
        }
        // make sure to remove activate keys
        delete obj['milvusKeys']['isMilvusActivated']

        const checkEmptyKeys = Object.values(obj.milvusKeys).some(value => !value);
        if (milvusKeys.keyType === 'custom' && (checkEmptyKeys)) {
            return addNewNotifcation('Please fill in all keys', 'warning')
        }

        setBtnLoading(true)

        const secretObj = {
            organizationId: oragID,
            secretKeys: obj
        }

        secretKeysService.updateMilvusSecretKeys(secretObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Milvus keys updated successfully', 'success')

        }).catch((error) => {
            console.log('error: ' ,error);
            if (error?.response?.status === 403) {
                addNewNotifcation(error?.response?.data || 'This organization does not have any valid subscription', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    const handleSelectChange = (name, value) => {
        if (value === keys.keyType) {
            resetValues()
        } else if (value === 'dedicated' && keys.hasDedicatedMilvus) {
            setMilvusKeys({
                [name]: value,
                ...keys.dedicatedMilvusKeys,
            })
        } else {
            setMilvusKeys({
                ...milvusObj,
                [name]: value,
            })
        }
    }

    
    const handleMilvusInputChange = (e) => {
        const { name, value } = e.target
        setMilvusKeys((prev) => ({ ...prev, [name]: value }))
    }

    const isSuperAdmin = selectedOrganization.userRole === 'superAdmin'

    return (
        <Card>
            <CardHeader>
                <CardTitle>Milvus Keys</CardTitle>
                <CardDescription>Manage your organization's configuration keys</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">

                <div className="space-y-3">
                    <div className="space-y-2">
                        <Label htmlFor={'db-store-type'}> Milvus Store Type </Label>

                        <Select disabled={!isSuperAdmin} onValueChange={(value) => handleSelectChange('keyType', value)} id="db-store-type" value={milvusKeys.keyType}>
                            <SelectTrigger className="w-full capitalize">
                                <SelectValue className="capitalize" placeholder="Select redis database type" />
                            </SelectTrigger>
                            <SelectContent>
                                {DB_STORE_TYPES.map(item => (
                                    <SelectItem className='capitalize' key={item} value={item}>{item} 
                                        {item === 'dedicated' && <small className="text-muted-foreground"> subscription required</small>} 
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    {milvusKeys.keyType === "dedicated" && !keys.hasDedicatedMilvus && (
                        <div className="w-full mt-2 p-2 rounded border flex flex-col gap-1">
                            <h2 className="font-bold text-[16px]">Automatic Key Generation</h2>
                            <span className="text-muted-foreground text-sm">
                                We'll create a new dedicated Milvus database for you and provide the connection details automatically.
                            </span>
                        </div>
                    )}
                </div>

                {/* { Milvus Keys} */}
                <div className="space-y-4">
                    <h3 className="text-lg font-semibold">Milvus Configuration</h3>
                    <div className="space-y-2">
                        <SecretInputRender  secretKey='Milvus api key' disabled={!isSuperAdmin || milvusKeys.keyType === 'dedicated'} onChange={handleMilvusInputChange} label='milvusApiKey' customValue={milvusKeys.milvusApiKey} />
                    </div>
                    <div className="space-y-2">
                        <SecretInputRender secretKey='Milvus cloud region' disabled={!isSuperAdmin || milvusKeys.keyType === 'dedicated'} onChange={handleMilvusInputChange} label='milvusCloudRegion' customValue={milvusKeys.milvusCloudRegion} />
                    </div>
                    <div className="space-y-2">
                        <SecretInputRender secretKey='Milvus cluster id' disabled={!isSuperAdmin || milvusKeys.keyType === 'dedicated'} onChange={handleMilvusInputChange} label='milvusClusterId' customValue={milvusKeys.milvusClusterId} />
                    </div>
                    <div className="space-y-2">
                        <SecretInputRender secretKey='Milvus public endpoint' disabled={!isSuperAdmin || milvusKeys.keyType === 'dedicated'} onChange={handleMilvusInputChange} label='milvusPublicEndpoint' customValue={milvusKeys.milvusPublicEndpoint} />
                    </div>
                </div>

            </CardContent>

            <CardFooter>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button disabled={btnLoading} onClick={handleChangeMilvusKeys} className="w-full">
                                {/* Save Configuration */}
                                {milvusKeys.keyType === "dedicated" ? 'Generate Keys' : 'Save Configuration'}
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-gray-900 text-white">
                            {!isSuperAdmin && (
                                'Only super admin can update milvus keys'
                            )}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </CardFooter>
        </Card>
    )
}

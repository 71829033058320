/* eslint-disable max-lines */
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'components/ui/dialog'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollArea } from "components/ui/scroll-area";
import { Button } from 'components/ui/button';
import { Context } from 'context/GlobalState';
import { Bot, Building2, ChevronDown, ListChecks, ListEnd, MapPinned, ServerCog } from 'lucide-react';
import { Badge } from 'components/ui/badge';
import { FilterBar, SearchBar } from '../Instructions/Filters';
import { modelService } from 'api/services/BEX/models.services';
import { Card, CardDescription, CardTitle, CardHeader, CardContent } from 'components/ui/card';
import { Label } from 'components/ui/label';
import { OrganizationContext } from 'context/OrganizationContext';

const CommonModelSelectionModal = ({ onModelSelect, finalModel }) => {
    const [ownedBy, setOwnedBy] = useState("");
    const [provider, setProvider] = useState(null);
    const [contextWindow, setContextWindow] = useState(null);
    const [description, setDescription] = useState("");
    const [region, setRegion] = useState("");
    const [loading, setLoading] = useState(true)
    const [commonModels, setCommonModels] = useState([])
    const [recommendedTasks, setRecommendedTasks] = useState([]);
    const [selectedModel, setSelectedModel] = useState(finalModel || null);
    const [selectType, setSelectType] = useState("All");
    const [searchTerm, setSearchTerm] = useState("");
    const [filterCount, setFilterCount] = useState(0);
    const [filteredModels, setFilteredModels] = useState([]);
    const [availableModelTypes, setAvailableModelTypes] = useState([]); 

    const {addNewNotifcation} = useContext(Context)
    const {selectedOrganization} = useContext(OrganizationContext);

    useEffect(() => {
        loadModels()
        if(finalModel?.modelName){
            setSelectedModel(finalModel)
        }
    
    }, [finalModel.modelName])

    useEffect(() => {
        const models = commonModels.filter((model) => {
            const searchTermLower = searchTerm.toLowerCase();
            const matchesSearchTerm =
              model.model_id.toLowerCase().includes(searchTermLower) ||
              model.owned_by.toLowerCase().includes(searchTermLower);
            const matchesType = selectType === 'All' || model.provider_type === selectType;
            return matchesSearchTerm && matchesType;
        });
        setFilterCount(models?.length || 0);
        setFilteredModels(models);
    }, [searchTerm, selectType, commonModels]);

    const loadModels = async () => {
        try {
            const body = {
                provider: selectedOrganization.providers || [],
                filter: {
                    "Watson": {
                        "region": "All",
                        "showInactive": false 
                    },
                    "Groq": {
                        "showInactive": false
                    }
                }
            }
            const res = await modelService.getModels(body);
            const models = res.data;

            models.sort((a, b) => {
                let providerComparison = a.provider_type.localeCompare(b.provider_type);
                return providerComparison !== 0 ? providerComparison : a.model_id.localeCompare(b.model_id);
            });
            setCommonModels(models);
            const uniqueModelTypes = [...new Set(models.map(model => model.provider_type))];
            setAvailableModelTypes(uniqueModelTypes);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const filteredInstructionTypes = [
        { id: 1, name: 'All', value: 'All' },
        ...availableModelTypes.map((type, index) => ({
            id: index + 2,
            name: type === 'IBM' ? 'Watson' : type,
            value: type,
        })),
    ];

    const handleModelSelect = (model) => {
        if (selectedModel?.modelName === model.model_id && selectedModel?.modelRegion === model.region) {
            resetModelDetails();
        } else {
            setSelectedModel({modelName: model.model_id, modelType: model.provider_type, modelRegion: model.region || ""});
            setOwnedBy(model?.owned_by || "");
            setContextWindow(model?.context_window || 0);
            setRegion(model?.region || "Not Applicable");
            setProvider(model?.provider_type || "");
            setRecommendedTasks(model?.tasks || []);  
            setDescription(model?.description || "No description available."); 
        }
    };

    const resetModelDetails = () => {
        setSelectedModel(null);
        setDescription("");
        setRegion("");
        setRecommendedTasks([]);
    };

    const handleDone = () => {
        if (!selectedModel?.modelName) {
            addNewNotifcation("Please select a model first.", "warning");
            return;
        }
        onModelSelect(selectedModel);
    };

        // Handle search term change
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    // Handle search term change
    const handleType = (value) => {
        setSelectType(value);
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button disabled={loading} variant="outline" className="w-full">
                    <ChevronDown className="mr-2 h-4 w-4" />
                    { loading ? 
                        "Loading models..."
                    :
                    finalModel?.modelName ? (
                        <span> {finalModel?.modelName} {" - "} <small className='text-muted-foreground'> ({finalModel.modelType}) </small> </span>
                        ) : "Select a model"
                    }
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>Select a Model</DialogTitle>
                    <DialogDescription>
                        Choose a model and see its details.
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex flex-col md:flex-row gap-4">
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="mb-6 flex flex-col sm:flex-row gap-4 items-center p-4">
                                <div className="flex-grow">
                                    <SearchBar onSearch={handleSearch} />
                                </div>
                                <FilterBar
                                    onSearch={handleType}
                                    selectModelType={selectType}
                                    types={filteredInstructionTypes} 
                                    placeholder="Filter model type"
                                />
                            </div>
                            <div className="px-4">
                                <span className='text-muted-foreground'>
                                    {filterCount} models found
                                </span>
                            </div>
                            <div className="grid grid-cols-3 grid-rows-5 gap-4 p-4">
                                {filteredModels.length > 0 ? (
                                    filteredModels.map((model) => (
                                        <Button
                                            key={model.id}
                                            variant={
                                                selectedModel?.modelName === model.model_id &&
                                                (!model.region || selectedModel?.modelRegion === model.region)
                                                    ? "default"
                                                    : "outline"
                                            }
                                            onClick={() => handleModelSelect(model)}
                                            className="justify-start h-auto text-left"
                                        >
                                            <div className="w-full space-y-1">
                                                <div className='flex w-full justify-between gap-2 items-center'>
                                                    <div className="font-semibold w-full truncate">{model.model_id || `N/A`}</div>
                                                    <Badge className="px-1 rounded text-green-50">
                                                        {model.provider_type || `N/A`}
                                                    </Badge>
                                                </div>
                                                <div className='flex w-full justify-between gap-2 items-center'>
                                                    <div className="text-xs text-muted-foreground">{model.owned_by || `N/A`}</div>
                                                    <div className="text-xs text-muted-foreground">{model.region || ''}</div>
                                                </div>
                                            </div>
                                        </Button>
                                    ))
                                ) : (
                                    <p>No models available</p>
                                )}
                            </div>
                        </ScrollArea>
                        {selectedModel?.modelName && (
                            <Card className="md:w-1/3 h-96 max-h-96 overflow-y-auto my-4 min-w-[300px]">
                                <CardHeader>
                                    <div className="flex items-start justify-between">
                                        <div className="space-y-1">
                                            <CardTitle className="text-xl whitespace-pre-wrap break-all">{selectedModel?.modelName || ''}</CardTitle>
                                            <CardDescription className="text-xs">Foundation Model</CardDescription>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardContent className="space-y-2.5">
                                    <div className="space-y-2">
                                        <div className="flex items-center gap-2 text-xs">
                                            <Building2 className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Owned By:</Label>
                                            <span className="text-muted-foreground">{ownedBy || '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs">
                                            <ListEnd className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Context Window:</Label>
                                            <span className="text-muted-foreground">{contextWindow} tokens</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs">
                                            <MapPinned className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Region:</Label>
                                            <span className="text-muted-foreground">{region || "Not Applicable"}</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs">
                                            <ServerCog className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Provider:</Label>
                                            <span className="text-muted-foreground">{provider || "Not Applicable"}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="space-y-2">
                                        <div className="">
                                            <Label className="text-xs font-medium">Description</Label>
                                            <p className="text-xs text-muted-foreground">{description || "No description available."}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="space-y-2">
                                        <div className="flex items-center gap-2">
                                            <Bot className="h-4 w-4" />
                                            <Label className="text-xs font-medium">Model ID</Label>
                                        </div>
                                        <code className="rounded bg-muted px-2 py-1 text-xs whitespace-pre-wrap break-all">{selectedModel?.modelName || '-'}</code>
                                    </div>
                                    <hr />
                                    <div className="space-y-2">
                                        <div className="flex items-center gap-2">
                                            <ListChecks className="h-4 w-4" />
                                            <Label className="text-xs font-medium">Tasks</Label>
                                        </div>
                                        <div className="flex flex-wrap gap-2">
                                            {recommendedTasks && recommendedTasks.length > 0 ? (
                                                recommendedTasks.map((task, index) => (
                                                    <div key={index} className="flex items-center gap-2">
                                                        <Badge className="bg-muted hover:bg-muted px-1 rounded text-auto">{task}</Badge>
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="text-xs text-muted-foreground">No information about tasks.</p>
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                    <div className="flex justify-end space-x-2"> 
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        {selectedModel?.modelName && (
                            <DialogClose className="flex justify-end">
                                <Button
                                    disabled={
                                        finalModel?.modelName === selectedModel?.modelName &&
                                        finalModel?.modelRegion === selectedModel?.modelRegion
                                    }
                                    variant="default"
                                    onClick={handleDone}
                                >
                                    Select
                                </Button>
                            </DialogClose>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CommonModelSelectionModal
import React, { Children } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import CopyButton from 'components/CopyButton';
import '../../aiLab.css';

export const AssistantMessage = ({ content, onReferenceClick, canClickRef = false }) => {
    const handleCitationClick = (name, page) => {
        onReferenceClick(name, page);
    };

    const transformToReactNode = (node) => {
        if (!node) return null;
        
        if (Array.isArray(node)) {
            return node.map((child, index) => transformToReactNode(child));
        }
    
        if (typeof node === 'string') {
            return node;
        }
    
        if (node.type === 'text') {
            return node.value;
        }
    
        if (node.type === 'element') {
            const { tagName, properties, children } = node;
            return React.createElement(
                tagName,
                { ...properties, key: properties.key || Math.random() },
                children && transformToReactNode(children)
            );
        }
    
        return null;
    };

    const findPattern = (node) => {
        if (!node) return node;
        
        // Process current node's children
        const children = node?.children || [];
        const type = node?.type || undefined;
        
        // Recursively process all children first
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.children) {
                children[i] = findPattern(child);
            }
        }
    
        // Then process the current node
        if (type !== "element" || children.length < 1) return node;
    
        let currentIndex = 0;
        while (currentIndex < children.length) {
            const startIndex = children.findIndex((child, idx) => 
                idx >= currentIndex && 
                child.type === "text" && 
                child.value === "<docName>"
            );
            
            if (startIndex === -1) break;
    
            const endIndex = children.findIndex((child, idx) => 
                idx >= startIndex && 
                child.type === "text" && 
                child.value === "</page>"
            );
            
            if (endIndex === -1) break;
    
            const relevantText = children
                .slice(startIndex, endIndex + 1)
                .filter(child => child.type === "text")
                .map(child => child.value)
                .join("");
    
            const match = /<docName>(.*?)<\/docName><page>(.*?)<\/page>/g.exec(relevantText);
            if (match) {
                let [_, name, page] = match;

                // Remove "Document " followed by any digits and dash
                name = name.replace(/^Document \d+ - /, '');
                
                // Remove the original citation text nodes
                children.splice(startIndex, endIndex - startIndex + 1);
                
                // Insert the Button node
                children.splice(startIndex, 0, {
                    type: "element",
                    tagName: "label",
                    properties: {
                        className: "bg-gray-300 text-black rounded-full px-2 py-0 h-fit text-xs hover:cursor-pointer",
                        onClick: () => handleCitationClick(name, page)
                    },
                    children: [{
                        type: "text",
                        value: `${name}, Page ${page}`
                    }]
                });
    
                currentIndex = startIndex + 1;
            } else {
                currentIndex = startIndex + 1;
            }
        }
    
        return node;
    };


    return (
        <div className="flex mb-4 mt-4 gap-2 items-start min-h-full w-2/3">
            <div className="bg-gray-100 rounded-md text-gray-900 p-3 min-h-full w-full">
                <div className="text-xs break-words whitespace-pre-wrap min-h-full">
                    <Markdown
                        className="ai-lab-markdown"
                        remarkPlugins={[remarkGfm]}
                        components={{
                            code: ({ node, inline, className, children, ...props }) => {
                                // ... (Your existing code rendering logic for code blocks)
                                const match = /language-(\w+)/.exec(className || '');
                                const code = String(children).replace(/\n$/, '');

                                inline = !className && !/\n/.test(code);
                                if (inline) {
                                    return (
                                        <code
                                            className="bg-gray-200 text-gray-800 rounded-[4px] px-1 text-xs font-mono"
                                            style={{ whiteSpace: 'pre-wrap' }}
                                        >
                                            {children}
                                        </code>
                                    );
                                }

                                if (!inline && match) {
                                    return (
                                        <div className="relative rounded-[12px]">
                                            <span className="absolute top-2 left-2 bg-gray-600 text-white text-xs px-2 py-1 rounded select-none">
                                                {match[1]}
                                            </span>

                                            <CopyButton code={code} className="text-gray-200" />
                                            <SyntaxHighlighter
                                                style={darcula}
                                                language={match[1]}
                                                PreTag="div"
                                                {...props}
                                                customStyle={{ paddingTop: '2.5rem' }}
                                            >
                                                {code}
                                            </SyntaxHighlighter>
                                        </div>
                                    );
                                }

                                return (
                                    <div
                                        className="bg-[#2c2c2c] rounded-md p-2 pt-[1.5rem] text-xs text-gray-200 font-mono whitespace-pre-wrap relative"
                                        style={{ overflowX: 'auto' }}
                                    >
                                        <CopyButton code={code} className="text-gray-200" />
                                        {children}
                                    </div>
                                );
                            },
                            p: ({ node, inline, className, children, ...props }) => {
                                const processedNode = findPattern(node);
                                const reactNode = transformToReactNode(processedNode);
                                return <p {...props}>{reactNode}</p>;
                            },
                            li: ({ node, inline, className, children, ...props }) => {
                                const processedNode = findPattern(node);
                                const reactNode = transformToReactNode(processedNode);
                                return <li {...props}>{reactNode}</li>;
                            }
                        }}
                    >
                        {content}
                    </Markdown>
                </div>
            </div>
        </div>
    );
};

export const UserMessage = ({ content }) => (
    <div className="flex mb-4 mt-4 gap-2 items-start justify-end">
        <div className="bg-black text-gray-100 rounded-lg p-3 max-w-[66.67%]">
            <p className="text-xs break-words whitespace-pre-wrap">{content}</p>
        </div>
    </div>
);

import { bexHttpClient } from '../../clients';


/**
 * Service class for performing project operations.
 */
class OrganizationAdminAccessService {

    /**
     * Fetches organization by id.
     * @returns {Promise<Object>} The response from the server returns organization object.
     */
    async getOrganizations() {
        const response = await bexHttpClient.get(`/admin/get-organizations`)
        return response;
    }

    /**
     * Check organization cluster status.
     * @returns {Promise<Object>} The response from the server returns organization object status.
     */
    async checkOrganizationCluster(organizationId) {
        const response = await bexHttpClient.get(`/admin/check-organization-cluster/${organizationId}`)
        return response;
    }

    /**
     * delete organization cluster.
     * @returns {Promise<Object>} The response from the server returns delete cluster response.
     */
    async deleteOrganizationCluster(organizationId) {
        const response = await bexHttpClient.delete(`/admin/delete-organization-cluster/${organizationId}`)
        return response;
    }

    /**
     * delete organization.
     * @returns {Promise<Object>} The response from the server returns delete organization response.
     */
    async deleteOrganization(organizationId) {
        const response = await bexHttpClient.delete(`/admin/delete-organization/${organizationId}`)
        return response;
    }

    /**
     * update organization model providers.
     * @returns {Promise<Object>} The response from the server returns updated organization response.
     */
    async updateOrganizationModelProviders(data) {
        const response = await bexHttpClient.put(`/admin/update-organization-providers`, data)
        return response;
    }

    /**
     * get organization backup data.
     * @returns {Promise<Object>} The response from the server returns backup organization response.
     */
    async getSubOrganizationBackupData(organizationId, subOrganizationId, entity) {
        const response = await bexHttpClient.get(`/admin/get-backup-data?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}&entity=${entity}`) 
        return response;
    }

}

const organizationAdminAccessService = new OrganizationAdminAccessService()
export { organizationAdminAccessService }

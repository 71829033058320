class FileTypeChecker {
    static isPDF(fileNameOrUrl) {
        return fileNameOrUrl.toLowerCase().endsWith(".pdf");
    }

    static isPPT(filename) {
        const pptExtensions = ['.ppt', '.pptx'];
        return pptExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
    }

    static isWORD(filename) {
        const wordExtensions = ['.doc', '.docx'];
        return wordExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
    }

    static isSPREADSHEET(filename) {
        const spreadsheetExtensions = ['.xls', '.xlsx', '.csv'];
        return spreadsheetExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
    }

    static isIMAGE(filename) {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', 'webp'];
        return imageExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
    }

    static isTEXT(filename) {
        const textExtensions = ['.txt'];
        return textExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
    }

    static isOFFICEFILE(filename) {
        return this.isPPT(filename) || this.isWORD(filename) || this.isSPREADSHEET(filename) || this.isTEXT(filename);
    }
}

class AiTeacherHelper {

    static findFile(name, files) {
        for (const item of files) {
            if (item && typeof item === 'object') {
                if (item.type === "file" && item.name === name) {
                    return item;
                } else if (Array.isArray(item.children)) {
                    // Recursively search in children
                    const result = AiTeacherHelper.findFile(name, item.children); // Properly calling the static method
                    if (result) return result;
                }
            }
        }
        return null;
    }

    static compileRAGResults(results) {
        if (typeof results !== 'object' || results === null || Array.isArray(results)) {
            throw new Error("Expected 'results' to be an object.");
        }
    
        const compiledTextList = [];
        const allFields = [];  // Collect all fields from the RAG results
        const docResults = results.results || [];
    
        docResults.forEach((result, index) => {
            // Fetch and sanitize the text content
            const documentText = (result.text || '').trim();
            const documentName = result.document_name || 'Unknown Document';
            const pageNumber = result.page_number || null;
            const relevanceScore = result.relevance_score || null;
    
            // Add all fields for this document
            allFields.push(result);
    
            // Compile text for compatibility with existing workflows
            if (documentText) {
                compiledTextList.push(`Document ${index + 1} - ${documentName} (Page ${pageNumber}):\n${documentText}\n\n`);
            }
        });
    
        return {
            compiled_text: compiledTextList.join(''),  // Concatenated text for backward compatibility
            all_fields: allFields  // List of all RAG results with full details
        };
    }

    static appendRAGandMSG(result, userMessage){
        return `${result.trim()}\n\nStudent Query: ${userMessage}`;
    }
}

module.exports = {
    FileTypeChecker,
    AiTeacherHelper
};
import React, { useEffect, useState } from 'react'
import StepNonLLMAPI from './StepNonLLMAPI'
import { opCodeService } from 'api/services/PROJECT-O/OpCodeBuilder.service';

const StepNonLLM = ({stepIndex, opCodeStep, opCodeSteps, setOpCodeSteps, nested}) => {
    const [functionRegistry, setFunctionRegistry] = useState({});
    const [apis, setApis] = useState([]);

    useEffect(() => {
        // Fetch the list of functions and their properties from FUNCTION_REGISTRY
        const fetchFunctions = async () => {
            try {
                const res = await opCodeService.getFunctionRegistry();
                if (res.data && typeof res.data === 'object') {
                    setFunctionRegistry(res.data);
                    setApis(Object.keys(res.data).filter(key => key === "RAG_API"));
                }
            } catch (err) {
                console.error("Error fetching functions:", err);
            }
        };

        fetchFunctions();
    }, []);

    return (
        <div className="flex flex-col gap-2 w-full">
            <StepNonLLMAPI 
            opCodeStep={opCodeStep} 
            stepIndex={stepIndex} 
            opCodeSteps={opCodeSteps} 
            setOpCodeSteps={setOpCodeSteps}
            functionRegistry={functionRegistry}
            apis={apis}
            nested={nested}
            />
        </div>
        
    )
}

export default StepNonLLM
import { useState, useEffect, useContext, useMemo, useCallback } from "react"
import { Button } from "components/ui/button"
import { Sheet, SheetContent, SheetTrigger } from "components/ui/sheet"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "components/ui/resizable"
import { DocViewer } from "./Viewer/DocumentPanel"
import { ChatPanel } from "./Chat/ChatPanel"
import { MessageSquare, MenuIcon } from "lucide-react"
import { AiLabContext } from "pages/PromptingAndRagTraining/context/AiLabContext"
import Breadcrumb from 'components/Breadcrumb';
import { useParams } from "react-router-dom";
import { milvusService } from "api/services/PROJECT-O/Milvus.service";
import { AiTeacherHelper } from "./helper";
import { assistantWsService } from "api/services/PROJECT-O/WEBSOCKETS/Assistant.websocket.service";
import { Context } from "context/GlobalState";
import { fileManagerService } from "api/services/BEX/fileManager.service"

export default function AITeacher() {
    const { state, dispatch } = useContext(AiLabContext);
    const { addNewNotifcation } = useContext(Context);
    const { oragID, subOragID } = useParams();
    const [isMobile, setIsMobile] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const [isChatOpen, setIsChatOpen] = useState(false)

    const { aiTeacher } = state || {};
    const { aiTeacherMessages: messages,
        mode,
        ragForm,
        isAiTeacherMessageLoading: isMessageLoading,
        fileSystem
    } = aiTeacher || {};
    const { COMMON_ORG, COMMON_SUBORG, AI_TEACHER_ASST_ID: ASST_ID, aiTeacherOpcodes: modes } = aiTeacher || {};
    const [asstLoadingMessage, setAsstLoadingMessage] = useState('');

    const tabs = state?.aiTeacher?.openedDocuments || [];
    const selectedDoc = state?.aiTeacher?.selectedDocument || null;
    const setActiveTab = (file) => dispatch({type: "AI_TEACHER_SET_DOCUMENT", value: file})
    const page = state?.aiTeacher?.currentPage || 1;
    const handleSetPage = (num) => dispatch({type: "AI_TEACHER_SET_CURRENT_PAGE", value: num})


    const handleSendMessage = async (e) => {
        // e is a form event
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const userMessage = formData.get("userMessage")

        if (!userMessage.trim()) return;

        if (!ragForm?.collection_id) {
            addNewNotifcation("Please select a collection first.", "warning")
            return;
        }

        try {
            dispatch({ type: "AI_TEACHER_SET_LOADING", value: true });

            const userMsgObject = { role: "user", content: userMessage.trim() };
            dispatch({ type: "AI_TEACHER_ADD_MESSAGE", value: userMsgObject });

            setAsstLoadingMessage("Searching through the collection...");
            const RAGForm = {
                organization_id: oragID,
                sub_organization_id: subOragID,
                collection_id: ragForm?.collection_id,
                query_text: userMessage.trim(),
                top_k: 10,
                offset: 0,
                top_n: 2,
                use_rerank: true,
                filters: {},
                search_type: "hybrid"
            };

            const searchResult = await milvusService.hybridSearch(RAGForm);
            const { compiled_text } = AiTeacherHelper.compileRAGResults(searchResult?.data);

            setAsstLoadingMessage("Thinking...");
            const asstQuery = AiTeacherHelper.appendRAGandMSG(compiled_text, userMessage);

            assistantWsService.setAssistant(ASST_ID);
            const response = await assistantWsService.sendToAssistant(
                COMMON_ORG,
                COMMON_SUBORG,
                modes[mode]?.opcode,
                { real_time_data: asstQuery.trim() }
            );
            // Check if there are errors
            if (response?.result?.error){
                const assistantMsgMessage = {
                    role: "assistant",
                    content: `Something went wrong during the execution.\`\`\`\n${response?.result?.error}\`\`\``,
                };
                dispatch({ type: "AI_TEACHER_ADD_MESSAGE", value: assistantMsgMessage });
                throw Error(`Something went wrong during the execution. ${error}`);
            }

            // If no error check last step output
            const lastStepOutput = response?.result?.execution_log?.slice(-1)[0]?.Outputs ? Object.values(response.result.execution_log.slice(-1)[0].Outputs)[0] : '';
            if (!lastStepOutput){
                const assistantMsgMessage = {
                    role: "assistant",
                    content: "An error occured after the execution. No result was generated.",
                };
                dispatch({ type: "AI_TEACHER_ADD_MESSAGE", value: assistantMsgMessage });
                throw Error("The assistant does not have a result after execution.");
            }

            // update with the response
            const assistantMsgMessage = {
                role: "assistant",
                content: lastStepOutput,
            };
            dispatch({ type: "AI_TEACHER_ADD_MESSAGE", value: assistantMsgMessage });

        } catch (error) {
            console.error("Error in handleSendMessage:", error);
            addNewNotifcation(error.message || "An error occurred. Please try again.", "error");
        } finally {
            setAsstLoadingMessage("");
            dispatch({ type: "AI_TEACHER_SET_LOADING", value: false });
        }
    };

    const onReferenceClick = async(name, page) => {
        // First check if the file exists in tabs
        let file = tabs.find(tab => tab.name === name);
        
        // If not in tabs, look in fileSystem
        if (!file) {
            file = AiTeacherHelper.findFile(name, fileSystem);
            if (fileSystem.length === 0 || file === null) {
                addNewNotifcation("Please make sure you are in the same collection or the document is opened.", "warning");
                return;
            }
            // Add to tabs to get presigned URL
            file = await addTab(file);
        }
        
        // Only update selected document if it's different from current
        if (file.id !== state?.aiTeacher?.selectedDocument?.id) {
            dispatch({ type: "AI_TEACHER_SET_DOCUMENT", value: file });
        }
    
        // Only update page if it's different from current
        if (page && parseInt(page) !== aiTeacher?.currentPage) {
            dispatch({ type: "AI_TEACHER_SET_CURRENT_PAGE", value: parseInt(page) });
        }
    };

    const addTab = async (file) => {
        // console.log("ADDED to tab", file)
        dispatch({type: "AI_TEACHER_ADD_OPENED_DOCUMENT", value: file})

        // If already in tabs, do nothing
        const isFileInTabs = tabs.some(tab => tab.id === file.id);
        if (isFileInTabs) return file

        // if not added to tabs yet, get presigned url first
        try{
            const bucketId = file?.bucket_name || null;
            if (!bucketId){
                addNewNotifcation(`BucketID not found when getting presigned-url of ${file?.name}. Please try again.`, "warning");
                console.log(`Presigned URL of ${file?.name} not updated.`)
            } else {
                
                const body = {
                    organizationId: oragID,
                    bucketId: bucketId,
                    prefix: file?.prefix || null,
                    fileNames: [file?.name]
                }
                const result = await fileManagerService.getPublicUrls(body);
                const retrievedUrls = result?.data?.preSignedUrls || [];
                const fileIndex =  retrievedUrls.findIndex((url) => url?.fileName === file?.name)
                if (fileIndex >= 0){
                    file.presigned_url = retrievedUrls[fileIndex]?.url;
                }
                
                // console.log("Retrieved presigned URLs", retrievedUrls)
                console.log(`Presigned URL of ${file?.name} updated.`)
                // console.log(`Presigned URL of ${file?.name} is ${file.presigned_url}.`)
            }
        } catch (error) {
            console.error(`Error while getting presigned URL: ${error}`);
        }

        return file
    }

    const removeTab = (fileId, e) => {
        e.stopPropagation()
        // console.log("REMOVED", fileId)
        dispatch({type: "AI_TEACHER_REMOVE_OPENED_DOCUMENT", value: fileId})
        if (fileId === selectedDoc?.id){
            if (tabs.length > 1)
                dispatch({type: "AI_TEACHER_SET_DOCUMENT", value: tabs[0]})
            else
                dispatch({type: "AI_TEACHER_SET_DOCUMENT", value: null})
        }
    }

    // Memoize the layout renderers to prevent unnecessary recreations
    const renderDocViewer = useMemo(() => (
        <DocViewer
            tabs={tabs}
            activeTab={selectedDoc}
            page={page}
            handleSetPage={handleSetPage}
            setActiveTab={setActiveTab}
            removeTab={removeTab}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
        />
    ), [tabs, selectedDoc, page, handleSetPage, setActiveTab, removeTab, isExpanded, setIsExpanded])

    const renderChatPanel = useMemo(() => (
        <ChatPanel
            mode={mode}
            messages={messages}
            loading={isMessageLoading}
            loadingMessage={asstLoadingMessage}
            onReferenceClick={onReferenceClick}
            handleSendMessage={handleSendMessage}
            onFileSelect={addTab}
        />
    ), [mode, messages, isMessageLoading, asstLoadingMessage, onReferenceClick, handleSendMessage, addTab])

    // Memoize the layout components
    const mobileLayout = useMemo(() => (
        <div className="h-full flex relative">
            <div className="w-full transition-all duration-300 ease-in-out">
                <div className="h-full">
                    {renderDocViewer}
                </div>
            </div>
            <Sheet open={isChatOpen} onOpenChange={setIsChatOpen}>
                <SheetTrigger asChild>
                    <Button size="icon" className="h-12 w-12 rounded-full absolute bottom-4 right-4 shadow-lg bg-gray-800">
                        <MessageSquare className="h-5 w-5" />
                        <span className="sr-only">Open chat</span>
                    </Button>
                </SheetTrigger>
                <SheetContent side="right" className="w-[400px] sm:w-[540px] p-0">
                    {renderChatPanel}
                </SheetContent>
            </Sheet>
        </div>
    ), [isChatOpen, setIsChatOpen, renderDocViewer, renderChatPanel])

    const desktopLayout = useMemo(() => (
        <div className="h-full flex relative">
            {isExpanded ? (
                <div className="w-full transition-all duration-300 ease-in-out">
                    <div className="h-full">
                        {renderDocViewer}
                    </div>
                </div>
            ) : (
                <ResizablePanelGroup direction="horizontal" className="w-full h-full">
                    <ResizablePanel defaultSize={60} minSize={30} maxSize={70}>
                        <div className="h-full">
                            {renderDocViewer}
                        </div>
                    </ResizablePanel>
                    <ResizableHandle withHandle />
                    <ResizablePanel defaultSize={40} minSize={30} maxSize={70}>
                        {renderChatPanel}
                    </ResizablePanel>
                </ResizablePanelGroup>
            )}

            {isExpanded && (
                <Sheet open={isChatOpen} onOpenChange={setIsChatOpen}>
                    <SheetTrigger asChild>
                        <Button size="icon" className="h-12 w-12 rounded-full absolute bottom-4 right-4 shadow-lg bg-gray-800">
                            <MessageSquare className="h-5 w-5" />
                            <span className="sr-only">Open chat</span>
                        </Button>
                    </SheetTrigger>
                    <SheetContent side="right" className="w-[400px] sm:w-[540px] p-0">
                        {renderChatPanel}
                    </SheetContent>
                </Sheet>
            )}
        </div>
    ), [isExpanded, isChatOpen, setIsChatOpen, renderDocViewer, renderChatPanel])

    // Use debounced resize handler
    useEffect(() => {
        let timeoutId;
        const checkScreenSize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setIsMobile(window.innerWidth < 768);
            }, 250); // Debounce time of 250ms
        };

        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);

        return () => {
            window.removeEventListener("resize", checkScreenSize);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className="h-full w-full flex flex-col bg-white">
            <header className="h-14 border-b flex items-center justify-between px-4 shrink-0">
                <div className="flex items-center gap-3">
                    <Breadcrumb maxPaths={2} />
                </div>
            </header>

            <div className="flex-1 relative">
                {isMobile ? mobileLayout : desktopLayout}
            </div>
        </div>
    );
}
/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect } from 'react'
import StepLLM from './OpCodeBuilderSteps/StepLLM/StepLLM';
import { OpCodeBuilderContext } from './OpCodeBuilderHAndC/OpCodeBuilderContext';
import StepNonLLM from './OpCodeBuilderSteps/StepNonLLM/StepNonLLM';
import StepCondition from './OpCodeBuilderSteps/StepCondition/StepCondition';
import StepLoop from './OpCodeBuilderSteps/StepLoop/StepLoop';
import { Badge } from 'components/ui/badge';
import handler from './OpCodeBuilderHAndC/OpCodeBuilderHandler';
import StepMemory from './OpCodeBuilderSteps/StepMemory/StepMemory';

const OpCodeBuilderStep = ({stepIndex, opCodeStep, opCodeSteps, setOpCodeSteps, nested = false}) => {
    const { actions, globalContext, removedContext } = useContext(OpCodeBuilderContext)
    const isNotSkipped = handler.checkStepDependency(opCodeStep.step_id, opCodeSteps, nested)
    const stepComponents = {
        "LLM": StepLLM,
        "LLM-Stream": StepLLM,
        "Identification": StepLLM,
        "Non-LLM": StepNonLLM,
        "Non-LLM - BeX Insights": StepNonLLM,
        "Condition": StepCondition,
        "Loop": StepLoop,
        "Memory": StepMemory
    };

    const StepComponent = stepComponents[opCodeStep?.step_type] || null;

    useEffect(() => {
        const otherContexts = getContextFromOtherSteps()
        const contexts = new Set([...globalContext, ...otherContexts])
        // console.log("Contexts from other steps:", otherContexts)

        actions({type:"SET_GLOBAL_CONTEXT", payload:Array.from(contexts)})
    }, [])

    useEffect(() => {
        if(removedContext){
            handler.removeSpecificContextFromOtherSteps(setOpCodeSteps, opCodeSteps, removedContext)
            actions({type:"SET_REMOVED_CONTEXT", payload:""})
        }
        
    }, [globalContext])
    
    const getContextFromOtherSteps = useCallback(() => {
            const contexts = new Set()
            opCodeSteps?.forEach((opCoStep) => {
                const contextRegex = /{{context\['(.*?)'\]}}/g
              
                let match
                while ((match = contextRegex.exec(opCoStep.step_type === "Non-LLM" ? opCoStep?.input?.query_text || "" : opCoStep?.input || "")) !== null) {
                    if (match[1] !== "real_time_data") {
                    contexts.add(match[1])
                    }
                }
            })
            return Array.from(contexts)
    }, [opCodeSteps, stepIndex, opCodeStep])

    // console.log(opCodeSteps)
        
    return (
        <div className={`space-y-2 bg-gray-50 border shadow rounded p-2 ${isNotSkipped? "" : "bg-gray-200/70"}`}>
            <div className="flex flex-row gap-2 items-center justify-between">
                <h3 className="text-lg font-semibold flex items-center gap-2">
                    {!isNotSkipped && 
                        <Badge className="font-normal bg-red-500 hover:bg-red-500">skipped</Badge>
                    }
                    {!isNotSkipped && 
                        <span className='text-gray-600 text-xs'>
                            (This step will be skipped because no other steps have it as their next step or selected by skipped step)
                        </span>
                    }
                </h3>
            
            </div>
        
            {StepComponent && (
                <StepComponent
                    opCodeStep={opCodeStep}
                    stepIndex={stepIndex}
                    opCodeSteps={opCodeSteps}
                    setOpCodeSteps={setOpCodeSteps}
                    nested={nested}
                />
            )}
            
            {/* {stepResult && 
                <StepResultViewer opCodeStep={opCodeStep} stepResult={stepResult}/>
            } */}
        
        </div>

        
            

    )
}

export default OpCodeBuilderStep
"use client"

import { useState, useContext } from "react"
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs"
import { Button } from "components/ui/button"
import { FileText, MessageSquare, School, Folders } from "lucide-react"
import { SubOrgContext } from "context/SubOrganizationContext"
import { AiLabContext } from "pages/PromptingAndRagTraining/context/AiLabContext"
import { Combobox } from "components/ui/combobox"
import { FileSelectionModal } from "../../Common/File/FileSelectionModal"


export const ChatControls = ({mode, onFileSelect}) => {

  const { state, dispatch } = useContext(AiLabContext);
  
  const [isFileSelectOpen, setIsFileSelectOpen] = useState(false);
  
  const { collections } = useContext(SubOrgContext);
  const coll_id = state?.aiTeacher?.ragForm?.collection_id || '';

  const setMode = (newMode) => dispatch({type: "AI_TEACHER_CHANGE_MODE", value: newMode})

  const handleCollectionSelection = (coll) => {
    dispatch({type: "AI_TEACHER_UPDATE_RAG_FIELD", field: "collection_id", value: coll._id})
    // console.log(coll)
  };

  const handleFileSelect = (fileList) => {
    // console.log("CONFIRMED SELECTED FILES:", fileList)
    dispatch({type: "AI_TEACHER_SET_DOCUMENT", value: fileList[0]});
    if (onFileSelect) onFileSelect(fileList[0]);
  }

  return (
    <div className="space-y-4">
      <Tabs value={mode} onValueChange={(e) => setMode(e)} className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="teacher" className="flex items-center gap-2">
            <School className="h-4 w-4" />
            Teacher
          </TabsTrigger>
          <TabsTrigger value="quiz" className="flex items-center gap-2">
            <MessageSquare className="h-4 w-4" />
            Quiz
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <Folders className="w-5 h-5"/>
          <Combobox 
              className=""
              comboboxWidth='w-full' 
              items={collections} 
              setter={handleCollectionSelection} 
              selectedValue={collections?.find((coll) => coll._id === coll_id)} 
              defaultDisplayed="Select Collection"
              disabled={collections?.length === 0} 
          />
        </div>
        <div className="flex flex-row gap-2 items-center">
          
          <Button
              onClick={() => setIsFileSelectOpen(true)}
              disabled={state?.aiTeacher?.ragForm?.collection_id?.trim() === ''}
              variant="outline"
              className="w-full"
          > 
            <FileText className="w-4 h-4 mr-2"/>
            Open Document
          </Button>
        </div>


        {/* File Selection Modal */}
        <FileSelectionModal
            isOpen={isFileSelectOpen} 
            setIsOpen={setIsFileSelectOpen}
            collection_id={coll_id} 
            onFileConfirm={handleFileSelect}
            fileSystem={state?.aiTeacher?.fileSystem}
            setFileSystem={(files) => dispatch({type: "AI_TEACHER_SET_FILE_SYSTEM", value: files})}
        />
      </div>
    </div>
  )
}
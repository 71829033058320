/* eslint-disable max-lines */
import React, { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { SendHorizontal, RotateCcw } from 'lucide-react';
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';
import { AssistantMessage, UserMessage } from '../AITeacher/Chat/MessageDisplay';
import { assistantWsService } from 'api/services/PROJECT-O/WEBSOCKETS/Assistant.websocket.service';
import { Context } from 'context/GlobalState';

const CodeChatBox = () => {
    const { state, dispatch } = useContext(AiLabContext);
    const { addNewNotifcation } = useContext(Context);

    const [userMessage, setUserMessage] = useState('');
    const [asstLoadingMessage, setAsstLoadingMessage] = useState('');
    const [shouldScroll, setShouldScroll] = useState(false);

    const scrollTargetRef = useRef(null);
    const containerRef = useRef(null);

    const { codeSandbox } = state || {};
    const { codeSandboxMessages: messages, isCodeSandboxMessageLoading } = codeSandbox || {};
    const { CODE_SANDBOX_COMMON_ORG, CODE_SANDBOX_COMMON_SUBORG, CODE_SANDBOX_ASST_ID: ASST_ID } = codeSandbox || {};

    useEffect(() => {
        if (shouldScroll && scrollTargetRef.current && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
            setShouldScroll(false);
        }
    }, [shouldScroll]);

    useEffect(() => {
        if (typeof window === "undefined") return;
    
        const observer = new MutationObserver(() => {
            if (scrollTargetRef.current && containerRef.current) {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
        });
    
        if (containerRef.current) {
            observer.observe(containerRef.current, { childList: true, subtree: true });
        }
    
        return () => observer.disconnect();
    }, [messages]);
    

    const handleSendMessage = async () => {
        if (!userMessage.trim()) return;

        try {
            setUserMessage('');
            dispatch({ type: "SET_LOADING", value: true });

            const userMsgObject = { role: "user", content: userMessage.trim() };
            dispatch({ type: "ADD_MESSAGE", value: userMsgObject });
            setShouldScroll(true);

            assistantWsService.setAssistant(ASST_ID);
            const response = await assistantWsService.sendToAssistant(
                CODE_SANDBOX_COMMON_ORG,
                CODE_SANDBOX_COMMON_SUBORG,
                "CodeGen",
                { real_time_data: userMessage.trim() }
            );

            // If no error check last step output
            const lastStepOutput = response?.result?.execution_log?.slice(-1)[0]?.Outputs ? Object.values(response.result.execution_log.slice(-1)[0].Outputs)[0] : '';
            if (!lastStepOutput){
                const assistantMsgMessage = {
                    role: "assistant",
                    content: "An error occured after the execution. No result was generated.",
                };
                dispatch({ type: "ADD_MESSAGE", value: assistantMsgMessage });
                throw Error("The assistant does not have a result after execution.");
            }

            // update with the response
            const assistantMsgMessage = {
                role: "assistant",
                content: lastStepOutput,
            };
            dispatch({ type: "ADD_MESSAGE", value: assistantMsgMessage });

        } catch (error) {
            console.error("Error in handleSendMessage:", error);
            addNewNotifcation(error.message || "An error occurred. Please try again.", "error");
        } finally {
            setAsstLoadingMessage("");
            dispatch({ type: "SET_LOADING", value: false });
        }
    };

    const handleOnClearMessages = useCallback(() => {
        dispatch({ type: "RESET_MESSAGES" });
    }, [dispatch]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
    }, [handleSendMessage]);

    return (
        <Card className="w-full flex flex-col justify-between h-full min-h-[480px]">
            <CardHeader className="max-h-[72px] border-b-[1px] border-gray-200 px-4 py-3.5 flex flex-row flex-nowrap items-center gap-2 select-none">
                <div className="w-full flex flex-col">
                    <CardTitle className="text-sm">Code Sandbox</CardTitle>
                    <CardDescription className="text-xs">Generate code based on your prompts.</CardDescription>
                </div>
            </CardHeader>
            <CardContent className="flex flex-col h-full gap-4 p-0 flex-grow">
                <div className="h-[600px] px-4 overflow-y-auto" ref={containerRef}>
                    {messages.filter((message) => message.role !== "system").map((chat, index) => (
                        <div key={index}>
                            {chat.role === "user" ? (
                                <UserMessage content={chat.content} />
                            ) : (
                                <AssistantMessage
                                    content={chat.content}
                                />
                            )}
                            {isCodeSandboxMessageLoading && ((index === messages.length - 2 && messages[messages.length - 1].role === "assistant") || (index === messages.length - 1 && messages[messages.length - 1].role === "user")) && (
                                <div className="italic text-gray-400 text-sm mb-4 ml-3">
                                    <span>{asstLoadingMessage}</span>
                                </div>
                            )}
                        </div>
                    ))}
                    <div ref={scrollTargetRef} />
                </div>
            </CardContent>

            <CardFooter className="h-16 max-h-16 border-t-[1px] border-gray-200 px-4 py-4 flex flex-row flex-nowrap gap-2">
                <Input
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="Ask the Code Sandbox..."
                    className="col-span-1 h-10 text-xs border-none shadow-none focus-visible:ring-0 focus-visible:ring-offset-0 focus:ring-0 ring-0 focus:outline-0 focus-visibe:outline-none outline-none"
                />
                <Button variant="ghost" size="icon" className="px-2" disabled={!userMessage.trim()} onClick={handleSendMessage}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
                <Button variant="ghost" size="icon" className="px-2" onClick={handleOnClearMessages}>
                    <RotateCcw className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    );
};

export default CodeChatBox;
import { bexHttpClient } from 'api/clients';

/**
 * Service class for performing File Manager operations.
 */
class FileManagerService {

    /**
     * Upload files to COS.
     * @returns {Promise<Object>}
     */
    async uploadFiles(data){
        const response = await bexHttpClient.post(`/cloud-storage/upload-files`, data, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        return response;
    }

    /**
     * get pre-signed urls from COS. (Upload)
     * @returns {Promise<Object>}
     */
    async getPreSignedUrls(data){
        const response = await bexHttpClient.post(`/cloud-storage/generate-presigned-urls`, data)
        return response;
    }

    /**
     * get pre-signed urls from COS.
     * @returns {Promise<Object>}
     */
    async getPublicUrls(data){
        const response = await bexHttpClient.post(`/cloud-storage/get-presigned-urls`, data)
        return response;
    }

    /**
     * delete files in cos, data include organizationId, bucketId, array of files keys
     * @returns {Promise<any[]>}
     */
    async deleteFiles(data) {
        const response = await bexHttpClient.delete(`/cloud-storage/delete-files`, {data})
        return response;
    }

    /**
     * delete files in cos, data include organizationId, bucketId, array of files keys
     * @returns {Promise<any[]>}
     */
    async listFiles(organizationId, bucketId, prefix) {
        let url = `/cloud-storage/list-files?organizationId=${organizationId}&bucketId=${bucketId}`
        if (prefix) {
            url += `&prefix=${prefix}`
        }
        const response = await bexHttpClient.get(url)
        return response;
    }

    
    /**
     * 
     * @returns {Promise<any[]>}
     */

    //list all of the files in the bucket with presigned url
    async listFilesWithPresignedUrl(organizationId, bucketId, prefix) {
        let url = `/cloud-storage/list-files-with-presigned-url?organizationId=${organizationId}&bucketId=${bucketId}` 
        if (prefix) {
            url += `&prefix=${prefix}`
        }
        const response = await bexHttpClient.get(url)
        return response;
    }


}

const fileManagerService = new FileManagerService()
export { fileManagerService }

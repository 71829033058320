import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/ui/dialog";
import { Label } from "components/ui/label";
import { useContext, useState } from "react";
import { milvusService } from "api/services/PROJECT-O/Milvus.service";
import { SubOrgContext } from "context/SubOrganizationContext";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import { SelectDropdown } from "./SelectDropdown";
import { QueryForm } from "./QueryForm";
import { QueryResults } from "./QueryResults";
import { Combobox } from 'components/ui/combobox';
import { useParams } from "react-router-dom";
import { Context } from "context/GlobalState";

export function RagModal({ isOpen, onClose, form, setForm, field, handleCopyImageUrl }) {
    const { collections } = useContext(SubOrgContext);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const { oragID, subOragID } = useParams();
    const { addNewNotifcation } = useContext(Context);

    const [query, setQuery] = useState("");
    const [useRerank, setUseRerank] = useState(false);
    const [searchType, setSearchType] = useState('hybrid');
    const [topK, setTopK] = useState(10);
    const [topN, setTopN] = useState(3);
    const [isQuerying, setIsQuerying] = useState(false);
    const [queryResults, setQueryResults] = useState([]);
    const [message, setMessage] = useState("");
    const [hasQueried, setHasQueried] = useState(false);

    const SEARCH_TYPES_ARR = [
        { name: 'vector', description: '' },
        { name: 'enhanced_vector', description: '' },
        { name: 'hybrid', description: '' },
        { name: 'keyword', description: '' },
    ];

    const handleSendQuery = async (queryText) => {
        if (selectedCollection == null){
            addNewNotifcation("Please select a collection.", "warning")
            return;
        }
        setQueryResults([]);
        try {
            setIsQuerying(true);

            const body = JSON.stringify({
                "organization_id": oragID,
                "sub_organization_id": subOragID,
                "collection_id": selectedCollection._id,
                "query_text": queryText,
                "offset": 0,
                "top_k": topK,
                "top_n": topN,
                "filters": {},
                "advanced_filters": [],
                "use_rerank": useRerank,
                "search_type": searchType
            });

            // console.log(body);
            const res = await milvusService.hybridSearch(body);
            // console.log("Milvus res", res);

            const results = res.data.results || [];
            if (results.length === 0) {
                setMessage("No matching documents found.");
                return;
            }

            setQueryResults(results);
        } catch (error) {
            console.error("Error sending query", error);
        } finally {
            setIsQuerying(false);
        }
    };

    const handleCopyResultToContext = (resultText) => {
        setForm(field, `${form[field] ? form[field] + '\n' : ''}${resultText}`);
        addNewNotifcation(`Copied to ${field == "inputData" ? "Input Data" : "Context"}`, "success")
    }

    const handleClear = () => {
        setQuery("");
        setQueryResults([]);
        setHasQueried(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-6xl select-none h-fit max-h-[800px] min-h-[300px]">
                <DialogHeader>
                    <DialogTitle>Retrieval-Augmented Generation (RAG)</DialogTitle>
                    <DialogDescription>
                        Query information from a collection.
                    </DialogDescription>
                </DialogHeader>
                <div className="w-full max-w-[300px]">
                    <Label>Collection</Label>

                    <Combobox 
                    id="collection-combobox"
                    comboboxWidth='w-[300PX]' 
                    items={collections}
                    setter={setSelectedCollection}
                    selectedValue={selectedCollection} 
                    disabled={collections?.length === 0} 
                    />
                </div>
                <QueryForm onSubmit={handleSendQuery} onClear={handleClear} isQuerying={isQuerying}/>
                <div className="w-full max-w-[500px]">
                    <Label> Search type </Label>
                    <SelectDropdown
                        options={SEARCH_TYPES_ARR}
                        value={searchType}
                        onChange={setSearchType}
                        placeholder="Select vector store type"
                    />
                </div>
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                        <Switch
                            id="use-rerank"
                            checked={useRerank}
                            onCheckedChange={setUseRerank}
                        />
                        <Label htmlFor="use-rerank">Use Rerank</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Label htmlFor="top-k">Top K:</Label>
                        <Input
                            id="top-k"
                            type="number"
                            value={topK}
                            onChange={(e) => setTopK(Number(e.target.value))}
                            className="w-20"
                        />
                    </div>

                    {useRerank &&
                        <div className="flex items-center space-x-2">
                            <Label htmlFor="top-n">Top N:</Label>
                            <Input
                                id="top-n"
                                type="number"
                                value={topN}
                                onChange={(e) => setTopN(Number(e.target.value))}
                                className="w-20"
                            />
                        </div>
                    }
                </div>
                <QueryResults results={queryResults} onResultAction={handleCopyResultToContext} onImageSelect={handleCopyImageUrl} field={field} isQuerying={isQuerying} handleClear={handleClear} hasQueried={hasQueried} setHasQueried={setHasQueried}/>
                {message && (
                    <Label>
                        {message}
                    </Label>
                )}
            </DialogContent>
        </Dialog>
    );
}
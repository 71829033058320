/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context/GlobalState'
import './Organiztion-styles.css'
import OrgForm from './OrgForm'
import { LoaderSpinner } from '../../components/LoaderSpinner'
import { httpRequest } from '../../utils/httpsRequest'
import { Card, CardContent, CardHeader } from '../../components/ui/card'
import { Button } from '../../components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table'
import { LogOut, Trash } from 'lucide-react'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../../components/ui/dialog'
import { Link } from 'react-router-dom'
import { handleLeaveOrganization } from '../../utils/organizationUtils'
import AlertModal from '../../components/Alertmodal'
import { Badge } from 'components/ui/badge'
import { organizationService } from 'api'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import ConfirmDeleteAlert from 'components/ConfirmDeleteAlert'
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout'

function checkNameLengthWithSuffix(name, maxBytes = 38, suffix = '-Main') {
    // Append the suffix to the name
    const fullName = name + suffix;
    
    // Use TextEncoder to calculate the byte length
    const encoder = new TextEncoder();
    const byteLength = encoder.encode(fullName).length;

    // Check if the byte length exceeds the maxBytes limit
    if (byteLength > maxBytes) {
        return true
    } else {
        return false
    }
}


function Organization() {
    const [btnLoading, setBtnLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [orgData, setOrgData] = useState({name:''})
    
    const { user, organizations, addNewNotifcation } = useContext(Context)

    const handleCreate = async () => {
        const orgNameRegex = /^[a-zA-Z0-9_ -]+$/;

        const existed = organizations?.find((org) => org.name.toLowerCase() === orgData.name.toLowerCase())
        if (existed) {
            return addNewNotifcation(`Organization "${orgData.name}" already exist. Please choose a difference name.`, 'warning')
        } else if (orgData.name.trim().length < 4) {
            return addNewNotifcation(`Organization name must be more than 4 character`, 'warning')
        } else if (!orgNameRegex.test(orgData.name.trim())) {
            return addNewNotifcation(`Organization name should not contain special character`, 'warning')
        } else if (checkNameLengthWithSuffix(orgData.name.trim().replaceAll(' ', '-'))) {
            return addNewNotifcation(`Organization name is too long. Please shorten it to fit within 30 characters or less.`, 'warning')
        }


        const orgObj = {
            name: orgData.name.trim(),
            created: new Date(),
            members:[],
            createdBy: user.id,
            superAdmin: user.id,
        }

        try {
            setBtnLoading(true)
            const res = await httpRequest.post('/db/organizations/insert_organization',orgObj)
            console.log('res: ',res)
            
            addNewNotifcation(`Organization "${orgData.name}" has been created successfully.`, 'success')
            // addNewNotifcation(`Sub Organization "${subOrganizationName}" has been created successfully.`, 'success')
            setTimeout(() => {
                window.location = '/'
            }, 1500)

        } catch(err) {
            console.log('err: ',err)
            if (err.response?.status === 409) {
                addNewNotifcation(`Organization name "${orgData.name}" already exist.`, 'warning')
            } else {
                addNewNotifcation(`Organization "${orgData.name}" could not be created. Please check all your entries.`, 'warning')
            }
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <NestedPageLayout>
            <Card className="shadow-md flex-grow">
                <CardHeader>
                    <div className="flex items-center justify-between mb-6">
                        <h1 className="font-bold"> Organizations </h1>
                        <div className="flex items-center gap-4">
                            <Button onClick={() => setOpenModal(true)} size="sm">
                                Create New Organization
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="border rounded-lg overflow-hidden">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Created</TableHead>
                                    <TableHead>Members</TableHead>
                                    <TableHead>Cluster Status</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {organizations.map((item, index) => (
                                    <OrganizationCard {...item} index={index} key={index} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>

            {/* create organization modal */}
            <Dialog open={openModal} onOpenChange={setOpenModal}>
                <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create New Organization</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new organization.</DialogDescription>
                    </DialogHeader>
                    <OrgForm orgData={orgData} setOrgData={setOrgData}/>

                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                        <Button onClick={handleCreate} className="px-5 py-3 text-md">
                            {btnLoading ? <LoaderSpinner /> : 'Create Organization'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </NestedPageLayout>
    )
}

const OrganizationCard = ({name, created, _id, members, index, clusterStatus, reason, superAdmin}) => {

    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    // leave org state
    const [LeaveLoading, setLeaveLoading] = useState(false)
    const [leaveOrgModal, setLeaveOrgModal] = useState(false)
    const { user, addNewNotifcation } = useContext(Context)

    const isSuperAdmin = superAdmin._id === user._id; // Check if the user is a superAdmin for this specific organization

    const handleLeave = async () => {
        setLeaveLoading(true)
        try {
            await handleLeaveOrganization(_id, user._id)
        } catch (error) {
            if (error.response?.status === 409) {
                addNewNotifcation('Super admin cannot leave the organization', 'warning')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'warning')
            }
        } finally {
            setLeaveLoading(false)
        }
    }

    const handleDeleteOrganization = () => {
        if (!isSuperAdmin) {
            return addNewNotifcation('Super admin can only delete the organization', 'warning')
        }

        setDeleteLoading(true)
        organizationService.deleteOrganization(_id)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Organization delete successfully', 'success')
            setTimeout(() => {
                window.location = '/organizations'
            }, 1000)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 409) {
                addNewNotifcation('Super admin can only delete the organization', 'warning')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'warning')
            }
        }).finally(() => {
            setDeleteLoading(false)
        })
    }

    const handleOpenModel = () => {
        if (isSuperAdmin) {
            setIsDialogOpen(true)
        } else {
            setLeaveOrgModal(true)
        }
    }

    useEffect(() => {
        let timeoutId;
    
        const checkStatus = async () => {
            if (clusterStatus === 'DEPLOYING') {
                const res = await checkDeploymentStatus();
                if (res) {
                    window.location.reload();
                } else {
                    timeoutId = setTimeout(checkStatus, 5000); // Wait 5 seconds before next check
                }
            }
        };
    
        checkStatus(); // Start the status check
    
        return () => clearTimeout(timeoutId); // Clear timeout on cleanup
    }, [clusterStatus]);

    const checkDeploymentStatus = async () => {
        try {
            const response = await organizationService.checkOrganizationCluster(_id)
            if (response.status === 202) {
                return false
            } else {
                return true
            } 
        } catch (error) {
            console.error('Error checking deployment status:', error);
            return false;
        }
    };

    const membersCount = clusterStatus === 'DEPLOYED' ? (members?.length || 0) + 1 : 'N/A'



    return (
        <TableRow key={index}>
            <TableCell className='p-4'>
                <div className="flex items-center gap-2">
                    <Link className={`flex items-center gap-2 text-[blue] hover:underline ${clusterStatus === 'DEPLOYED' ? '' : 'pointer-events-none'}`} to={`/organizations/${_id}/sub`}>
                        <span>{name}</span>
                    </Link>
                </div>
            </TableCell>
            <TableCell className='p-4'>{created.split('T')[0] || "2024/2/2"}</TableCell>
            <TableCell className='p-4'>{membersCount}</TableCell>
            <TableCell className='p-4'>
                {clusterStatus === 'DEPLOYED' ? (
                    <Badge variant="outline" className="bg-green-500 text-green-50">
                        Deployed
                    </Badge>
                ) : clusterStatus === 'DELETED' ? (
                    <Badge variant="outline" className="bg-red-500 text-green-50">
                        DELETED
                    </Badge>
                ) : clusterStatus === 'FAILED' ? (
                    <>
                        <Badge variant="outline" className="bg-red-500 text-yellow-50">
                            failed
                        </Badge>
                        <div className="text-xs text-muted-foreground max-w-[200px] truncate">
                            {reason || 'Something went wrong'}
                        </div>
                    </>
                ) : clusterStatus === 'DELETING' ? (
                    <>
                        <Badge variant="outline" className="bg-red-500 text-yellow-50">
                            deleting
                        </Badge>
                    </>
                ) : (
                    <>
                        <Badge variant="outline" className="bg-yellow-500 text-yellow-50">
                            Deploying...
                        </Badge>
                        <div className="text-xs text-muted-foreground">
                            Organization is still being deployed and is not yet available for use.
                        </div>
                    </>
                )}
            </TableCell>
            <TableCell className='p-4'>
                <div className="flex items-center gap-2">
                    {isSuperAdmin ? (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div>
                                        <Button disabled={clusterStatus === 'DEPLOYING' || clusterStatus === 'DEPLOYED' || clusterStatus === 'DELETING'} onClick={handleOpenModel} variant="destructive" className='flex items-center gap-2 text-[13px]' size="sm">
                                            <Trash size={13} />
                                            Delete Organization
                                        </Button>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent className="bg-gray-900 text-white">
                                    {clusterStatus === 'DEPLOYED' && (
                                        <span> You can not delete organization with deployed cluster, you need to terminate its cluster first </span>
                                    )}
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    ) : (
                        <Button disabled={clusterStatus === 'DEPLOYING'} onClick={handleOpenModel} variant="destructive" className='flex items-center gap-2 text-[13px]' size="sm">
                            <LogOut size={13} />
                            Leave
                        </Button>
                    )}
                </div>
            </TableCell>

            {/* leave organization alert modal */}
            <AlertModal openModal={leaveOrgModal} 
            setopenModal={setLeaveOrgModal} onDelete={handleLeave} loading={LeaveLoading} 
            title='Are you sure you want to leave this organization' 
            subTitle='This action cannot be undone and you will lose access to all organizational resources and data.' 
            btnTitle='Leave' />


            {/* delete organization alert */}
            <ConfirmDeleteAlert
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen} 
            name={name}
            deleteLoading={deleteLoading}
            handleDeleteOrganization={handleDeleteOrganization} />

        </TableRow>
    )
}

export default Organization
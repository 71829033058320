/* eslint-disable max-lines */
import { useEffect, useState } from "react"
import { Book, ChevronDown, ChevronRight, Clock, File, FileJson, Plus, RefreshCw, Text, Trash } from "lucide-react"

import { Badge } from "components/ui/badge"
import { Button } from "components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { ScrollArea } from "components/ui/scroll-area"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { organizationAdminAccessService } from "api"
import { AppLoader } from "components/LoaderSpinner"
import { formatDate } from "utils/timeUtils"

const navItems = [
  { id: "opcode", name: "OpCodes", icon: FileJson },
  { id: "collections", name: "Collections", icon: File },
  { id: "assistant", name: "Assistants", icon: Text },
  { id: "SambaNovaModel", name: "SambaNovaModels", icon: Book },
  { id: "GroqModel", name: "GroqModels", icon: Book },
  { id: "modelDetails", name: "Watson model", icon: Book },
]

// Get a shortened version of the key for display
const getShortenedKey = (key) => {
    const parts = key.split(":")
    return parts[parts.length - 1] || key
}

// Group backups by key first (to track versions)
const groupBackupsByKey = (backups) => {
    const grouped = {}

    backups.forEach((backup) => {
        if (!grouped[backup.key]) {
            grouped[backup.key] = []
        }
        grouped[backup.key].push(backup)
    })

    // Sort each group by timestamp (newest first)
    Object.keys(grouped).forEach((key) => {
        grouped[key].sort((a, b) => Number.parseInt(b.snapshotTimestamp) - Number.parseInt(a.snapshotTimestamp))
    })

    return grouped
}

// Get change type badge
const getChangeTypeBadge = (changeType) => {
    switch (changeType) {
        case "added":
            return (
                <Badge className="bg-green-500 hover:bg-green-600">
                    <Plus className="h-3 w-3 mr-1" />
                    Added
                </Badge>
            )
        case "updated":
            return (
                <Badge className="bg-blue-500 hover:bg-blue-600">
                    <RefreshCw className="h-3 w-3 mr-1" />
                    Updated
                </Badge>
            )
        case "deleted":
            return (
                <Badge className="bg-red-500 hover:bg-red-600">
                    <Trash className="h-3 w-3 mr-1" />
                    Deleted
                </Badge>
            )
        default:
        return <Badge>{changeType}</Badge>
    }
}

export default function BackupViewer({ subOrg }) {
    const [selectedBackup, setSelectedBackup] = useState(null)
    const [selectedEntity, setSelectedEntity] = useState('opcode')
    const [expandedKeys, setExpandedKeys] = useState({})
    const [groupedBackups, setGroupedBackups] = useState([])
    const [loading, setLoading] = useState(false)

    // Toggle expanded state for a key
    const toggleExpand = (key) => {
        setExpandedKeys((prev) => ({
            ...prev,
            [key]: !prev[key],
        }))
    }

    useEffect(() => {
        if (!selectedEntity) return
        setLoading(true)
        organizationAdminAccessService.getSubOrganizationBackupData(subOrg.organizationId, subOrg._id, selectedEntity)
        .then((res) => {
            setGroupedBackups(groupBackupsByKey(res.data))
            console.log(res)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading(false)
            console.log("Done")
        })
    }, [selectedEntity])

    return (
        <div className="flex mx-auto py-6">
            {/* Sidebar */}
            <div className="min-w-48 border-r bg-muted/30">
                <div className="p-6">
                    <h2 className="text-lg font-semibold mb-4">Entities</h2>
                    <nav className="space-y-2">
                        {navItems.map((entity) => {
                            const Icon = entity.icon
                            return (
                                <button
                                className={`flex items-center gap-3 truncate ${selectedEntity === entity.id ? 'bg-gray-300' : ''} hover:bg-gray-200 p-3 w-full rounded-md`}
                                key={entity.id}
                                onClick={() => setSelectedEntity(entity.id)}>
                                    <Icon className="min-h-4 min-w-4 max-w-4 max-h-4" />
                                    {entity.name}
                                </button>
                            )
                        })}
                    </nav>
                </div>
            </div>
            <div className="flex flex-col space-y-6 w-full">

                {loading ? (
                    <div className="flex items-center justify-center h-64">
                        <AppLoader size={50} />
                    </div>
                ) : (
                    <div className={`grid grid-cols-1 ${selectedBackup ? 'md:grid-cols-3' : 'md:grid-cols-1'} w-full gap-6`}>
                        <div className="md:col-span-2 w-full">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Version History</CardTitle>
                                    <CardDescription>View all versions of backup items</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <ScrollArea className="h-[calc(100vh-250px)]">
                                        <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead className="w-[40px]"></TableHead>
                                                    <TableHead>Item</TableHead>
                                                    <TableHead>Time</TableHead>
                                                    <TableHead>Status</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {Object.entries(groupedBackups).map(([key, versions]) => {
                                                    const latestVersion = versions[0]
                                                    const hasVersions = versions.length > 1

                                                    return (
                                                        <>
                                                            <TableRow
                                                            key={`${key}-latest`}
                                                            className={`cursor-pointer ${selectedBackup?._id === latestVersion._id ? "bg-muted" : ""}`}
                                                            onClick={() => selectedBackup?._id === latestVersion._id ? setSelectedBackup(null) : setSelectedBackup(latestVersion)}
                                                            >
                                                                <TableCell>
                                                                    {hasVersions && (
                                                                    <Button
                                                                        variant="ghost"
                                                                        size="icon"
                                                                        className="h-6 w-6"
                                                                        onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        toggleExpand(key)
                                                                        }}
                                                                    >
                                                                        {expandedKeys[key] ? (
                                                                            <ChevronDown className="h-4 w-4" />
                                                                        ) : (
                                                                            <ChevronRight className="h-4 w-4" />
                                                                        )}
                                                                    </Button>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell className="font-medium">
                                                                    {getShortenedKey(key)}
                                                                    <div className="text-xs text-muted-foreground">Current Version</div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div className="flex items-center">
                                                                        <Clock className="h-3 w-3 mr-1" />
                                                                        {formatDate(latestVersion.snapshotTimestamp)}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>{getChangeTypeBadge(latestVersion.changeType)}</TableCell>
                                                            </TableRow>

                                                            {hasVersions &&
                                                            expandedKeys[key] &&
                                                            versions.slice(1).map((version, index) => (
                                                                <TableRow
                                                                key={`${key}-version-${index}`}
                                                                className={`cursor-pointer bg-muted/30 ${selectedBackup?._id === version._id ? "bg-muted" : ""}`}
                                                                onClick={() => setSelectedBackup(version)}
                                                                >
                                                                    <TableCell></TableCell>
                                                                    <TableCell className="pl-8">
                                                                        <div className="text-xs text-muted-foreground">
                                                                            Version {versions.length - index - 1}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>{version.value?.type || "N/A"}</TableCell>
                                                                    <TableCell>
                                                                        <div className="flex items-center">
                                                                            <Clock className="h-3 w-3 mr-1" />
                                                                            {formatDate(version.snapshotTimestamp)}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>{getChangeTypeBadge(version.changeType)}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </ScrollArea>
                                </CardContent>
                            </Card>
                        </div>

                        {selectedBackup && (
                            <div className="md:col-span-1">
                                <Card className="h-full overflow-hidden">
                                    <CardHeader>
                                        <CardTitle>
                                            <>
                                                {getShortenedKey(selectedBackup.key)}
                                                <span className="ml-2">{getChangeTypeBadge(selectedBackup.changeType)}</span>
                                            </>
                                        </CardTitle>
                                        <CardDescription>
                                            {`Version from ${formatDate(selectedBackup.snapshotTimestamp)}`}
                                        </CardDescription>
                                    </CardHeader>
                                    <CardContent>
                                        <ScrollArea className="h-[calc(100vh-350px)] overflow-x-scroll">
                                            <pre className="p-4 bg-muted rounded-md text-xs">
                                                {JSON.stringify(selectedBackup, null, 2)}
                                            </pre>
                                        </ScrollArea>
                                    </CardContent>
                                </Card>
                            </div>
                        )}

                    </div>
                )}

            </div>
        </div>
    )
}
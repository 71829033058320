import React from 'react'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';
import InputValidation from 'components/InputValidation';
import { Label } from 'components/ui/label';
import NextStep from '../NextStep';
import { Input } from 'components/ui/input';

const StepMemory = ({ opCodeStep, stepIndex, opCodeSteps, setOpCodeSteps}) => {
    const output = opCodeStep?.output.Output

    const handleInputChange = (field, value) => {
            const newOpCodeStep = {...opCodeStep};

            if (field === 'n') {
                newOpCodeStep.input.n = Math.abs(value);
            } else if (field === 'limit_size') {
                newOpCodeStep.input.limit_size = Math.abs(value); 
            } else if (field === 'output') {
                newOpCodeStep.output.Output = value.trim();
            }
            
            setOpCodeSteps(opCodeSteps.map((opCoStep, i) => i === stepIndex ? newOpCodeStep : opCoStep));
    };

    return (
        <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 items-center gap-4 w-full">
            <div >
                <Label htmlFor={`n`}>Number of log entries</Label>
                <Input
                className="bg-white"
                type="number"
                min="0"
                value={opCodeStep?.input?.n || 0}
                onChange={(e) => handleInputChange("n", e.target.value)}
                />
            </div>

            <div >
                <Label htmlFor={`limit_size`}>Limit Size</Label>
                <Input
                className="bg-white"
                type="number"
                min="0"
                value={opCodeStep?.input?.limit_size || 0}
                onChange={(e) => handleInputChange("limit_size", e.target.value)}
                />
            </div>
            
            <div className="flex flex-col justify-center gap-1 pt-1">
                <Label htmlFor={`output-${stepIndex}`}>Step Output key</Label>
                <InputValidation
                    id={`output-${stepIndex}`}
                    value={output || ""}
                    onChange={(e) => handleInputChange("output", e.target.value)}
                    className="bg-white"
                    validation={handler.validateOutputName}
                    disabled={handler.isOutputUsedInLaterSteps(opCodeSteps, output, stepIndex)}
                />
            </div>

            <div className="flex flex-col justify-center gap-1 pt-1">
                <NextStep
                opCodeStep={opCodeStep}
                opCodeSteps={opCodeSteps}
                setOpCodeSteps={setOpCodeSteps}
                stepIndex={stepIndex}
                />
            </div>
        </div>   
    );
}

export default StepMemory
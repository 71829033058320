import React, { useContext } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Label } from 'components/ui/label'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler'
import { OpCodeBuilderContext } from '../../OpCodeBuilderHAndC/OpCodeBuilderContext'

const StepIdSelector = ({ label, isTrue, stepIndex, opCodeStep, opCodeSteps, setOpCodeSteps, selectedStep }) => {
    const {indicators } = useContext(OpCodeBuilderContext)

    const handleStepSelect = (stepId) => {
        if (stepId ) {
            const newOpCodeStep = {
                ...opCodeStep, 
                next_step:{ ...opCodeStep.next_step, [isTrue? "true_branch" : "false_branch"]: [stepId] }
            }
            setOpCodeSteps(opCodeSteps.map((opCoStep, i) => i === stepIndex? newOpCodeStep : opCoStep))
        }
    }

    return (
        <div className="">
            <Label className="block text-sm font-medium text-gray-700">{label}</Label>
            <Select
                onValueChange={(value) => handleStepSelect(value)}
                value={selectedStep[0] || ""}
            >
                <SelectTrigger className="w-full bg-white">
                    <SelectValue placeholder="Select step" />
                </SelectTrigger>
                <SelectContent>
                    {handler.getNextSteps(opCodeSteps, stepIndex, indicators).map((item) => (
                        <SelectItem key={item.nextStep.label} value={item.nextStep.value}>
                            {item.nextStep.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}

export default StepIdSelector


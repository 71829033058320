import React, { useContext, useEffect, useState } from 'react'
import { initialOpCodeSteps, OpCodeBuilderContext, STEP_TYPES } from './OpCodeBuilderHAndC/OpCodeBuilderContext'
import { Context } from 'context/GlobalState'
import handler from './OpCodeBuilderHAndC/OpCodeBuilderHandler'
import { Label } from 'components/ui/label'
import InputValidation from 'components/InputValidation'
import { Textarea } from 'components/ui/textarea'
import OpCodeBuilderSteps from './OpCodeBuilderSteps'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import { Step, StepsContainer } from 'components/NewDesignComponents/VerticalSteps'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { opcodeService } from 'api/services/BEX/opcode.service'

const OpCodeBuilderCreate = ({openModal, setOpenModal, scrollAreaRef}) => {
    const [opCodeName, setOpCodeName] = useState("");
    const [opCodeDescription, setOpCodeDescription] = useState("");
    const [opCodeSteps, setOpCodeSteps] = useState(initialOpCodeSteps);
    const [isCreating, setIsCreating] = useState(false);
    const [stepTypes, setStepTypes] = useState([STEP_TYPES[0].name]);
    const { orgId, subOrgId, actions, opCodes, opType, constantStepTypes, validateSteps } = useContext(OpCodeBuilderContext)
    const { addNewNotifcation } = useContext(Context);
    const parentId = window.crypto.randomUUID()

    useEffect(() => {
        const indicatorArray = handler.generateStepNumbers(opCodeSteps)

        if(indicatorArray.length > 0){
            actions({type:"SET_INDICATORS", payload:indicatorArray})
        }
    
    }, [opCodeSteps])
    
    const createOpCode = async () => {
        // Validate the opCodeName first
        let { isValid: isOpCodeValid, message: opCodeMessage } = handler.validateOpCodeName(opCodeName.trim());
        if (!isOpCodeValid) {
            addNewNotifcation(opCodeMessage, "warning");
            return;
        }

        // Validate the opCodeSteps (steps)
        if (opCodeSteps.length < 2){
            addNewNotifcation("Please add at least one step to the opcode other than Identification.", "warning");
            return;
        }

        if(opType === "ChatOp" && opCodeSteps[opCodeSteps.length - 1].step_type !== "LLM-Stream"){
            addNewNotifcation("In type chat opcode, the final step must be LLM-Stream.", "warning");
            return;
        }

        let { isValid: areStepsValid, message: stepsMessage } = validateSteps(opCodeSteps);
        if (!areStepsValid) {
            addNewNotifcation(stepsMessage, "warning");
            return;
        }
        
        const opCodeData = {
            org_id: orgId,
            sub_org_id: subOrgId,
            opcode_id: opCodeName.trim(),
            description: opCodeDescription,
            type:opType,
            identification_step:opCodeSteps[0],
            steps: opCodeSteps.slice(1)
        };
    
        // console.log("OpcodeData:", opCodeData);
    
        try {
            setIsCreating(true);
            const res = await opcodeService.createOpCode(opCodeData);
            // console.log(res);
            addNewNotifcation("Opcode saved successfully.", "success");
            actions({type:"SET_OP_CODES", payload:[...opCodes, {...res.data, created:new Date()}]});
            setOpCodeName("");
            setOpCodeDescription("");
            setOpCodeSteps([]);
            setOpenModal(false)
            if (scrollAreaRef.current) {
                scrollAreaRef.current.scrollTo({ top: scrollAreaRef.current.scrollHeight + 50, behavior: "smooth" });
            }
            actions({type:"SET_GLOBAL_CONTEXT", payload:[]})
        } catch (error) {
            console.error("Error creating Opcode:", error);
            if (error?.response?.status === 409) {
                addNewNotifcation("Opcode already exists. Please choose another unique name.", "warning");
            } else {
                addNewNotifcation("Opcode cannot be created. Please try again.", "danger");
            }
        } finally {
            setIsCreating(false);
        }
    };

    const validateOpNameDesType = () => {
        return (!handler.validateOpCodeName(opCodeName.trim())?.isValid || !opType || !opCodeDescription.trim())
    }

    const handleTypeChange = (value) => {
        const isAuto = value === 'AutoOp'
        const firstPart = isAuto ? "LLM-Stream" : "LLM"
        const secondPart = isAuto ? "Memory" : "LLM"
        const constantTypes = STEP_TYPES.filter(type => type.name !== firstPart && type.name !== secondPart)

        actions({type:"SET_OP_CODE_TYPE", payload:value })

        actions({type:"SET_CONSTANT_TYPES", payload: constantTypes})

        // setOpCodeSteps(opCodeSteps.filter(step=> step.step_type !== firstPart && step.step_type !== secondPart))
        setOpCodeSteps(initialOpCodeSteps)

        // setStepTypes(stepTypes.filter(type => type !== firstPart && type !== secondPart))
        setStepTypes([STEP_TYPES[0].name])
    }
      
    const handleMoveStep = (dragIndex, hoverIndex, steps, parentIdContainer) => {
        try{
            if(parentId === parentIdContainer){
                let newOpCodeSteps = []
                let newTypes = []
                steps?.map(step => {
                    if(step.props.indicator !== "S" && step.props.indicator !== "E"){
                        const foundInput = opCodeSteps.find(opCodeStep => opCodeStep.step_id === step.props.step_id)
        
                        newOpCodeSteps.push(foundInput)
        
                        newTypes.push(foundInput.step_type)
                    }
                })
                setOpCodeSteps(newOpCodeSteps)
                setStepTypes(newTypes)
            }
        }catch(err) {
            console.log(err)
        }
    }
    
    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="xl:max-w-[1250px] lg:max-w-[1000px] md:max-w-[850px] max-w-[700px] w-full p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='lg:max-h-[680px] md:max-h-[580px] sm:max-h-[400px] p-4' >
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create New Opcode</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new opcode.</DialogDescription>
                    </DialogHeader>
                    <StepsContainer onMoveStep={handleMoveStep} parentId={parentId} isNotNestead={true} className={'w-full'}>
                        <Step
                          indicator="S"
                          canAddBefore={false}
                          canAddAfter={false}
                          isDraggable={false}
                          canDelete={false}
                          isDisabled={false}
                          itemId={parentId}
                          title="Opcode Form"
                        >
                            <div className="space-y-4 w-full">
                                <div className="flex justify-between gap-2">
                                    <div className='w-full'>
                                        <Label>Opcode ID <span className='text-gray-400'>(Required)</span></Label>
                                        <InputValidation
                                            defaultValue={opCodeName}
                                            // value={opCodeName}
                                            onChange={(e) => setOpCodeName(e.target.value)}
                                            placeholder="Enter a unique name for this opcode"
                                            validation={handler.validateOpCodeName}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <Label>Opcode Type <span className='text-gray-400'>(Required)</span></Label>
                                        <Select onValueChange={handleTypeChange} 
                                        id="opcode_type" 
                                        className="w-full" value={opType}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select opcode type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value={"AutoOp"}>{"Auto Opcode"}</SelectItem>
                                                <SelectItem value={"ChatOp"}>{"Chat Opcode"}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                                <div>
                                    <Label>Opcode Description <span className='text-gray-400'>(Required)</span></Label>
                                    <Textarea 
                                        defaultValue={opCodeDescription}
                                        // value={opCodeDescription} 
                                        className="min-h-[100px]"
                                        onChange={(e) => setOpCodeDescription(e.target.value)} 
                                        placeholder="Enter a description for this opcode" 
                                    />
                                </div>
                            </div>
                        </Step>
                        <OpCodeBuilderSteps 
                        opCodeStepsState={{opCodeSteps, setOpCodeSteps}} 
                        stepTypesState={{stepTypes, setStepTypes}} 
                        otherProps={{parentId, constantStepTypes, orgId, subOrgId, opType, validateOpNameDesType}}
                        />   
                        <Step
                        indicator="E"
                        canAddBefore={true}
                        canAddAfter={false}
                        isDraggable={false}
                        canDelete={false}
                        itemId={parentId}
                        isDisabled={validateOpNameDesType()}
                        title="Opcode Summary and Create"
                        >
                            <div className='flex flex-col w-full'>
                                <Card className="mb-4 w-full">
                                    <CardContent className="pt-6">
                                        <h3 className="text-lg font-semibold mb-2">OpCode Summary</h3>
                                        <p><strong>Name:</strong> {opCodeName}</p>
                                        <p><strong>Description:</strong> {opCodeDescription || 'No description provided'}</p>
                                        <p><strong>Number of Steps:</strong> {opCodeSteps?.length}</p>
                                        <h4 className="font-semibold mt-2 mb-1">Step Types:</h4>
                                        <ul className="list-disc pl-5 flex gap-6">
                                            {stepTypes.map((type, index) => (
                                                <li key={index}>{type}</li>
                                            ))}
                                        </ul>
                                    </CardContent>
                                </Card>
                                <Button onClick={createOpCode} disabled={isCreating}>
                                    {isCreating ? "Creating..." : "Create"}
                                </Button>
                            </div>
                        </Step>
                    </StepsContainer>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}
export default OpCodeBuilderCreate
import React, { useEffect, useState } from 'react'
import { Card, CardTitle, CardContent, CardHeader } from 'components/ui/card'
import NewStyledTable from 'components/ui/StyledTable'

export default function ResourceUsage({ organizationData }) {

    const [subOrgRowActions, setSubOrgRowActions] = useState(organizationData.subOrganizations || []);

    useEffect(() => {
        // Calculate totals
        const totals = {
            name: 'Total',
            assistantInstances: organizationData.subOrganizations.reduce((sum, item) => sum + item.assistantInstances.length, 0),
            opcodes: organizationData.subOrganizations.reduce((sum, item) => sum + item.opcodes.length, 0),
            instructions: organizationData.subOrganizations.reduce((sum, item) => sum + item.instructions.length, 0),
            collections: organizationData.subOrganizations.reduce((sum, item) => sum + item.collections.length, 0),
            channels: organizationData.subOrganizations.reduce((sum, item) => sum + item.channels.length, 0),
        }

        setSubOrgRowActions(prev => [...prev, totals])

    }, [organizationData])
    

    return (
        <Card>
            <CardHeader>
                <CardTitle> Resource Usage </CardTitle>
            </CardHeader>
            <CardContent>
                <NewStyledTable
                cardTitle={`Resource Usage`}
                columns={[
                    {
                        field: 'name', 
                        headerName: 'Sub-org Name',
                        renderCell: (row) => (
                            <div className={`${row.name === 'Total' ? 'font-semibold' : ''}`}>
                                {row.name}
                            </div>
                        )
                    },
                    {
                        field: 'Collections', 
                        headerName: 'collection', 
                        renderCell: (row) => (
                            <div className={`${row.name === 'Total' ? 'font-semibold' : ''}`}>
                                {row.name === 'Total' ? row.collections : row.collections.length}
                            </div>
                        )
                    }, 
                    {
                        field: 'assistantInstances', 
                        headerName: 'Assistant Instances',
                        renderCell: (row) => (
                            <div className={`${row.name === 'Total' ? 'font-semibold' : ''}`}>
                                {row.name === 'Total' ? row.assistantInstances : row.assistantInstances.length}
                            </div>
                        )
                    },
                    {
                        field: 'opcodes', 
                        headerName: 'Opcodes',
                        renderCell: (row) => (
                            <div className={`${row.name === 'Total' ? 'font-semibold' : ''}`}>
                                {row.name === 'Total' ? row.opcodes : row.opcodes.length}
                            </div>
                        )
                    },
                    {
                        field: 'channels', 
                        headerName: 'Channels',
                        renderCell: (row) => (
                            <div className={`${row.name === 'Total' ? 'font-semibold' : ''}`}>
                                {row.name === 'Total' ? row.channels : row.channels.length}
                            </div>
                        )
                    },
                    {
                        field: 'instructions', 
                        headerName: 'Instructions',
                        renderCell: (row) => (
                            <div className={`${row.name === 'Total' ? 'font-semibold' : ''}`}>
                                {row.name === 'Total' ? row.instructions : row.instructions.length}
                            </div>
                        )
                    },
                ]}
                data={subOrgRowActions}
                />
            </CardContent>
        </Card>
    )
}
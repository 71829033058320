import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import React, { useEffect, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import NestedSteps from './NestedSteps'
import NextStep from '../NextStep'
import ConditionBuilder from '../ConditionBuilder'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler'
import { Switch } from 'components/ui/switch'

const StepLoop = ({stepIndex, opCodeStep, opCodeSteps, setOpCodeSteps}) => {
    const [selectedBreakOpt, setSelectedBreakOpt] = useState("max_iterations")
    const [nestedPrevOutputs, setNestedPrevOutputs] = useState([])
    const parentId = window.crypto.randomUUID()
    const previousOutputs = handler.getPreviousOutputs(opCodeSteps, stepIndex)

    useEffect(() => {
        if(opCodeStep?.input?.max_iterations !== undefined && opCodeStep?.input?.max_iterations !== null ){
            setSelectedBreakOpt("max_iterations")
        } else if(opCodeStep?.input?.break_condition !== undefined && opCodeStep?.input?.break_condition !== null) {
            setSelectedBreakOpt("break_condition")
        }
    
    }, [opCodeStep])

    const handleCollectionChange = (value) => {
        const newOpCodeStep = {...opCodeStep}
        newOpCodeStep.input.collection = value
        setOpCodeSteps(prev => prev.map(step => step.step_id === opCodeStep.step_id? newOpCodeStep : step))
    }

    const handleBreakOptSelection = (value) => {
        const newOpCodeStep = {...opCodeStep}

        if(value === "break_condition"){
            newOpCodeStep.input.break_condition = ""
            delete newOpCodeStep.input.max_iterations
        } else {
            newOpCodeStep.input.max_iterations = ""
            delete newOpCodeStep.input.break_condition
        }
        
        setSelectedBreakOpt(value)
        setOpCodeSteps(prev => prev.map(step => step.step_id === opCodeStep.step_id? newOpCodeStep : step))
    }

    const handleInputChange = (name, value) => {
        const newOpCodeStep = {...opCodeStep}
        if(name === "break_condition") {
            newOpCodeStep.input[name] = value 
        } else if (name === "max_iterations") {
            newOpCodeStep.input[name] = Math.abs(Number(value))
        } else {
            newOpCodeStep.output.Output = value.trim()
        }

        setOpCodeSteps(prev => prev.map(step => step.step_id === opCodeStep.step_id? newOpCodeStep : step))
    }

    const handleParallel = (value) => {
        const newOpCodeStep = {...opCodeStep}
        newOpCodeStep.input.parallel = value
        setOpCodeSteps(prev => prev.map(step => step.step_id === opCodeStep.step_id? newOpCodeStep : step))
    }

	return (
    <div className='w-full space-y-7'>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
            <div className="">
                <Label htmlFor="collection">List Name</Label>
                <Input
                id={`collection`}
                name="collection"
                value={opCodeStep?.input?.collection}
                onChange={(e) => handleCollectionChange(e.target.value.trim())}
                placeholder="Enter list name here"
                className="border-0 focus-visible:ring-0 focus-visible:ring-offset-0 bg-white"
                />
            </div>
            
            <div className="">
                <Label htmlFor={`input`} className="">
                    Parallel
                </Label>
                <div className="flex items-center bg-white gap-2 h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 
                text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground 
                focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
                    <Switch id="real-time-data" checked={opCodeStep?.input?.parallel} onCheckedChange={handleParallel} />
                    <span className="text-xs text-gray-400">Include Parallel</span>
                </div>
            </div>
        </div>

        <NestedSteps 
        parentId={parentId} 
        mainStepIndex={stepIndex} 
        opCodeStep={opCodeStep}
        opCodeSteps={opCodeSteps} 
        setOpCodeSteps={setOpCodeSteps}
        setNestedPrevOutputs={setNestedPrevOutputs}
        />

        <div className="flex-1 w-full space-y-2">
            <Label htmlFor="collection">Break Loop</Label>
            <Select value={selectedBreakOpt} onValueChange={(value) => handleBreakOptSelection(value)}>
                <SelectTrigger id={`select-opt`} className="bg-white">
                    <SelectValue placeholder={`Select Option`} />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem key={"break_condition"} value={"break_condition"}>
                        Condition
                    </SelectItem>
                    <SelectItem key={"max_iterations"} value={"max_iterations"}>
                        Max Iterations
                    </SelectItem>
                </SelectContent>
            </Select>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {selectedBreakOpt === "break_condition" && 
                    <div className='col-span-3'>
                        <ConditionBuilder
                        stepIndex={stepIndex}
                        opCodeSteps={opCodeSteps}
                        setOpCodeSteps={setOpCodeSteps}
                        onConditionChange={(value) => handleInputChange("break_condition", value)}
                        initialCondition={opCodeStep?.input?.break_condition || ""}
                        previousOutputs={[...previousOutputs, ...nestedPrevOutputs]}
                        loop={true}
                        />
                    </div>
                }

                {selectedBreakOpt === "max_iterations" && 
                    <div className="col-span-1">
                        <Label htmlFor="max_iterations">Max Iterations</Label>
                        <Input
                        id="max_iterations"
                        name="max_iterations"
                        type="number"
                        min={1}
                        value={opCodeStep?.input?.max_iterations || ""}
                        onChange={(e) => handleInputChange("max_iterations", e.target.value)}
                        placeholder="Enter maximum iterations"
                        className="bg-white"
                        />
                    </div>
                }

                <div className="col-span-1">
                    <NextStep
                    opCodeStep={opCodeStep}
                    opCodeSteps={opCodeSteps}
                    setOpCodeSteps={setOpCodeSteps}
                    stepIndex={stepIndex}
                    />
                </div>
            </div>
        </div>

    </div>
    )
}

export default StepLoop
import { Label } from "components/ui/label";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import React, {useEffect, useState } from 'react'
import StepNonLLMAPI2 from './StepNonLLMAPI2';
import NextStep from '../NextStep';
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';
import DynamicStaticInputBuilder from '../DynamicStaticInputBuilder';
import InputValidation from 'components/InputValidation';

const StepNonLLMAPI = ({opCodeStep, stepIndex, opCodeSteps, setOpCodeSteps, functionRegistry, apis, nested}) => {
    const [selectedFunctionProps, setSelectedFunctionProps] = useState({});
    const output = Object.keys(opCodeStep?.output)[0]

    useEffect(() => {
            if(opCodeStep.registry_key === "RAG_API" ||  opCodeStep.registry_key === "KNOWLEDGE_RETRIEVAL_API"){
                if(functionRegistry["RAG_API"]?.properties){
                    handleSorting(functionRegistry["RAG_API"]?.properties || {})
                }
            }
    }, [stepIndex, functionRegistry])

    const handleSorting = (obj) => {
        if(obj){
            const keyOrder = ["collection_id", "search_type", "use_rerank"];
    
            // Separate the fixed keys and the rest
            const sortedObj = Object.fromEntries(
                [...keyOrder, ...Object.keys(obj).filter(k => !keyOrder.includes(k))]
                .map(key => [key, obj[key]])
            );
    
            setSelectedFunctionProps(sortedObj || {});
        }
    }

    const handleInputChange = (field, value) => {
        const newOpCodeSteps = [...opCodeSteps];
        if (field === "registry_key") {
            newOpCodeSteps[stepIndex].registry_key = value;
            handleSorting(functionRegistry[value]?.properties || {});
        } else 
        if (field === "output") {
            // console.log(value)
            newOpCodeSteps[stepIndex].output = {[value.trim()]:"compiled_text"};
        } else if (selectedFunctionProps[field] === "fixed") {
            if (field === "top_k" || field === "top_n" || field === "offset") {
                newOpCodeSteps[stepIndex].input[field] = parseInt(value) || 0;
            } else if (field === "use_rerank") {
                newOpCodeSteps[stepIndex].input[field] = value === "true";
            } else {
                newOpCodeSteps[stepIndex].input[field] = field === "collection_id"? value?._id : value;
            }
        } else if (selectedFunctionProps[field] === "dynamic") {
            newOpCodeSteps[stepIndex].input[field] = value;
        }
        setOpCodeSteps(newOpCodeSteps);
    };
    
    return (
        <div className={`grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 items-center gap-4 w-full`}>
           
            <div>
                <Label htmlFor={`functionName-${stepIndex}`}>API Name</Label>
                <Select
                    onValueChange={(value) => handleInputChange("registry_key", value)}
                    value={opCodeStep?.registry_key || ""}
                >
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select an API" />
                    </SelectTrigger>
                    <SelectContent>
                        {apis.map((funcName) => (
                            <SelectItem key={funcName} value={funcName}>
                                {funcName}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <StepNonLLMAPI2
            opCodeStep={opCodeStep}
            stepIndex={stepIndex}
            handleInputChange={handleInputChange}
            selectedFunctionProps={selectedFunctionProps}
            />  
 
            {opCodeStep?.registry_key && (
                <>
                    <DynamicStaticInputBuilder 
                    previousOutputs={handler.getPreviousOutputs(opCodeSteps, stepIndex)} 
                    onCombinedResultChange={(result) => handleInputChange("query_text", result) }
                    opCodeStep={opCodeStep}
                    inputText={opCodeStep?.input.query_text}
                    opCodeSteps={opCodeSteps}
                    setOpCodeSteps={setOpCodeSteps}
                    stepIndex={stepIndex}
                    nested={nested}
                    />

                    <div >
                        <Label htmlFor={`output-${stepIndex}`}>Step Output key</Label>
                        <InputValidation
                            id={`output-${stepIndex}`}
                            value={ Object.keys(opCodeStep?.output)[0]  || ""}
                            onChange={(e) => handleInputChange("output", e.target.value)}
                            className="bg-white"
                            validation={handler.validateOutputName}
                            disabled={handler.isOutputUsedInLaterSteps(opCodeSteps, output, stepIndex)}
                        />
                    </div>
                </>
            )}

            <div>
                <NextStep
                opCodeStep={opCodeStep}
                opCodeSteps={opCodeSteps}
                setOpCodeSteps={setOpCodeSteps}
                stepIndex={stepIndex}
                />
            </div>
        </div>   
    )
}

export default StepNonLLMAPI
import React from 'react'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { convertUnixTimestampToDate } from 'utils/timeUtils';
import { httpRequest } from 'utils/httpsRequest';

export default function SubscriptionDetails({ subscription }) {

    const createdDate = convertUnixTimestampToDate(subscription.created)
    const endDate = convertUnixTimestampToDate(subscription.current_period_end)

    const handleBillingPortal = async () => {
        try {
            const response = await httpRequest.post('/db/subscriptions/create-billing-portal-session', {
                customerId: subscription.customer,
                returnUrl: window.location.href,
            });
        
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Failed to create billing portal session:', error.message);
        }
    };

    return (
        <div className='flex flex-grow flex-col'>
            <Card className="w-full space-y-8 p-4">
                <CardHeader>
                    <CardTitle> Current Plan </CardTitle>
                    <CardDescription> {subscription?.cancel_at_period_end && `This subscription is cancelled and it will end at ${convertUnixTimestampToDate(subscription?.cancel_at)}`} </CardDescription>
                </CardHeader>
                <CardContent>
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center'>
                            <Label className='text-gray-500 min-w-[150px]'> ID </Label>
                            <span className='font-[500]'>{subscription.id}</span>
                        </div>
                        {subscription.items.data.map((item) => (
                            <div key={item.id} className='flex flex-col gap-3'>
                                <div className='flex items-center'>
                                    <Label className='text-gray-500 min-w-[150px] mb-3'> Plan </Label>
                                    <div>
                                        <span className='font-[500]'>{item.price.product.name}</span>
                                        <p className='text-gray-700'> {item.price.product.description}</p>
                                    </div>
                                </div>
                                <div className='flex items-center'>
                                    <Label className='text-gray-500 min-w-[150px]'> Price </Label>
                                    <span className='font-[500]'>{item.price.unit_amount / 100} {item.price.currency.toUpperCase()}</span>
                                </div>
                            </div>
                        ))}
                        <div className='flex items-center'>
                            <Label className='text-gray-500 min-w-[150px]'> Created </Label>
                            <span className='font-[500]'>{createdDate}</span>
                        </div>
                        <div className='flex items-center'>
                            <Label className='text-gray-500 min-w-[150px]'> Current period </Label>
                            <span className='font-[500]'>{endDate}</span>
                        </div>
                    </div>
                </CardContent>
                <CardFooter>
                    <Button onClick={handleBillingPortal} className='w-full'> Manage Plan </Button>
                </CardFooter>
            </Card>
        </div>
    )
}


import { bexHttpClient } from "api/clients";

export const onResponseLogging = async (response) => {
    // console.log(response)
    try {
        let fullUrl = "";

        if (response?.config?.url?.startsWith("ws://") || response?.config?.url?.startsWith("wss://")) {
            fullUrl = response?.config?.url; 
        } else {
            fullUrl = new URL(response?.config?.url || "", response?.config?.baseURL || "").toString();
        }
        const logPayload = {
            originalUrl: fullUrl || "",
            method: response?.config?.method || "",
            query: response?.config?.params || {},
            statusCode: response?.status || 200,
            responseMessage: response?.statusText || "",
        };
        // console.log("this is success", logPayload)

        await bexHttpClient.post("/db/activity/create", logPayload);
        
    } catch (error) {
        console.error("Failed to log response activity", error);
    } finally {
        return response;
    }
};

export const onResponseErrorLogging = async (error) => {
    try {
        let fullUrl = "";
        // console.log(error)
        if (error?.config?.url?.startsWith("ws://") || error?.config?.url?.startsWith("wss://")) {
            fullUrl = error?.config?.url; 
        } else {
            fullUrl = new URL(error?.config?.url || "", error?.config?.baseURL || "").toString();
        }
        const logPayload = {
            originalUrl: fullUrl || "",
            method: error?.config?.method || "",
            query: error?.config?.params || {},
            statusCode: error?.response?.status || 500,
            responseMessage: error?.message || "Unknown error",
        };
        // console.log("this is error", logPayload)

        await bexHttpClient.post("/db/activity/create", logPayload);

    } catch (loggingError) {
        console.error("Failed to log error response activity", loggingError);
    } finally {
        return Promise.reject(error);
    }
};

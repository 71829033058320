import { createContext, useContext, useEffect, useState } from "react";
import { AppLoader } from "components/LoaderSpinner";
import { organizationService, subscriptionService } from "api";
import { useParams } from "react-router-dom";
import { Context } from "./GlobalState";

export const OrganizationContext = createContext({});

export const OrganizationProvider = ({ children }) => {
    
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const { oragID } = useParams();
    const { actions: globalAction, organizations } = useContext(Context);

    const getOrgData = async  () => {
        setLoading(true)
        setSubscription(null)
        try {
            // fetch sub organizations if not cached in organizations state
            let subOrganizations = [];
            if (organizations.find(org => org._id === oragID).subOrganizations) {
                subOrganizations = organizations.find(org => org._id === oragID).subOrganizations;
            } else {
                const results = await organizationService.getSubOrganizations(oragID);
                globalAction({type: 'SET_ORGANIZATIONS', payload: (prev => prev.map(org => org._id === oragID ? {...org, subOrganizations: results.data} : org))});
                subOrganizations = results.data;
            }
            setSelectedOrganization(prev => ({...prev, subOrganizations: subOrganizations}));

            const subscriptionResponse = await subscriptionService.getOrganizationSubscription(oragID);
            setSubscription(subscriptionResponse.data);
        } catch (error) {
            console.log('error getting organization subscription: ', error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getOrgData()
    }, [oragID])

    // Actions
    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
            case "SET_LOADING":
                return setLoading(payload);
            case "SET_SELECTED_ORGANIZATION":
                return setSelectedOrganization(payload);
            default:
                return loading;
        }
    };

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <OrganizationContext.Provider
        value={{
            actions,
            loading,
            selectedOrganization,
            subscription
        }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};

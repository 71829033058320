import { useContext, useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Badge } from "components/ui/badge"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { ScrollArea } from "components/ui/scroll-area"
import { CalendarIcon, UsersIcon, BuildingIcon } from "lucide-react"
import { Link } from 'react-router-dom'
import { OrgInfoContext } from './OrganizationContext'
import { AppLoader } from 'components/LoaderSpinner'
import AdminOrganizationCluster from './AdminOrgCluster'
import SubOrgResourceDetail, { ModelProviders } from './SubOrganizationResource'
import ResourceUsage from './ResourceUsage'


export default function OrganizationDetails() {
    const [activeTab, setActiveTab] = useState("info")

    const { organizationData, loading, error } = useContext(OrgInfoContext)
    
    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    if (error) {
        return <h1>error: {error}</h1>
    }

    return (
        <div className="container mx-auto p-4">
            <Card className="mb-6">
                <CardHeader>
                    <CardTitle className="text-2xl flex justify-between items-center">
                        <span>{organizationData.name}</span>
                        <Badge variant="outline">{organizationData.id}</Badge>
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="flex items-center space-x-2">
                            <CalendarIcon className="h-5 w-5 text-muted-foreground" />
                            <span>Created: {new Date(organizationData.created).toLocaleDateString()}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <UsersIcon className="h-5 w-5 text-muted-foreground" />
                            <span>Members: {organizationData.members?.length}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <BuildingIcon className="h-5 w-5 text-muted-foreground" />
                            <span>Sub-orgs: {organizationData.subOrganizations?.length}</span>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="grid w-full grid-cols-5">
                    <TabsTrigger value="info">Basic Info</TabsTrigger>
                    <TabsTrigger value="members">Members</TabsTrigger>
                    <TabsTrigger value="suborgs">Sub-organizations</TabsTrigger>
                    <TabsTrigger value="subscription">Subscription</TabsTrigger>
                    <TabsTrigger value="resources">Resources</TabsTrigger>
                </TabsList>

                <TabsContent value="info">
                    <Card>
                        <CardHeader>
                            <CardTitle>Organization Information</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <strong>MongoDB Project:</strong> {organizationData.mongoProjectName}
                                </div>
                                <div>
                                    <strong>COS Name:</strong> {organizationData.cosName}
                                </div>
                                <div>
                                    <strong>COS ID:</strong> {organizationData.cosId || 'N/A'}
                                </div>
                                <div>
                                    <strong>COS Demo Mode:</strong> {organizationData.cosDemoModeName}
                                </div>
                                <div>
                                    <strong>Main MongoDB:</strong> {organizationData.mainMongoDB}
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* organization cluster */}
                    <div className='mt-4'>
                        <AdminOrganizationCluster selectedOrganization={organizationData} />
                    </div>
                </TabsContent>

                <TabsContent value="members">
                    <Card>
                        <CardHeader>
                            <CardTitle>Members ({organizationData.members?.length})</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <ScrollArea className="h-[400px]">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Name</TableHead>
                                            <TableHead>Email</TableHead>
                                            <TableHead>Role</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {organizationData.members?.map((member) => (
                                            <TableRow key={member.id}>
                                                <TableCell>{member.name}</TableCell>
                                                <TableCell>{member.email}</TableCell>
                                                <TableCell>
                                                    <Badge variant={member.role === 'superAdmin' ? "default" : member.role === 'admin' ? "secondary" : "outline"}>
                                                        {member.role}
                                                    </Badge>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </TabsContent>


                <TabsContent value="subscription">
                    <Card>
                        <CardHeader>
                            <CardTitle>Subscription Details</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-2">
                                <div>Is Trial Used: {organizationData.isTrialUsed ? 'Yes' : 'No'}</div>
                                <div>Status: {organizationData.subscription?.stripeSubscription?.status}</div>
                                <div>Plan: {organizationData.subscription?.stripeSubscription?.plan.nickname || "N/A"}</div>
                                <div>Amount: ${organizationData.subscription?.stripeSubscription?.plan.amount / 100} / year</div>
                                <div>Current Period End: {new Date(organizationData.subscription?.stripeSubscription?.current_period_end * 1000).toLocaleDateString()}</div>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="resources">
                    <div className='flex flex-col gap-4'>
                        <SubOrgResourceDetail organizationData={organizationData} />
                        <ModelProviders organizationData={organizationData} />
                        <ResourceUsage organizationData={organizationData} />
                    </div>
                </TabsContent>

                <TabsContent value="suborgs">
                    <Card>
                        <CardHeader>
                            <CardTitle>Sub-organizations ({organizationData.subOrganizations?.length})</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <ScrollArea className="h-[400px]">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Name</TableHead>
                                            <TableHead>Members</TableHead>
                                            <TableHead>Created</TableHead>
                                            <TableHead>Status</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {organizationData.subOrganizations?.map((subOrg) => (
                                            <TableRow key={subOrg._id}>
                                                <TableCell>
                                                    <Link to={`sub/${subOrg._id}`}> {subOrg.name} </Link>
                                                </TableCell>
                                                
                                                <TableCell>{subOrg.members.length}</TableCell>
                                                <TableCell>{new Date(subOrg.created).toLocaleDateString()}</TableCell>
                                                <TableCell>
                                                    <Badge variant={subOrg.isMain ? "default" : "secondary"}>
                                                        {subOrg.isMain ? "Main" : "Sub"}
                                                    </Badge>
                                                    {subOrg.isDeleted && (
                                                        <Badge variant="destructive" className="ml-2">
                                                            Deleted
                                                        </Badge>
                                                    )}
                                                    {subOrg.isTrialUsed && (
                                                        <Badge variant="outline" className="ml-2">
                                                            Trial Used
                                                        </Badge>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
        </div>
    )
}
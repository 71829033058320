import React, { useEffect, useState, useContext } from 'react';
import { Button } from "components/ui/button";
import { DialogFooter } from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from "context/GlobalState";
import { channelService, opcodeAssistantService } from "api";
import { NewCombobox } from 'components/ui/combobox';
import { FaCheck, FaCopy } from "react-icons/fa";

export const WhatsAppChannelInput = ({ newChannelObj = {}, onSubmit, setOpenEmailModal, isUpdate=false}) => {
    const FEO_CALLBACK_URL = `${process.env.REACT_APP_FEO_SERVER_URL}/feo-v3/api/twilio/send`;

    const [btnLoading, setBtnLoading] = useState(false);
    const { oragID, subOragID } = useParams();

    const [channelData, setChannelData] = useState({
        type: 'Whatsapp',
        address: 'whatsapp:+',
        topic: '',
        assistantId: '',
        ...newChannelObj, // Merge initial data
    });

    const [assistants, setAssistants] = useState([{ value: '', name: 'None' }]);
    const [assistantsLoading, setAssistantsLoading] = useState(true);
    const [copied, setCopied] = useState(false);

    const handleChange = (value, type) => {
        setChannelData(prev => ({ ...prev, [type]: value }));
    };

    const handleNumberChange = (e) => {
        setChannelData(prev => ({ ...prev, address: `whatsapp:+${e.target.value}` }));
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(FEO_CALLBACK_URL).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const isButtonDisabled = () => {
        return channelData.topic.trim() === '' || channelData.address.trim() === '' || channelData.address.trim() === 'whatsapp:+';
    };

    useEffect(() => {
        setAssistantsLoading(true);
        opcodeAssistantService.listOpcodeAssistants(oragID, subOragID)
            .then((res) => {
                setAssistants(prev => [...prev, ...res.data.map(item => ({ name: item.title, value: item._id }))]);
            })
            .catch((error) => console.log('Error fetching assistants:', error))
            .finally(() => setAssistantsLoading(false));
    }, [oragID, subOragID]);

    const handleSubmit = () => {
        setBtnLoading(true);
        onSubmit(channelData).finally(() => setBtnLoading(false));
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <Label>WhatsApp Number <span className="text-red-500">*</span></Label>
                <div className="flex items-center border rounded-md bg-gray-100">
                    <span className="px-3 py-2 text-gray-500">whatsapp:+</span>
                    <Input
                        disabled={isUpdate}
                        className="flex-1 border-none bg-white"
                        value={channelData.address.replace('whatsapp:+', '').replace('Whatsapp:+', '')}
                        onChange={handleNumberChange}
                        placeholder="1234567890"
                        type="tel"
                    />
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <Label>Topic <span className="text-red-500">*</span></Label>
                <Input
                    value={channelData.topic}
                    onChange={(e) => handleChange(e.target.value, 'topic')}
                    placeholder="Customer Support, Sales, etc."
                />
            </div>

            <div className="flex flex-col gap-2">
                <Label>Assistant <span className="text-red-500">*</span></Label>
                <NewCombobox
                    defaultDisplayed="Select Assistant to connect to"
                    items={assistants}
                    setter={(value) => handleChange(value, 'assistantId')}
                    selectedValue={channelData.assistantId}
                    disabled={assistantsLoading}
                    comboboxWidth="w-full"
                />
            </div>

            <div className="flex flex-col gap-2">
                <Label>Callback URL <span className="text-gray-500">(copy this url and past it in twilio)</span></Label>
                <div className="flex items-center gap-2">
                    <Input
                        readOnly
                        className="bg-gray-100"
                        value={FEO_CALLBACK_URL}
                    />
                    <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={handleCopyClick}
                    >
                        {copied ? <FaCheck className="text-green-500" /> : <FaCopy />}
                    </Button>
                </div>
            </div>

            <DialogFooter className="mt-3">
                <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                <Button onClick={handleSubmit} disabled={btnLoading || isButtonDisabled()}> Submit </Button>
            </DialogFooter>
        </div>
    );
};

export default function WhatsAppSetup({ setOpenEmailModal, setEmailChannels }) {
    
    const navigate = useNavigate();
    const location = useLocation();
    const { addNewNotifcation } = useContext(Context);
    const { oragID, subOragID } = useParams();

    const handleOnSubmit = async (newChannelObj) => {
        try {
            newChannelObj["organizationId"] = oragID;
            newChannelObj["subOrganizationId"] = subOragID;

            const res = await channelService.addChannel(newChannelObj);

            addNewNotifcation('Channel added successfully', 'success');
            setOpenEmailModal(false);
            setEmailChannels(prev => [...prev, res.data]);
            navigate(location.pathname, { replace: true });
        } catch (error) {
            console.log('Error adding channel:', error);
            if (error.response?.status === 409) {
                addNewNotifcation('Channel already exists', 'danger');
            } else if (error.response?.status === 403) {
                addNewNotifcation(error?.response?.data?.message || 'Resource limit. You can not create more channels under your current plan', 'danger');
            } else if (error.response.status === 403) {
                addNewNotifcation('Resource limit reached. You cannot create more channels under your current plan.', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger');
            }
        }
    };

    return (
        <div>
            <WhatsAppChannelInput
                newChannelObj={{}}
                setOpenEmailModal={setOpenEmailModal}
                onSubmit={handleOnSubmit}
            />
        </div>
    );
}
import React, { useContext, useState } from 'react'
import { ScrollArea } from 'components/ui/scroll-area';
import WatsonXConfiguration from '../Common/WatsonXConfiguration';
import GroqConfiguration from '../Common/GroqConfiguration';
import Anatomy from '../Common/Anatomy';
import { Label } from 'components/ui/label';
import InputValidation from 'components/InputValidation';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';
import { Textarea } from 'components/ui/textarea';
import { Separator } from 'components/ui/separator';
import { Input } from 'components/ui/input';
import { Play } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Output } from './Output';
import { unifiedModelWSService } from 'api/services/PROJECT-O/WEBSOCKETS/UnifiedModel.websocket.service';
import { Context } from 'context/GlobalState';
import CommonModelSelectionModal from '../Common/CommonModelSelectionModal';
import SambaNovaConfiguration from '../Common/SambaNovaConfiguration';

export default function InstructionForm({ form, setForm, update=false }) {

    const [output, setOutput] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [inputData, setInputData] = useState('')
    
    const { addNewNotifcation } = useContext(Context)

    const handleParameter = (data) => {
        if (form.model_type === 'IBM') {
            setForm(prev => ({...prev, watsonParameters: data}))
        } else if (form.model_type === 'Groq')  {
            setForm(prev => ({...prev, groqParameters: data}))
        } else if (form.model_type === 'SambaNova')  {
            setForm(prev => ({...prev, sambaNovaParameters: data}))
        }
    }

    const selectedModel = (data) => {
        setForm(prev => ({...prev, selectedModel: data.modelName, model_type: data.modelType}))
    }

    const handleSendPrompt = async (e) => {
        e.preventDefault()
    
        if (!form.selectedModel) {
            return addNewNotifcation("Please select a foundation model.", "warning");
        } else if (!form.instruction.trim()) {
            return addNewNotifcation("Please enter an instruction.", "warning");
        }

        try {
            setBtnLoading(true)
            setOutput("")
            
            let params = form?.watsonParameters;
            if (form.model_type === 'IBM') {
                if (form.watsonParameters.mode === "greedy") {
                    params = Object.fromEntries(
                        Object.entries(form.watsonParameters).filter(([key]) => key === "max_new_tokens" || key === "mode" || key === "stop_sequences")
                    );
                }
            }
            
            const body = {
                "modelId": form.selectedModel,
                "prompt": labHelper.ConstructPromptUnified({
                    "instruction": form.instruction.trim(),
                    "context": form.context.trim(),
                    "target_format": form.targetFormat.trim(),
                    "sample_input": form.sampleInput.trim(),
                    "sample_output": form.sampleOutput.trim(),
                }),
                "parameters": form.model_type === 'IBM' ? params : form.groqParameters,
                "query": inputData.trim()
            }
            
            // console.log("Sent Prompt\n", body.prompt);
            const res = await unifiedModelWSService.experiment(body, form.model_type);
            if (typeof res === "object" && res.model_type && res.result.prediction) {
                setOutput(res.result.prediction);
            } else {
                throw new Error(res);
            }
        } catch (error) {
            if (typeof error === "string" && error.includes("rate_limit_exceeded")) {
                addNewNotifcation("Model rate limit exceeded.", "warning");
            } else if (typeof error === "object" && error?.websocket_error) {
                addNewNotifcation("Error generating response.", "danger");
            } else {
                addNewNotifcation("Error generating response.", "danger");
            }
            console.log('Experiment Error: ', error);
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <div>
            <div className='flex gap-2'>
                <div className="w-full">
                    <CommonModelSelectionModal
                    finalModel={{modelName: form.selectedModel, modelType: form.model_type}}
                    onModelSelect={selectedModel}
                    />
                </div>
                {form.model_type === 'IBM' ? (
                    <WatsonXConfiguration
                    parametersWatsonX={form.watsonParameters} 
                    setParametersWatsonX={handleParameter} 
                    selectedModel={form.selectedModel}
                    />
                ) : form.model_type === 'Groq' ? (
                    <GroqConfiguration
                    parametersGroq={form.groqParameters} 
                    setParametersGroq={handleParameter} 
                    />
                ) : form.model_type === 'SambaNova' ? (
                    <SambaNovaConfiguration
                    parametersSambaNova={form.sambaNovaParameters} 
                    setParametersSambaNova={handleParameter}
                    />
                ) : ''}
            </div>

            <Separator className='my-3' />

            <ScrollArea className="max-h-[360px]">
                <div className="space-y-2 px-1 pt-4">
                    <div>
                        <Label htmlFor="name" className="text-right">
                            Unique Name
                        </Label>
                        <InputValidation
                            id="name"
                            value={form.unique_name}
                            disabled={update}
                            onChange={(e) => setForm(prev => ({...prev, unique_name: e.target.value}))}
                            className="col-span-3"
                            placeholder="Enter a unique name for your instruction"
                            validation={labHelper.validateUniqueName}
                        />  
                    </div>
                    <div>
                        <Label htmlFor="description" className="text-right">
                            Description
                        </Label>
                        <Textarea
                            id="description"
                            value={form.modelDescription}
                            onChange={(e) => setForm(prev => ({...prev, modelDescription: e.target.value}))}
                            className="col-span-3"
                            placeholder="Enter description for your instruction"
                        />
                    </div>
                </div>
                <Anatomy instructionForm={form} setInstructionForm={setForm} isExecute={false} />

                <Separator />

                <div className='mt-3 px-2'>
                    <div className='space-y-2'>
                        <Label> Input data </Label>
                        <form onSubmit={handleSendPrompt} className='flex flex-row gap-2'>
                            <Input value={inputData} onChange={(e) => setInputData(e.target.value)} placeholder='input data' />
                            <Button type='submit' disabled={btnLoading} onSubmit={handleSendPrompt} className='px-1' size='icon'>
                                <Play size={16} className='' />
                            </Button>
                        </form>
                    </div>

                    <div className='space-y-2 mt-3'>
                        <Output output={output} />
                    </div>
                </div>
            </ScrollArea>
        </div>
    )
}
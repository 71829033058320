import './App.css';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Profile from './pages/Settings/Profile'
import NotFound from './pages/NotFound'
import Organization from './pages/Organization';
import { OrganizationInfoCard } from './pages/Settings/Organization';
import UsersSetting from './pages/Settings/Organization/Users';
import SubOrganizationSetting from './pages/Settings/Organization/SubOrganization';
import { ProtectedRoute } from './layout/ProtectedRoutes';
import { AuthRoute } from './layout/AuthRoutes';
import { ProtectedOrgRoute } from './layout/ProtectedOrgRoutes';
import { ProtectedSubOrgRoute } from './layout/ProtectedSubOrgRoute';
import TextToSpeech from './pages/TextToSpeech';
import PromptingAndRAGTraining from './pages/PromptingAndRagTraining';
import VerifyEmail from './pages/VerifyEmail';
import ResetPassword from './pages/ResetPassword';
import Secure from './pages/Secure';
import SpeechToText from './pages/SpeechToText';
import ChangePassword from './pages/ChangePassword';
import { Layout } from './layout/Wrapper';
import SideMenuWrapper from './layout/SideMenuWrapper';
import mainSectionMenu from './data/mainSectionMenu';
import orgSectionMenu from './data/orgSectionMenu';
import AssistantDashboard from './pages/Dashboard/Assistant';
import SubOrganizationSettings from './pages/Dashboard/SubOrganization/Settings';
import Sandbox from './pages/Sandbox';
import DemoModeX from './pages/LegalContract/components/ConversationPage/conversationPagev3';
import EmailHandlingDemo from './pages/EmailHandling/EmailHandlingDemo';
import WebDemoMode from 'pages/WebEngagement/Components/WebDemoMode';
import DemoModeHR from './pages/HrAssistant/Components/DemoModeHR';
import HotelOperationsDemo from './pages/HotelOperations';
import OpCodes from 'pages/Dashboard/SubOrganization/OpCodes';
import Channels from 'pages/Dashboard/SubOrganization/Channels';
import Assistants from 'pages/Dashboard/SubOrganization/Assistants';
import { SubOrganizationProvider } from 'context/SubOrganizationContext';
import { OrganizationProvider } from 'context/OrganizationContext';
import KnowledgeBuilder from 'pages/Dashboard/SubOrganization/Collection/KnowledgeBuilder';
import RedirectUser from 'pages/RedirectUser';
import FileManagerDocument from 'pages/Dashboard/SubOrganization/Collection/Documents/FileManagerDocument';
import OrganizationConfig from 'pages/Settings/Configurations';
import { FileManagerProvider } from 'context/FileManagerContext';
import AssistantsGallery from 'pages/AssistantsGallery';
import { ActiveAssistant } from 'pages/Dashboard/EmailExtraction';
import OpCodeBuilderViewAndExecute from 'pages/Dashboard/SubOrganization/OpCodes/OpcodeBuider/ViewAndExecute/OpCodeBuilderViewAndExecute';
import OpCodeWrapper from 'pages/Dashboard/SubOrganization/OpCodes/OpCodeWrapper';
import LivePrompt from 'pages/PromptingAndRagTraining/components/LivePrompt';
import Instructions from 'pages/PromptingAndRagTraining/components/Instructions';
import CodeSandbox from 'pages/PromptingAndRagTraining/components/CodeSandbox';
import AITeacher from 'pages/PromptingAndRagTraining/components/AITeacher';
import TuningExperiment from 'pages/PromptingAndRagTraining/components/TuningLab';
import ChannelDetails from 'pages/Dashboard/SubOrganization/Channels/ChannelDetails';
import InstructionDetails from 'pages/PromptingAndRagTraining/components/Instructions/InstructionDetails';
import AiLabWrapper from 'pages/PromptingAndRagTraining/context/AiLabWrapper';

function App() {

    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/auth/login'} />} />
            <Route element={<Layout />}>
                <Route element={<AuthRoute />}>
                    <Route path="/auth">
                        <Route index path='login' element={<Login />} />
                        <Route path='signup' element={<Signup />} />
                        <Route path='reset-password' element={<ResetPassword />} />
                    </Route>
                </Route>
            </Route>
            <Route path="/auth/change-password/:token" element={<ChangePassword/>} />
            
            <Route element={<Layout />}>
                
                <Route element={<ProtectedRoute />}>
                    {/* used to redirect user on login */}
                    <Route path='redirect' element={<RedirectUser />} />

                    <Route element={<SideMenuWrapper section={mainSectionMenu} />}>
                        <Route path='profile' element={<Profile />} />
                        <Route path='organizations' element={<Organization />} />
                    </Route>

                    <Route element={<ProtectedRoute />}>
                        <Route path='organizations/:oragID'>
                            <Route element={
                                <OrganizationProvider>
                                    <ProtectedOrgRoute />
                                </OrganizationProvider>
                            }>
                                <Route element={<SideMenuWrapper section={orgSectionMenu} />}>
                                    <Route index element={<Navigate to="sub" replace />} />
                                    <Route path='sub' element={<SubOrganizationSetting />} />
                                    <Route path='members' element={<UsersSetting />} />
                                    <Route path='configurations' element={<OrganizationConfig />} />
                                    <Route path='settings' element={<OrganizationInfoCard />} />
                                </Route>
									
                                <Route path='sub/:subOragID'>
                                    <Route element={
                                        <SubOrganizationProvider>
                                            <ProtectedSubOrgRoute />
                                        </SubOrganizationProvider>
                                    }>
                                        <Route index element={<Navigate to="ai-lab" replace />} />
                                        <Route path='bex-assistants'>
                                            <Route index element={<AssistantsGallery />} />
                                            <Route path=':assistantId' element={<AssistantDashboard />} />
                                            <Route path={"legal-demo"} element={<DemoModeX />} />
                                            <Route path={"email-handling-demo"} element={<EmailHandlingDemo />} />
                                            <Route path={"web-demo"} element={<WebDemoMode />} />
                                            <Route path="text-to-speech" element={<TextToSpeech />} />
                                            <Route path="speech-to-text" element={<SpeechToText />} />
                                            <Route path={"hr-demo"} element={<DemoModeHR />} />
                                            <Route index path={"hotel-op-demo"} element={<HotelOperationsDemo />} />
                                        </Route>
                                        <Route path="assistants">
                                            <Route index element={<Assistants />} />
                                            <Route path=':assistantId' element={<ActiveAssistant />} />
                                            <Route path={"email-handling-demo"} element={<EmailHandlingDemo />} />
                                        </Route>
                                        <Route path='knowledge-builder'>
                                            <Route index element={<KnowledgeBuilder/>} />
                                            <Route path=':collectionId'>
                                                <Route index element={<Navigate to=".." replace />} />
                                                <Route path=':fileName' element={
                                                    <FileManagerProvider>
                                                        <FileManagerDocument />
                                                    </FileManagerProvider>
                                                }></Route>
                                            </Route>
                                        </Route>
                                        
                                        <Route path='op-codes' element={<OpCodeWrapper />} >
                                            <Route index element={<OpCodes />} />
                                            <Route path=':opCodeName' element={ <OpCodeBuilderViewAndExecute />}/>
                                        </Route>
                                        <Route path='channels'>
                                            <Route index element={<Channels />} />
                                            <Route path=':channelId' element={<ChannelDetails />} />
                                        </Route>
                                        <Route path='ai-lab' element={<AiLabWrapper/>}>
                                            <Route index element={<PromptingAndRAGTraining />} />
                                            <Route path='instructions'>
                                                <Route index element={<Instructions />} />
                                                <Route path=':uniqueName/:modelType' element={<InstructionDetails/>}></Route>
                                            </Route>
                                            <Route path="prompt-lab" element={<LivePrompt />} />
                                            <Route path="code-sandbox" element={
                                                <CodeSandbox />
                                            } />
                                            <Route path="ai-teacher" element={
                                                <AITeacher />
                                            } />
                                            <Route path="tuning-lab" element={<TuningExperiment />} />
                                        </Route>
                                        <Route path='settings' element={<SubOrganizationSettings />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path='verify-email' element={<VerifyEmail />} />
                <Route path="/sandbox" element={<Sandbox />} />

                {/* routes for admin user */}
                <Route path="/secure/*" element={<Secure />} />

                <Route path="*" element={<NotFound />} />
            </Route>

        </Routes>
    );
}

export default App;
import { AiTeacherData } from "../initialData";

export const AiTeacherReducer = (state, action) => {
  switch (action.type) {
    case "AI_TEACHER_SET_OPCODES":
      return {
        ...state,
        aiTeacherOpcodes: action.value,
      };
    case "AI_TEACHER_ADD_MESSAGE":
      return {
        ...state,
        aiTeacherMessages: [
          ...state.aiTeacherMessages,
          action.value,
        ],
      };
    case "AI_TEACHER_CHANGE_MODE":
      return {
        ...state,
        mode: action.value,
      };
    case "AI_TEACHER_SET_FILE_SYSTEM":
      return {
        ...state,
        fileSystem: action.value,
      };
    case "AI_TEACHER_RESET_FILE_SYSTEM":
      return {
        ...state,
        fileSystem: AiTeacherData.fileSystem,
      };
    case "AI_TEACHER_SET_DOCUMENT":
      return {
        ...state,
        selectedDocument: action.value,
      };
    case "AI_TEACHER_ADD_OPENED_DOCUMENT":
      return {
        ...state,
        openedDocuments: [
          ...state.openedDocuments,
          action.value,
        ],
      };
      case "AI_TEACHER_REMOVE_OPENED_DOCUMENT":
        return {
          ...state,
          openedDocuments: state.openedDocuments.filter(doc => doc.id !== action.value),
        };
    case "AI_TEACHER_SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.value,
      };
    case "AI_TEACHER_RESET_MESSAGES":
      return { 
        ...state, 
        aiTeacherMessages: AiTeacherData.aiTeacherMessages
      };
    case "AI_TEACHER_UPDATE_RAG_FIELD":
      return { 
        ...state, 
        ragForm: {
          ...state.ragForm,
          [action.field]: action.value
        }
      };
    case "AI_TEACHER_SET_LOADING":
      return { 
        ...state, 
        isAiTeacherMessageLoading: action.value,
      };

    default:
      return state;
  }
};

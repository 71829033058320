// ================================ Prompt Lab (START) ================================
const form = {
    unique_name: "",
    model_type: "IBM",
    description: "",
    selectedModel: "",
    instruction: "",
    context: "",
    targetFormat: "",
    sampleInput: "",
    sampleOutput: "",
    inputData: "",
    region: "",
    selectedImages: [],
    groqParameters: {
      max_tokens: 200,
      n: 1,
      top_p: 1,
      stop: ["<|endoftext|>", "<|endofcode|>"],
      temperature: 0,
      frequency_penalty: 1.2,
      presence_penalty: 0,
      response_format: { type: "text" },
    },
    watsonParameters: {
      decoding_method: "greedy",
      temperature: 0.7,
      min_new_tokens: 10,
      max_new_tokens: 200,
      top_p: 1,
      top_k: 50,
      repetition_penalty: 1.0,
      stop_sequence: ["<|endoftext|>", "<|endofcode|>"],
    },
    sambaNovaParameters: {
      max_tokens: 200,
      top_p: 1,
      top_k: 50,
      stop: ["<|endoftext|>", "<|endofcode|>"],
      temperature: 0,
    },

};
const savedInstructions = [];

const PromptLabData = {
  form,
  savedInstructions,
};
// ================================ Prompt Lab (END) ================================

// ================================ AI Teacher(START) ================================
const aiTeacherMessages = [
  {
    role: 'assistant',
    content: "Hi! I am AI Teacher. I am here to help you go through your course materials.",
    ragForm: null
  },
];

const ragForm = {
  "organization_id": "",
  "sub_organization_id": "",
  "collection_id": "",
  "query_text": "",
  "top_k": 10,
  "offset": 0,
  "top_n": 3,
  "use_rerank": false,
  "filters": {},
  "search_type": "hybrid"
}

const selectedDocument = null; // selected document
const openedDocuments = []; //opened documents
const currentPage = 1; // selected document's current page
const mode = "teacher"

const AI_TEACHER_ASST_ID = '67a36c5fdd1ad198ab37831d';
const COMMON_ORG = '64f9f31ab8f2b3a65cbfc48d';
const COMMON_SUBORG = '676187d7f757f3a63a31a24b';
const aiTeacherOpcodes = {
  "teacher": {
    "name": "AI Teacher Mode",
    "description": "Receive guided explanations and answers.",
    "opcode": null
  },
  "quiz": {
    "name": "Quiz Mode",
    "description": "Generate questions to test your knowledge.",
    "opcode": null
  }
};
const isAiTeacherMessageLoading = false;
const fileSystem = [];

const AiTeacherData = {
  AI_TEACHER_ASST_ID,
  COMMON_ORG,
  COMMON_SUBORG,
  aiTeacherOpcodes,
  aiTeacherMessages,
  ragForm,
  selectedDocument,
  openedDocuments,
  currentPage,
  mode,
  isAiTeacherMessageLoading,
  fileSystem
};
// ================================ AI Teacher (END) ================================

// ================================ Code Sandox (START) ================================

const codeSandboxMessages = [
  {
    role: 'assistant',
    content: "Hi! Welcome to Code Sandbox. Let me know if there is something I can help you with regarding code generation.",
    collection: ""
  },
];

const CODE_SANDBOX_ASST_ID = 'CodeGenAssistant';
const CODE_SANDBOX_COMMON_ORG = '64f9f31ab8f2b3a65cbfc48d';
const CODE_SANDBOX_COMMON_SUBORG = '6639b5b112bba60113cde5c0';
const isCodeSandboxMessageLoading = false;

const CodeSandboxData = {
  codeSandboxMessages,
  CODE_SANDBOX_ASST_ID,
  CODE_SANDBOX_COMMON_ORG,
  CODE_SANDBOX_COMMON_SUBORG,
  isCodeSandboxMessageLoading
};

// ================================ Code Sandbox (END) ================================

module.exports = {
  PromptLabData,
  AiTeacherData,
  CodeSandboxData
};



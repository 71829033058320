import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components/ui/button';
import {  Bot, Copy, MoreVertical, Settings, Trash2 } from 'lucide-react'
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { formatDateToDDMMYYY } from 'utils/timeUtils';
import { getOrganizationMember } from 'utils/organizationUtils';
import useLazyLoad from 'hooks/useLazyLoading';
import { TextSkeleton } from 'components/ui/text-skeleton';


export default function InstructionCard({ unique_name, user_id, modified_timestamp, creation_timestamp, onManage, onCopy, onDelete, model_type, selectedOrganization }) {

    const [createdByName, setCreatedByName] = useState('')

    const fetchInstructionInfo = () => {
        getOrganizationMember(user_id, selectedOrganization)
        .then((name) => {
            setCreatedByName(name || '-')
        }).catch((error) => {
            console.log('error: ', error);
        })
    }

    const navigate = useNavigate()
    const { elementRef } = useLazyLoad(fetchInstructionInfo)

    const handleInstructionSelection = () => {
        navigate(`${unique_name}/${model_type}`)
    }

    const handleStopPropagation = (e, callback) => {
        e.stopPropagation()
        if (callback) callback()
    }

    return (
        <Card ref={elementRef} className='overflow-hidden cursor-pointer' onClick={handleInstructionSelection}>
            <CardHeader>
                <div className='flex w-full justify-between items-center'>
                    <CardTitle className='leading-[auto] text-current cursor-pointer hover:underline truncate'>{unique_name}</CardTitle>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger onClick={handleStopPropagation} asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <MoreVertical className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent onClick={handleStopPropagation} align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={onManage}>
                                <Settings className="mr-2 h-4 w-4" />
                                <span>Manage</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={onCopy}>
                                <Copy className="mr-2 h-4 w-4" />
                                <span>Copy</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={onDelete} className="text-red-600">
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Delete</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent>
                <div className="flex gap-3 items-center">
                    <span className='rounded-full  min-w-16 min-h-16  flex justify-center items-center bg-[#b1092a]'>
                        <Bot size={28} color='white'/>
                    </span>
                    <ul className="flex flex-col truncate">
                        {/* <li>Purpose: </li> */}
                        <li className='text-sm text-muted-foreground'>Instruction type: <span>{model_type}</span> </li>
                        <li className='text-sm text-muted-foreground'>Created on: <span>{formatDateToDDMMYYY(creation_timestamp)}</span> </li>
                        <li className='text-sm text-muted-foreground'>Updated on: <span>{formatDateToDDMMYYY(modified_timestamp)}</span> </li>
                        <li className='text-sm text-muted-foreground truncate flex items-center gap-2'>
                            Created by:
                            <TextSkeleton lines={1} width={70} loading={createdByName === ''} className='truncate'>
                                <span> {createdByName} </span>
                            </TextSkeleton>
                        </li>
                    </ul>
                </div>
            </CardContent>
        </Card>
    )
}

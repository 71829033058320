import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { MinusCircle, Plus } from 'lucide-react'
import React, { useState } from 'react'

const OpCodeBuilderLoopFields = ({field, setLoopList}) => {
    const [collectionArr, setCollectionArr] = useState([""])

    const handleCollectionArray = (e, collectionIndex) => {
        const { value } = e.target
        const colArr = [...collectionArr]
        colArr[collectionIndex] = value
        setCollectionArr(colArr)
        setLoopList(prev => prev.map(item => item.key === field? {...item, list:colArr}: item))
	}

	const addCollectionField = (position) => {
        if (collectionArr.some(item => item.trim() === '')) {
            return; // Prevent adding if any field is empty
        }
    
        setCollectionArr((prevGroups) => {
            const newGroups = [...prevGroups]; // Create a copy of the previous array
            newGroups.splice(position, 0, ''); // Insert the new item at the specified position
            setLoopList(prev => prev.map(item => item.key === field ? { ...item, list: newGroups } : item));
            return newGroups;
        });
    };

	const removeCollectionField = (position) => {
        if (collectionArr.length > 1) {
            const newGroups = [...collectionArr]; 
            newGroups.splice(position, 1)
            setLoopList(prev => prev.map(item => item.key === field? {...item, list:newGroups}: item))
            setCollectionArr(newGroups);
        }
	}

    return (
        <div className='grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-2'>
            {collectionArr.map((coll, collIndex) => (
                <div key={collIndex} className="flex items-center space-x-1">
                    <div className="flex-grow flex items-center space-x-1 bg-white rounded-md border border-input">
                        <Input
                        id={`collection-${collIndex}`}
                        name="collection"
                        value={coll}
                        onChange={(e) => handleCollectionArray(e, collIndex)}
                        placeholder="Enter collection variable name"
                        className="border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                        />
                    </div>
                    <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => addCollectionField(collIndex + 1)}
                    className="h-9 px-2"
                    aria-label="Add field after"
                    >
                        <Plus className="h-4 w-4 ml-1" />
                    </Button>
                    {collectionArr.length > 1 && (
                        <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => removeCollectionField(collIndex)}
                            aria-label="Remove field"
                        >
                            <MinusCircle className="h-4 w-4" />
                        </Button>
                    )}
                </div>
            ))}
        </div>
    )
}

export default OpCodeBuilderLoopFields
import React, { useState, useEffect, useContext } from "react";
import {
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogContent,
  Dialog,
} from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Badge } from "../components/ui/badge";
import { ScrollArea } from "../components/ui/scroll-area";
import {
  MagnifyingGlassIcon,
  Cross1Icon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { AppLoader } from "../components/LoaderSpinner";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import { OrganizationContext } from "context/OrganizationContext";
import { organizationService } from "api";

const UpdateSubOrganizationDialog = ({
  show,
  setShow,
  subOrg,
  setSubOrg,
  addNewNotification,
}) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [members, setMembers] = useState([]);
    const [loadingMembers, setLoadingMembers] = useState(true);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const { selectedOrganization } = useContext(OrganizationContext);


    const getData = async () => {
        if (!show) return;
        try {
            const orgUsers = await organizationService.getOrganizationUsers(selectedOrganization._id);
            setMembers(orgUsers.data);
            setSelectedMembers(orgUsers.data.filter((member) => subOrg.members.includes(member._id)));
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setLoadingMembers(false);
        }
    };

    useEffect(() => {
        getData();
    }, [show, selectedOrganization, subOrg]);

    const handleUpdateSubOrganization = () => {
        setBtnLoading(true);
        organizationService.updateSubOrganizations(selectedOrganization._id, subOrg._id, { members: selectedMembers.map((member) => member._id)})
        .then((res) => {
            setSubOrg({ ...subOrg, members: res.data.members });
            setShow(false);
            addNewNotification("Sub-Organization updated successfully", "success");
        })
        .catch((error) => {
            console.log("error: ", error);
            if (error?.response?.status === 404) {
                addNewNotification("Sub-Organization not found", "danger");
            } else if (error?.response?.status === 401) {
                addNewNotification(
                    "You are not authorized to update this sub-organization",
                    "danger"
                );
            } else {
                addNewNotification("Something went wrong", "danger");
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });
    };

    const handleSelectMember = (member) => {
        if (!selectedMembers.some((m) => m._id === member._id)) {
            setSelectedMembers([...selectedMembers, member]);
        }
    };

    const handleRemoveMember = (member) => {
        setSelectedMembers(selectedMembers.filter((m) => m._id !== member._id));
    };

    const filteredMembers = members.filter(
        (member) =>
        member.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="max-h-[650px] overflow-scroll w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">
                        Update Sub Organization
                    </DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">
                        Modify the members of {subOrg.name}
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-6">
                    <div className="flex">
                        <ScrollArea className="rounded-md min-h-0 max-h-[80px]">
                            <div className="flex flex-wrap gap-2">
                                {selectedMembers.map((member) => (
                                    <Badge
                                    key={member._id}
                                    variant="secondary"
                                    className="p-2 text-sm"
                                >
                                        {member.name}
                                        <Cross1Icon
                                        className="ml-2 cursor-pointer"
                                        onClick={() => handleRemoveMember(member)}
                                        />
                                    </Badge>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                    <div className="relative space-y-3">
                        <Label htmlFor="member-search" className="text-md">
                            Member Selection
                        </Label>
                        <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                        <Input
                        id="member-search"
                        placeholder="Search members..."
                        type="search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                        <ScrollArea className="border-solid rounded-md h-56">
                            <div className="flex flex-col p-3 space-y-3">
                                {loadingMembers ? (
                                    <AppLoader size={60} />
                                ) : filteredMembers.length === 0 ? (
                                    <div className="text-center text-muted-foreground">
                                        No members found
                                    </div>
                                ) : (
                                filteredMembers.map((member) => (
                                    <div
                                    key={member._id}
                                    className="flex items-center space-x-3 cursor-pointer"
                                    onClick={() => handleSelectMember(member)}
                                    >
                                        <div>
                                            <div className="font-medium text-md">{member.name}</div>
                                            <div className="text-sm text-muted-foreground">
                                                {member.email}
                                            </div>
                                        </div>
                                        <CheckIcon
                                        className={cn(
                                        "ml-auto h-6 w-6",
                                        selectedMembers.some((m) => m._id === member._id)
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                    />
                                    </div>
                                ))
                                )}
                            </div>
                        </ScrollArea>
                    </div>
                </div>
                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button
                    variant="outline"
                    onClick={() => setShow(false)}
                    className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground"
                    >
                        Cancel
                    </Button>
                    <Button
                    onClick={handleUpdateSubOrganization}
                    className="px-5 py-3 text-md"
                    disabled={btnLoading}
                    >
                        {btnLoading ? 'Updating Sub Organization...' : "Update Sub Organization"}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateSubOrganizationDialog;

/* eslint-disable max-lines */
import React from 'react'
import OpCodeBuilderStep from './OpCodeBuilderStep'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Step } from 'components/NewDesignComponents/VerticalSteps'
import handler from './OpCodeBuilderHAndC/OpCodeBuilderHandler'

const OpCodeBuilderSteps = ({opCodeStepsState, otherProps, stepTypesState}) => {
    const {opCodeSteps, setOpCodeSteps} = opCodeStepsState
    const {stepTypes, setStepTypes} = stepTypesState
    const {constantStepTypes, opType, validateOpNameDesType, orgId=0, subOrgId=0, parentId} = otherProps 

    const handleAddStep = (position, currentStep) => {
        // console.log(`Add step ${position} ${currentStep + 1}`)
        let newOpCodeSteps = [...opCodeSteps];
        const newStepType = [...stepTypes];
        const currentIndex = Number(currentStep) 

        // Find the index of the current step  
        if (currentIndex === -1) {
            console.error("Current step not found");
            return;
        }

        const newOpCodeStep = {
            step_id: window?.crypto?.randomUUID(),
            step_type: `${opType === 'AutoOp'? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: " {{context['real_time_data']}}",  
            output: { Output: "", Output_map: {} },
            next_step: [],
            accumulate_output: true,
            is_first_step: false
        }
    
        if (position === 'before' || position === 'after') {
            const indexOffset = position === 'before' ? 0 : 1;
            const insertionIndex = currentIndex + indexOffset;

            if (position === 'before') {
                // Update the `next_step` of the new step to point to the current step
                newOpCodeStep.next_step = [newOpCodeSteps[currentIndex].step_id];
                
                // Update the previous step's `next_step` to point to the new step, if applicable
                if (currentIndex > 0 && (currentIndex - 1 !== 0)) {
                    newOpCodeSteps[currentIndex - 1].next_step = [newOpCodeStep.step_id];
                }
            } else if (position === 'after') {
                // Update the `next_step` of the new step to point to the next step of the current step
                newOpCodeStep.next_step = currentIndex !== 0? newOpCodeSteps[currentIndex]?.next_step[0]? 
                [newOpCodeSteps[currentIndex]?.next_step[0]] : [] : []
    
                // Update the `next_step` of the current step to point to the new step
                if(currentIndex !== 0 && newOpCodeSteps[currentIndex]?.step_type !== "Condition"){
                    newOpCodeSteps[currentIndex].next_step = [newOpCodeStep.step_id];
                }

                if(newOpCodeSteps[currentIndex]?.step_type === "Condition"){
                    newOpCodeSteps[currentIndex].next_step = {true_branch:[newOpCodeStep.step_id], false_branch:[newOpCodeStep.step_id]}
                }
            }

            newOpCodeSteps.splice(insertionIndex, 0, newOpCodeStep);
            newStepType.splice(insertionIndex, 0, constantStepTypes[1].name);
        } else {
            console.error("Invalid position");
        }
    
        setOpCodeSteps(newOpCodeSteps);
        setStepTypes(newStepType)
    };

    const handleStepTypeSelect = (value, index) => {
        const newType = constantStepTypes.find(item => item.name === value).name

        setStepTypes(stepTypes.map((type,i) => i === index? newType : type))

        let newOpCodeStep = {}

        const currentOpCodeStep = opCodeSteps.find((_,i) => i === index)

        const inputText = currentOpCodeStep?.step_type === "Non-LLM"? (currentOpCodeStep?.input?.query_text || "") 
        : (currentOpCodeStep?.step_type=== "Condition"? "" : currentOpCodeStep?.input || "")

        const outputVar = currentOpCodeStep?.step_type === "Non-LLM"? (Object.keys(currentOpCodeStep?.output)[0] || "") 
        : (currentOpCodeStep?.output?.Output || "")
        
        const nextStepValue = currentOpCodeStep.step_type !== "Condition"? currentOpCodeStep?.next_step || [] 
        : currentOpCodeStep?.next_step?.true_branch || []

        const llmObj = {
            step_id: "",
            step_type: `${newType === "LLM"? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: inputText,  
            output: { Output: outputVar, Output_map: {} },
            next_step: nextStepValue,
            accumulate_output: true,
            is_first_step: false
        }

        if (newType === "LLM") {
            newOpCodeStep = llmObj
        } else if (newType === "LLM-Stream") {
            newOpCodeStep = llmObj
        } else if (newType === "Non-LLM") {
            newOpCodeStep = {
                step_id: "",
                step_type: "Non-LLM",
                registry_key: "",
                input: {
                    "org_id": orgId,
                    "sub_org_id": subOrgId,
                    "collection_id": "",
                    "use_rerank": true,
                    "query_text": inputText,  
                    "top_k": 10,
                    "top_n": 3,
                    "offset": 0,
                    "filters": {},
                    "search_type": "vector",
                },
                output: { [outputVar]: "compiled_text" },
                next_step: nextStepValue,
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Condition"){
            newOpCodeStep = {
                step_id: "",
                step_type: "Condition",
                input: "",
                output: { Output: outputVar },
                next_step: {
                    true_branch: nextStepValue,
                    false_branch: nextStepValue
                },
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Loop"){
            newOpCodeStep = {
                step_id: "",
                step_type: "Loop",
                input: {
                    collection: "",
                    item: "loopItem",
                    max_iterations: "",
                    parallel:true,
                    steps: [
                        {
                            step_id: window?.crypto?.randomUUID(),
                            step_type: `${opType === 'AutoOp'? "LLM" : "LLM-Stream"}`,
                            unique_name: "",
                            model_type: "",
                            input: " {{context['real_time_data']}}",  
                            output: { Output: "", Output_map: {} },
                            next_step: [],
                            accumulate_output: true,
                            is_first_step: true
                        }
                    ],
                },
               output: { Output: outputVar},
               next_step: nextStepValue,
               accumulate_output: true,
               is_first_step: false
            }
        } else if (newType === "Memory") {
            newOpCodeStep = {
                step_id: "",
                step_type: "Memory",
                input: {
                    n:3,
                    limit_size:1000
                },
                output: { Output: outputVar },
                next_step: nextStepValue,
                accumulate_output: true,
                is_first_step: false
            }
        }

        const newOpCodeSteps = opCodeSteps.map((opCodeStep, i) => i === index? {...newOpCodeStep, step_id:opCodeStep.step_id} : opCodeStep)
        setOpCodeSteps(newOpCodeSteps) 
    }
    
    const handleRemoveStep = (id) => {
        // console.log(`Delete step ${id + 1}`, stepTypes)
        const opCodeStepDeleted = opCodeSteps?.find((_, index) => index === id)
        const outputText = opCodeStepDeleted?.output?.Output || Object.keys(opCodeStepDeleted?.output)[0] || ""
        const nextStepValue =  opCodeStepDeleted?.step_type === 'Condition'? opCodeStepDeleted?.next_step?.true_branch || [] : opCodeStepDeleted?.next_step || []


        const updatedOpCodeSteps = opCodeSteps?.map(opCodeStep =>{
            if(opCodeStep?.step_type !== "Condition" && opCodeStep.step_type !== 'Identification' ){

                let newGroup = {...opCodeStep}

                 if (opCodeStep?.next_step[0] === opCodeStepDeleted?.step_id) {
                        newGroup = { ...opCodeStep, next_step: nextStepValue };
                }

                if(opCodeStep.step_type === 'Memory' || opCodeStep.step_type === 'Loop'){
                    return newGroup
                }

                if(opCodeStep.step_type === "Non-LLM" && outputText){
                    if(opCodeStep.input.query_text.includes(outputText)){
                        newGroup = { ...newGroup, input:{...newGroup.input, query_text: handler.removeDeletedOutput(opCodeStep.input.query_text || "", outputText)}}
                    }
                } else if(outputText) {
                    if(opCodeStep.input.includes(outputText)){
                        newGroup = { ...newGroup, input: handler.removeDeletedOutput(opCodeStep.input || "", outputText)} 
                    }

                    if(opCodeStep?.output?.Output_map && Object.keys(opCodeStep?.output?.Output_map).length > 0 && outputText in opCodeStep.output.Output_map){
                        delete newGroup.output.Output_map[outputText]
                    }
                }

                return newGroup
            }

            if(opCodeStep?.step_type === "Condition"){
                let true_branch = opCodeStep?.next_step?.true_branch
                let false_branch = opCodeStep?.next_step?.false_branch

                if (opCodeStep?.next_step?.true_branch[0] === opCodeStepDeleted?.step_id) {
                    true_branch =  nextStepValue 
                }

                if (opCodeStep?.next_step?.false_branch[0] === opCodeStepDeleted?.step_id) {
                    false_branch =  nextStepValue 
                }

                return {...opCodeStep, next_step:{true_branch, false_branch}}
            }

            return opCodeStep
        } )

        let newOpCodeSteps = updatedOpCodeSteps.filter((_, index) => index !== id)

        setOpCodeSteps(newOpCodeSteps)

        setStepTypes(stepTypes.filter((_, index) => index !== id))
    }

    return (
        <div className='space-y-2'>
            {opCodeSteps?.map((opCodeStep, index) => (
                <Step
                indicator={`${index + 1}`}
                stepIndex={index}
                canAddBefore={index === 0 ? false : true}
                canAddAfter={index !== 0? true : opCodeSteps.length === 1? true : false}
                isDraggable={false }
                canDelete={index === 0 ? false : true}
                isDisabled={validateOpNameDesType()}
                onAddStep={handleAddStep}
                onDeleteStep={handleRemoveStep}
                key={opCodeStep?.step_id || index}
                title={`Opcode Step ${index + 1}`}
                itemId={parentId}
                step_id={opCodeStep.step_id}
                >
                    <div key={opCodeStep?.step_id || index} className="flex flex-col gap-2 w-full">
                        <Select onValueChange={(value) => handleStepTypeSelect(value, index)} 
                        id="step_type" 
                        className="w-full" value={stepTypes[index]}
                        disabled={index === 0}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select step type" />
                            </SelectTrigger>
                            <SelectContent>
                                {index === 0 ? (
                                    <SelectItem key="0" value="Identification">
                                        Identification
                                    </SelectItem>
                                    ) : (
                                    constantStepTypes.slice(1).map((item) => (
                                        <SelectItem key={item.id} value={item.name}>
                                            {item.name}
                                        </SelectItem>
                                    ))
                                )}
                            </SelectContent>
                        </Select>
                        <div className="flex flex-col gap-4">
                            <OpCodeBuilderStep 
                                stepIndex={index}
                                opCodeStep={opCodeStep} 
                                opCodeSteps={opCodeSteps}
                                setOpCodeSteps={setOpCodeSteps}
                            />
                            <hr/>
                        </div>
                    </div>
                </Step>
                ))}
        </div>
    )
}
export default OpCodeBuilderSteps
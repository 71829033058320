import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout'
import Breadcrumb from 'components/Breadcrumb';
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
// import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "components/ui/dialog"
import { Textarea } from 'components/ui/textarea';
import { Label } from 'components/ui/label';
import { Context } from 'context/GlobalState';
import { Input } from 'components/ui/input';
import { OpCodeBuilderContext } from '../OpCodeBuilderHAndC/OpCodeBuilderContext';
import OpCodeBuilderExecute from './OpCodeBuilderExecute';
import { opcodeService } from 'api/services/BEX/opcode.service';
import { useParams } from 'react-router-dom';
// import { ScrollArea } from 'components/ui/scroll-area';
// import OpCodeBuilderStepDetails from './OpCodeBuilderStepDetails';
import { Button } from 'components/ui/button';
import OpCodeBuilderUpdate from '../OpCodeBuilderUpdate';

const OpCodeBuilderViewAndExecute = () => {
    const { addNewNotifcation } = useContext(Context);
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [opCode, setOpCode] = useState(null)
    // const [isOpen, setIsOpen] = useState(false)
    // const [steps, setSteps] = useState([])
    const { orgId, subOrgId} = useContext(OpCodeBuilderContext)
    // const [selectedStep, setSelectedStep] = useState(null)
    // const [stepIndex, setStepIndex] = useState(null)
    const {opCodeName: opCodeNameParam} = useParams()
    const memoryId = window.crypto.randomUUID()

    useEffect(() => {
        if(opCodeNameParam){
            initializeModal()
        }
    }, [opCodeNameParam])

    const initializeModal = async () => {
        console.log("Retrieving opode details for ", opCodeNameParam);
        try {
            setLoadingDetails(true)

            const res = await opcodeService.getOpCodeById(orgId, subOrgId, opCodeNameParam);
            // console.log(res)
            const details = res.data;
            // console.log("Retrieved details:", details);
            if(details?.opcode_id){
                setOpCode(details)
            }

        } catch (error) {
            console.error("Error getting opcode details:", error);
            if(error.response.data === "Opcode not found"){
                addNewNotifcation(`No opcode found with this name "${opCodeNameParam}"`, "danger")
            }else{
                addNewNotifcation("Failed to retrieve opcode details. Please try again.", "danger");
            }
        } finally {
            setLoadingDetails(false)
        }
    };

    const getSteps = () => {
        if(opCode) {
            if ((opCode?.steps && opCode?.steps?.length > 0) || opCode?.identification_step){
                if(opCode.identification_step){
                    return ([opCode.identification_step, ...opCode.steps])
                }else {
                    return (opCode.steps)
                }
            }
        }

        return []
    }

    // const handleStepClick = (step, index) => {
    //     setSelectedStep(step)
    //     setStepIndex(index)
    //     setIsOpen(true)
    // }

    return (
        <NestedPageLayout>
            <div className='my-3'>
                <Breadcrumb maxPaths={2} />
            </div>
            
            <Card>
                <CardHeader className="flex justify-between">
                    <div className="flex justify-between">
                        <div className="">
                            <CardTitle>View and Execute OpCode </CardTitle>
                            <CardDescription className='mt-1 flex flex-col'>
                                You are viewing the opcode: {loadingDetails ?  "Loading Details..." : (opCodeNameParam || '')}
                                {loadingDetails? "" : opCode? "" : 
                                <span className='text-red-400'>Failed to load opcode, please try again later</span> 
                                }
                            </CardDescription>
                        </div>
                        <Button disabled={loadingDetails} onClick={() => setOpenModal(true)}>
                            Manage
                        </Button>
                        {opCode && openModal && 
                        <OpCodeBuilderUpdate opCode={opCode} setOpCode={setOpCode} openModal={openModal} setOpenModal={setOpenModal} ishome={false}/>}
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="space-y-2">
                        <div>
                            <Label>Opcode ID</Label>
                            <Input value={opCode?.opcode_id || ""} readOnly className="font-bold"/>
                        </div>
                        <div>
                            <Label>Opcode Description</Label>
                            <Textarea className="min-h-[100px]" readOnly value={opCode?.description || ""}  />
                        </div>
                        {/* <div className="border px-2 py-2 rounded h-fit">
                            <div className="space-y-2">
                                <Label>Steps</Label>  
                                {getSteps().map((step, index) => (
                                    <Button
                                        key={step.step_id}
                                        // variant={selectedStep === step ? "default" : "outline"}
                                        variant={"outline"}
                                        className="w-full justify-start"
                                        onClick={() => handleStepClick(step, index)}
                                    >
                                        Step {index + 1}: {step.step_type}
                                    </Button>
                                ))}
                            </div>
                        </div> */}
                        
                        <OpCodeBuilderExecute opCode={opCode} steps={getSteps()} memoryId={memoryId}/>
                    </div>

                    {/* <StepDetailsModal isOpen={isOpen} setIsOpen={setIsOpen} step={selectedStep} stepIndex={stepIndex} /> */}
                </CardContent>
            </Card>
            
        </NestedPageLayout>
    )
}

export default OpCodeBuilderViewAndExecute


// const StepDetailsModal = ({ isOpen, setIsOpen, step, stepIndex }) => {
//     return (
//         <Dialog open={isOpen} onOpenChange={setIsOpen}>
//             <DialogContent className="sm:max-w-[600px]">
//                 <DialogHeader>
//                     <DialogTitle>Step Details: {step?.step_type}</DialogTitle>
//                     <DialogDescription>Step ID: step_{stepIndex + 1}</DialogDescription>
//                 </DialogHeader>
//                 <ScrollArea className="h-[450px] w-full rounded-md">
//                     <OpCodeBuilderStepDetails step={step} stepIndex={stepIndex}/>
//                 </ScrollArea>
//             </DialogContent>
//         </Dialog>
//     )
// }
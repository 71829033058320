import * as React from "react"
import { Button } from "components/ui/button"
import { ScrollArea } from "components/ui/scroll-area"
import { X, Minimize2, Maximize2 } from "lucide-react"
import { FileTypeChecker } from "../helper"
import PDFViewer from "./PDFViewer"
import OfficeViewer from "./OfficeViewer"
import ImageViewer from "./ImageViewer"


export const DocViewer = ({
  tabs,
  activeTab, // the selectedDocument
  page, // the selectedDocument's page
  handleSetPage,
  setActiveTab,
  removeTab,
  isExpanded,
  setIsExpanded,
}) => {
  return (
    <div className="relative h-full flex flex-col">
      <div className="flex items-center justify-between border-b px-4 min-h-[40px]">
        <ScrollArea className="w-full whitespace-nowrap" orientation="horizontal">
          <div className="flex gap-1 p-1 flex-wrap">
            {tabs.map((tab) => (
              <Button
                key={tab.id}
                variant={activeTab?.id === tab.id ? "default" : "ghost"}
                className="h-7 px-2 text-xs select-none"
                onClick={() => setActiveTab(tab)}
              >
                <span className="truncate max-w-[120px]">{tab.name}</span>
                <X className="ml-2 h-3 w-3 hover:text-destructive" onClick={(e) => removeTab(tab.id, e)} />
              </Button>
            ))}
          </div>
        </ScrollArea>
        <Button variant="ghost" size="icon" className="ml-2 shrink-0" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <Minimize2 className="h-4 w-4" /> : <Maximize2 className="h-4 w-4" />}
          <span className="sr-only">Toggle expanded view</span>
        </Button>
      </div>
      <div className="flex-1 h-32">
        {!activeTab ?
            <div className='h-full bg-muted/50 flex items-center justify-center text-muted-foreground'>
                <span>Select document to display</span>
            </div>
        : FileTypeChecker.isPDF(activeTab?.name) ? 
            <PDFViewer document={activeTab} page={page} setPage={handleSetPage}/>
        : FileTypeChecker.isOFFICEFILE(activeTab?.name) ?
            <OfficeViewer document={activeTab} page={page} />
        : FileTypeChecker.isIMAGE(activeTab?.name) ?
            <ImageViewer  document={activeTab} page={page} />
        : <div className='h-full bg-muted/50 flex items-center justify-center text-muted-foreground'>
            <span>No renderer for this file type</span>
        </div>}
      </div>
    </div>
  )
}
/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react'
import { Button } from "components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "components/ui/card"
import { secretKeysService } from 'api'
import { useParams } from 'react-router-dom'
import { AppLoader } from 'components/LoaderSpinner'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Context } from 'context/GlobalState'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import { OrganizationContext } from 'context/OrganizationContext'
import { SecretInputRender } from './RedisKys'
import { Label } from 'components/ui/label'

export default function ElasticsearchConfig() {
    
    const [secretKeys, setSecretKeys] = useState({esKeys: {}})
    const [loading, setLoading] = useState(true)
    const [isDeploying, setIsDeploying] = useState(false)
    const [error, setError] = useState(null)

    const { oragID } = useParams()

    const getEsKeys = async () => {
        secretKeysService.listElasticsearchSecretKeys(oragID)
        .then((res) => {
            if (res.data.isDeploying) {
                setIsDeploying(true)
            } else {
                setIsDeploying(false)
                setSecretKeys({esKeys: res.data.elasticsearch})
            }
        }).catch(error => {
            console.log('error: ', error);
            setError(error?.response?.message || 'Something went wrong')
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getEsKeys()
    }, [])

    useEffect(() => {
        let timeoutId;
    
        const checkStatus = async () => {
            if (isDeploying) {
                const res = await checkDeploymentStatus();
                if (res) {
                    getEsKeys()
                } else {
                    timeoutId = setTimeout(checkStatus, 5000); // Wait 5 seconds before next check
                }
            }
        };
    
        checkStatus(); // Start the status check
    
        return () => clearTimeout(timeoutId); // Clear timeout on cleanup
    }, [isDeploying]);

    const checkDeploymentStatus = async () => {
        try {
            const response = await secretKeysService.checkElasticsearchDeployment(oragID)
            if (response.status === 202) {
                return false
            } else {
                return true
            } 
        } catch (error) {
            console.error('Error checking deployment status:', error);
            return false;
        }
    };


    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    if (error) {
        return <h1> Error: {error} </h1>
    }

    return (
        <OrganizationKeyCard 
        setIsDeploying={setIsDeploying}
        esKeys={secretKeys.esKeys} 
        isDeploying={isDeploying}
        />
    )
}


const OrganizationKeyCard = ({ esKeys, isDeploying, setIsDeploying }) => {

    const DB_STORE_TYPES = ['dedicated', 'custom']
    const elasticsearchObj = {
        'elasticsearchApiKey': '',
        'elasticsearchCloudId': '',
        'elasticsearchEndpoint': '',
        'keyType': 'custom',
        'isElasticsearchActivated': false
    }

    const [btnLoading, setBtnLoading] = useState(false)
    const [elasticsearchKey, setElasticsearchKey] = useState(elasticsearchObj)

    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    const { oragID } = useParams()

    const resetValues = () => {
        if (selectedOrganization?.isElasticsearchActivated) {
            setElasticsearchKey(esKeys)
        }
    }

    useEffect(() => {
        resetValues()
    }, [])


    const handleChangeElasticsearchKeys = () => {
        if (selectedOrganization.userRole !== 'superAdmin') {
            return addNewNotifcation('Only super admin can update elasticsearch keys', 'warning')
        }

        const obj = {
            elasticsearch: elasticsearchKey,
        }
        // make sure to remove activate keys
        delete obj['elasticsearch']['isElasticsearchActivated']

        const checkEsEmptyKeys = Object.values(obj.elasticsearch).some(value => !value);
        if (elasticsearchKey.keyType === 'custom' && (checkEsEmptyKeys)) {
            return addNewNotifcation('Please fill in all keys', 'warning')
        }

        setBtnLoading(true)

        const secretObj = {
            organizationId: oragID,
            secretKeys: obj
        }

        secretKeysService.updateElasticsearchSecretKeys(secretObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Elasticsearch created successfully', 'success')
            if (elasticsearchKey.keyType === "dedicated" && !esKeys.hasDedicatedElasticsearch) {
                setIsDeploying(true)
            }
        }).catch((error) => {
            console.log('error: ' ,error);
            if (error?.response?.status === 403) {
                addNewNotifcation(error?.response?.data || 'This organization does not have any valid subscription', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    const handleSelectChange = (name, value) => {
        if (value === esKeys.keyType) {
            resetValues()
        } else if (value === 'dedicated' && esKeys.hasDedicatedElasticsearch) {
            setElasticsearchKey({
                [name]: value,
                ...esKeys.dedicatedElasticsearchKeys
            })
        } else {
            setElasticsearchKey({
                ...elasticsearchObj,
                [name]: value,
            })
        }
    }

    const handleElasticsearchInputChange = (e) => {
        const { name, value } = e.target
        setElasticsearchKey((prev) => ({ ...prev, [name]: value }))
    }

    const isSuperAdmin = selectedOrganization.userRole === 'superAdmin'

    return (
        <Card>
            <CardHeader>
                <CardTitle>Elasticsearch Keys</CardTitle>
                <CardDescription>Manage your organization's configuration keys</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">

                <div className="space-y-3">
                    <div className="space-y-2">
                        <Label htmlFor={'db-store-type'}> Elasticsearch Store Type </Label>

                        <Select disabled={!isSuperAdmin} onValueChange={(value) => handleSelectChange('keyType', value)} id="db-store-type" value={elasticsearchKey.keyType}>
                            <SelectTrigger className="w-full capitalize">
                                <SelectValue className="capitalize" placeholder="Select redis database type" />
                            </SelectTrigger>
                            <SelectContent>
                                {DB_STORE_TYPES.map(item => (
                                    <SelectItem className='capitalize' key={item} value={item}>{item} 
                                        {item === 'dedicated' && <small className="text-muted-foreground"> subscription required</small>} 
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    {elasticsearchKey.keyType === "dedicated" && !esKeys.hasDedicatedElasticsearch && (
                        <div className="w-full mt-2 p-2 rounded border flex flex-col gap-1">
                            <h2 className="font-bold text-[16px]">Automatic Key Generation</h2>
                            <span className="text-muted-foreground text-sm">
                                We'll create a new dedicated Elasticsearch database for you and provide the connection details automatically.
                            </span>
                        </div>
                    )}
                </div>

                {/* { elasticsearch Keys} */}
                <div className="space-y-4">
                    <h3 className="text-lg font-semibold">Elasticsearch Configuration</h3>
                    <div className="space-y-2">
                        <SecretInputRender secretKey='Elasticsearch api key' disabled={!isSuperAdmin || elasticsearchKey.keyType === 'dedicated'} onChange={handleElasticsearchInputChange} label='elasticsearchApiKey' customValue={elasticsearchKey.elasticsearchApiKey} />
                    </div>
                    <div className="space-y-2">
                        <SecretInputRender secretKey='Elasticsearch cloud id' disabled={!isSuperAdmin || elasticsearchKey.keyType === 'dedicated'} onChange={handleElasticsearchInputChange} label='elasticsearchCloudId' customValue={elasticsearchKey.elasticsearchCloudId} />
                    </div>
                    <div className="space-y-2">
                        <SecretInputRender secretKey='Elasticsearch endpoint' disabled={!isSuperAdmin || elasticsearchKey.keyType === 'dedicated'} onChange={handleElasticsearchInputChange} label='elasticsearchEndpoint' customValue={elasticsearchKey.elasticsearchEndpoint} />
                    </div>
                </div>
        
            </CardContent>

            <CardFooter>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button disabled={btnLoading || isDeploying} onClick={handleChangeElasticsearchKeys} className="w-full">
                                {/* Save Configuration */}
                                {isDeploying ? 'Deploying...' : elasticsearchKey.keyType === "dedicated" ? 'Generate Keys' : 'Save Configuration'}
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-gray-900 text-white">
                            {!isSuperAdmin && (
                                'Only super admin can update elasticsearch keys'
                            )}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </CardFooter>
        </Card>
    )
}

import React, { useState, useEffect } from 'react';
import StyledTextArea from "../StyledTextArea";

const reservedKeywords = [
    "Instruction:", "Context:", "Target Format:", "Sample Input:", "Sample Output:",
    "## Instruction", "## Context", "## Target Format", "## Sample Input", "## Sample Output"
];

const tooltips = {
    instruction: "Provide specific tasks or questions for the language model to address. This sets the purpose and direction for the response.",
    context: "Supply background information or details that help the language model understand the scenario or topic, ensuring relevant and accurate responses.",
    targetFormat: "Specify the structure and style of the response. This ensures that the output is consistent with your needs, whether it's a specific layout, tone, or type of information.",
    sampleInput: "Offer an example of what the input data might look like. This helps clarify the format and type of data expected.",
    sampleOutput: "Show a representative example of the desired response. This demonstrates the format and quality of output you are looking for from the language model.",
    inputData: "Include any data or details that the language model needs to process in order to generate a meaningful response. This can include text, numbers, or structured data."
};

// Function to validate if input contains reserved keywords
const validateInput = (text) => {
    const lowerCaseText = text.toLowerCase();
    const foundKeyword = reservedKeywords.find(keyword => lowerCaseText.includes(keyword.toLowerCase()));
    return foundKeyword ? `Reserved keyword detected: '${foundKeyword}'. Please revise your text to proceed.` : "";
};

const Anatomy = ({ instructionForm, setInstructionForm, isExecute = false }) => {
    const [errors, setErrors] = useState({});

    const handleInputChange = (field, value) => {
        setInstructionForm(prev => ({
            ...prev,
            [field]: value
        }));
    };

    useEffect(() => {
        const newErrors = {};
        const fieldsToValidate = ["instruction", "context", "targetFormat", "sampleInput", "sampleOutput"];
        
        fieldsToValidate.forEach((field) => {
            newErrors[field] = validateInput(instructionForm[field] || "");
        });
    
        setErrors(newErrors);
    }, [instructionForm.instruction, instructionForm.context, instructionForm.targetFormat, instructionForm.sampleInput, instructionForm.sampleOutput]); 

    return (
        <div className='flex flex-col gap-4 px-1 py-4'>
            {["instruction", "context", "targetFormat", "sampleInput", "sampleOutput"].map((field) => (
                <div key={field} className="flex flex-col gap-2">
                    <StyledTextArea
                        value={instructionForm[field]}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        labelText={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                        tooltipText={tooltips[field]}
                        required={field === "instruction"}
                    />
                    {errors[field] && <span className='text-xs text-red-400'>{errors[field]}</span>}
                </div>
            ))}
            {isExecute && (
                <StyledTextArea
                    disabled={!isExecute}
                    value={instructionForm.inputData}
                    onChange={(e) => handleInputChange("inputData", e.target.value)}
                    labelText="Input Data"
                    tooltipText={tooltips.inputData}
                    required={false}
                />
            )}
        </div>
    );
};

export default Anatomy;

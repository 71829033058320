import { styled } from "styled-components";
import { CgClose } from 'react-icons/cg'
import { LoaderSpinner } from "./LoaderSpinner";
import { Button } from "./ui/button";


const Modalbackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
`

const Modalcontainer = styled.div`
    width: 450px;
    min-height: 200px;
    max-height: 220px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    padding: 20px;
`

const CloseBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const Closebtn = styled.button`
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
`

const TitleCointaier = styled.div`
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
`

const MassageContainer = styled.div`
    // height : 25px;
    margin-top: 10px;
    whidth: 100%
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    text-align: center;
`

const BtnContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    margin-top:auto;
`

export default function AlertModal({ openModal, setopenModal, onDelete, btnTitle='Permanently delete',  title='Are you sure you want to delete this item', subTitle='Once deleted, this item will no longer be accessible.', loading=false }) {

    const handleCloseModal = (e) => {
        if (loading) return
        e.stopPropagation()
        setopenModal(false)
    }

    return (
        <>
            {openModal && (
                <Modalbackground className="cursor-default" onClick={handleCloseModal}>
                    <Modalcontainer onClick={(e)=>{e.stopPropagation()}}>
                        <CloseBtnContainer>
                            <Closebtn onClick={handleCloseModal}>
                                <CgClose size={20} />
                            </Closebtn>
                        </CloseBtnContainer>
                        <TitleCointaier>
                            <h3> {title} </h3>
                        </TitleCointaier>
                        <MassageContainer>
                            <span>{subTitle}</span>
                        </MassageContainer>
                        <BtnContainer>
                            <Button variant='outline' onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant='destructive' className='w-[155px]' onClick={onDelete}>
                                {loading ? <LoaderSpinner /> : btnTitle}
                            </Button>
                        </BtnContainer>
                    </Modalcontainer>
                </Modalbackground>
            )}
        </>
    )
}

import { useState, useEffect, useRef } from 'react';

// Custom hook for lazy loading any component
const useLazyLoad = (fetchDataFn) => {
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    // Fetch data when the element is visible
    const fetchData = async () => {
        await fetchDataFn();
        setIsDataFetched(true);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
            }
            });
        },
        { threshold: 0.1 } // Trigger when 10% of the element is in view
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible && !isDataFetched) {
            fetchData();
        }
    }, [isVisible, isDataFetched]);

    return { isDataFetched, elementRef };
};

export default useLazyLoad;

import React, { createContext, useState, useContext, useEffect, useReducer } from "react";
import { RootReducer } from "./reducers/RootReducer";
import { PromptLabData, AiTeacherData, CodeSandboxData } from "./initialData";
import { useParams } from "react-router-dom";
import { instructionService } from "api/services/BEX/instruction.service";
import { opcodeAssistantService } from "api";
import { OrganizationContext } from "context/OrganizationContext";

// Create the parent context
export const AiLabContext = createContext();
// Parent provider
export const AiLabProvider = ({ children }) => {
  // use Reducers
  const [state, dispatch] = useReducer(RootReducer, {
    promptLab: PromptLabData,
    aiTeacher: AiTeacherData,
    codeSandbox: CodeSandboxData
  });

  const { oragID, subOragID } = useParams();
  const { selectedOrganization } = useContext(OrganizationContext);
  const [isLoadingInstructions, setIsLoadingInstructions] = useState(false);
  
  const fetchInstructions = async () => {
    try{
      setIsLoadingInstructions(true);
      console.log("Fetching Instructions...")
      const res = await instructionService.listModels(
        oragID,
        subOragID,
      );

      const instructions = res.data || [];
      if (instructions.length === 0) {
          console.log("No instructions saved.");
          return;
      }

      // console.log("fetched instructions: ", instructions)

      const fetchedInstructions = instructions.map((instruction) => {
          const modifiedByMember = selectedOrganization.members.find(member => member.id._id === instruction.modified_by);
          const userIdMember = selectedOrganization.members.find(member => member.id._id === instruction.createdBy);

          return {
              ...instruction,
              modified_by: modifiedByMember ? modifiedByMember.id.name : instruction.modified_by,
              user_id: userIdMember ? userIdMember.id.name : instruction.createdBy
          };
      });
      dispatch({type: "SET_INSTRUCTIONS", field: "savedInstructions", value: fetchedInstructions })
    } catch (error) {
      console.error("Error fetching instructions:", error);
    } finally {
      setIsLoadingInstructions(false);
    }
  };

  const fetchAiTeacherAssistant = async () => {
    try {
      console.log("Fetching Ai Teacher Assistant Details...")
      const assistant = await opcodeAssistantService.getOpcodeAssistant(
        state?.aiTeacher?.COMMON_ORG,
        state?.aiTeacher?.COMMON_SUBORG,
        state?.aiTeacher?.AI_TEACHER_ASST_ID
      )

      const opcodesList = assistant?.data?.opcodes || [];
      const opcodesMap = {
          "teacher": { ...state?.aiTeacher?.aiTeacherOpcodes?.teacher,
            "opcode": opcodesList.find((opcode) => opcode.includes('TEACHER_MODE')) || null
          },
          "quiz": { ...state?.aiTeacher?.aiTeacherOpcodes?.quiz,
            "opcode": opcodesList.find((opcode) => opcode.includes('QUIZ_MODE')) || null
          } 
      };

      dispatch({type: "AI_TEACHER_SET_OPCODES", value: opcodesMap});
    } catch (error) {
      console.error("Error fetching assistants:", error);
    }
  }
  
  useEffect(() => {
    // Populate instructions on load of ai lab
    fetchInstructions(); 

    // Retrieve Assistant Details in AI Teacher
    fetchAiTeacherAssistant();

  }, [])

  // useEffect(() => {
  //   // console.log("prompt Lab:", state?.promptLab)
  //   // console.log("AI Teacher:", state?.aiTeacher)
  // }, [state.promptLab, state.aiTeacher])

  // context vars
  const values = {
    // reducers
    state, dispatch,

    // ===== Live Prompt ===== 
    instructions: state.promptLab.savedInstructions,
    fetchInstructions,
    isLoadingInstructions,

    // ===== AI Teacher =====
    aiTeacherMessages: state.aiTeacher.aiTeacherMessages,

    // ===== Code Sandbox =====
    codeSandboxMessages: state.codeSandbox.codeSandboxMessages
  }

    return (
        <AiLabContext.Provider value={values}>
            {children}
        </AiLabContext.Provider>
  );
};
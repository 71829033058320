import { bexHttpClient } from '../../clients';


/**
 * Service class for performing project operations.
 */
class OrganizationService {

    /**
     * Fetches organization by id.
     * @returns {Promise<Object>} The response from the server returns organization object.
     */
    async getOrganization(organizationId) {
        const response = await bexHttpClient.get(`/db/organizations/get_organization_by_id/${organizationId}`)
        return response;
    }

    /**
     * Fetches organization by id.
     * @returns {Promise<Object>} The response from the server returns organization object.
     */
    async getOrganizations(userId) {
        const response = await bexHttpClient.get(`/db/organizations/get_organizations_by_user_id/${userId}`)
        return response;
    }

    /**
     * Fetches sub-organization by organizationId.
     * @returns {Promise<Object>} The response from the server returns list of sub-organizations object.
     */
    async getSubOrganizations(organizationId) {
        const response = await bexHttpClient.get(`/db/sub-organizations/get-suborganizations/${organizationId}`)
        return response;
    }

    /**
     * Update sub-organization data.
     * @returns {Promise<Object>} The response from the server returns updated sub-organizations object.
     */
    async updateSubOrganizations(organizationId, subOrganizationId, data) {
        const response = await bexHttpClient.put(`/db/sub-organizations/update-sub-organization?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`, data)
        return response;
    }

    /**
     * Update sub-organization data.
     * @returns {Promise<Object>} The response from the server returns updated sub-organizations object.
     */
    async getOrganizationUsers(organizationId) {
        const response = await bexHttpClient.get(`/db/users/getUsers/${organizationId}`)
        return response;
    }

    /**
     * Check organization cluster status.
     * @returns {Promise<Object>} The response from the server returns organization object status.
     */
    async checkOrganizationCluster(organizationId) {
        const response = await bexHttpClient.get(`/db/organizations/check-organization-cluster/${organizationId}`)
        return response;
    }

    /**
     * delete organization cluster.
     * @returns {Promise<Object>} The response from the server returns delete cluster response.
     */
    async deleteOrganizationCluster(organizationId) {
        const response = await bexHttpClient.delete(`/db/organizations/delete-organization-cluster/${organizationId}`)
        return response;
    }

    /**
     * delete organization.
     * @returns {Promise<Object>} The response from the server returns delete organization response.
     */
    async deleteOrganization(organizationId) {
        const response = await bexHttpClient.delete(`/db/organizations/delete-organization/${organizationId}`)
        return response;
    }

}

const organizationService = new OrganizationService()
export { organizationService }

import React, { useContext, useState } from 'react'
import './web-engagement-styles.css'
import SetupCard from '../../../components/AppSetup/SetupCard.jsx'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'components/Breadcrumb'
import { OrganizationContext } from "context/OrganizationContext";


export default function WebEngagementDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Your website URL where you are going to setup the BeX AI Assistant',
        'A set of FAQ or knowledge base documents that you want to setup'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistant for website conversation is designed to assist you with handling your website conversations.',
        'This BeX AI Assistant is capable of communicating with your website visitors, to answer all the queries based on the knowledge base that you train her with, record down appointments, connects to your backend systems.'
    ];

    const navigate = useNavigate()
    const param = useParams()
    const { selectedOrganization } = useContext(OrganizationContext);

    const [openModal, setOpenModal] = useState(false)

    const handleDemoClick = () => {
        if (selectedOrganization) {
            navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/web-demo`)
        } else {
            navigate(`/organizations`)
        }
        console.log(param.oragID, param.subOragID)
    }

    const handleSetupClick = () => {
        setOpenModal(!openModal)
    };

    return (
        <div className='web-engagement-dashboard-container'>
            <div className='web-engagement-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    <Breadcrumb maxPaths={4} />

                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle={'BeX AI Assistant for Website Conversation'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant'
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />

                    </div>
                </div>
            </div>
        </div>
    )
}